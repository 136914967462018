import { Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { configService } from '../../lib/services/ConfigService';

export function SearchComponent({
  onChange,
  searchQuery,
  enabled,
}: {
  searchQuery: string;
  enabled: boolean;
  onChange: (e) => void;
}) {
  const { t } = useTranslation();
  const queryLen = searchQuery.trim().length;
  const minimumSearchCharCount = configService.getMinimumSearchCharCount();
  return (
    <Tooltip
      title={t('Type at least {{count}} characters to search', {
        count: minimumSearchCharCount,
      })}
      trigger="focus"
      visible={enabled && queryLen > 0 && queryLen < minimumSearchCharCount}
      placement="bottom"
    >
      <Search
        size="large"
        allowClear
        placeholder={t('Search by art name, creator or collection')}
        className="search-menu-large-screens"
        onChange={onChange}
        value={searchQuery}
        defaultValue={searchQuery}
        type="search"
      />
    </Tooltip>
  );
}
