import React from 'react';
import Identicon from 'react-identicons';
import { useRouter } from 'next/router';

import { VerifiedIcon } from '../../lib/Resources';
import ScamPattern from '../icons/ScamPattern';
import HrefLink from './HrefLink';

const UserAvatar = ({
  user,
  isNft = false,
  className = 'user-avatar-container',
  imgClassName = 'user-avatar-container-img absolute',
  IdenticonSize = 30,
  isClickable = true,
  src = '',
  showVerifiedIcon = false,
}: {
  user: {
    walletAddress?: string;
    address?: string;
    userAvatarUrl?: string;
    userAvatarUrlThumbnail?: string;
    verified?: boolean;
    banned?: boolean;
  };
  isNft?: boolean;
  className?: string;
  imgClassName?: string;
  IdenticonSize?: number;
  isClickable?: boolean;
  src?: string;
  showVerifiedIcon?: boolean;
}) => {
  const { push } = useRouter();

  const handleClick = (e) => {
    if (isClickable) {
      e.stopPropagation();
      if (user?.walletAddress) {
        push(`/creators/${user.walletAddress}`);
      }
    }
  };

  const UserImage = () => {
    if (
      user?.banned ||
      (!user?.userAvatarUrl && !user?.userAvatarUrlThumbnail)
    ) {
      return (
        <div
          className="absolute identicon-wrapper"
          style={{
            height: `${IdenticonSize}px`,
            width: `${IdenticonSize}px`,
            backgroundColor: 'white',
            borderRadius: '999px',
            overflow: 'hidden',
          }}
        >
          <Identicon
            string={user?.walletAddress || user?.address}
            size={isNft ? 30 : IdenticonSize}
          />
        </div>
      );
    }

    return (
      <img
        className={`${imgClassName} ${isNft ? 'avatar-nft' : ''} `}
        src={src || user?.userAvatarUrlThumbnail || user?.userAvatarUrl}
        alt="User profile avatar"
      />
    );
  };

  return (
    <HrefLink
      href={`/creators/${user?.walletAddress || user.address}`}
      className={className}
      onClick={handleClick}
    >
      <ScamPattern />
      <UserImage />

      {user?.verified && !showVerifiedIcon ? (
        <img
          src={VerifiedIcon}
          alt="VerifiedIcon"
          className="user-verified-icon"
        />
      ) : (
        <div />
      )}
    </HrefLink>
  );
};

export default UserAvatar;
