import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloseActionBtn,
  Coin98IconSvg,
  HowToUseAirNFTs,
  CoinBaseIcon,
  BitKeepSvg,
} from '../../lib/Resources';
import TrustWalletIcon from '../icons/TrustWalletIcon';
import WalletConnectIcon from '../icons/WalletConnectIcon';
import TokenPocketIcon from '../icons/TokenPocketIcon';
import MetamaskIcon from '../icons/MetamaskIcon';
import WalletConnection from './WalletConnection';

export const HowToConnectModal = ({
  visible,
  onCancel,
  onWCConnectAttempt,
  onWeb3ConnectAttempt,
}: {
  visible: boolean;
  onCancel: () => void;
  onWCConnectAttempt: () => void;
  onWeb3ConnectAttempt: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title=""
      visible={visible}
      centered
      onCancel={onCancel}
      footer={null}
      className="how-to-connect-modal"
      closeIcon={
        <div>
          <img src={CloseActionBtn} alt="Close action" height={20} width={20} />
        </div>
      }
    >
      <div className="account-modal-div">
        <div className="mint-popup-title">{t('Connect to a Wallet')}</div>

        <WalletConnection
          title="Metamask"
          onConnect={onWeb3ConnectAttempt}
          icon={<MetamaskIcon />}
        />

        <WalletConnection
          title="Trust Wallet"
          onConnect={onWeb3ConnectAttempt}
          icon={<TrustWalletIcon />}
        />

        <WalletConnection
          title="Wallet Connect"
          onConnect={onWCConnectAttempt}
          icon={<WalletConnectIcon />}
        />

        <WalletConnection
          title="TokenPocket"
          onConnect={onWeb3ConnectAttempt}
          icon={<TokenPocketIcon />}
        />

        <WalletConnection
          title="Coinbase Wallet"
          onConnect={onWeb3ConnectAttempt}
          icon={
            <img
              src={CoinBaseIcon}
              alt="Coinbase Wallet Icon"
              height={31}
              width={31}
            />
          }
        />

        <WalletConnection
          title="BitKeep Wallet"
          onConnect={onWeb3ConnectAttempt}
          icon={
            <img src={BitKeepSvg} alt="BitKeep Icon" height={31} width={31} />
          }
        />

        <WalletConnection
          title="Coin98 Wallet"
          onConnect={onWeb3ConnectAttempt}
          icon={
            <img src={Coin98IconSvg} alt="Coin98 Icon" height={31} width={31} />
          }
        />

        <div className="learn-connect">
          <a href={HowToUseAirNFTs}>{t('Learn how to connect')}</a>
        </div>
      </div>
    </Modal>
  );
};
