/* eslint-disable import/no-cycle */
import { SupportedChains } from '../lib/models/NftModel';
import { SupportedPair } from '../lib/services/MarketPriceService';
import { IPoolToken } from '../lib/@Types';

export interface ChainConfigInterface {
  name: SupportedChains;
  network: string;
  networkExplorer: string;
  nodeRPC: string;
  chainID: number;
  mintContractAddress: string;
  airtTokenContactAddress: string;
  deployerAddress: string;
  auctionEscrowAddress: string;
  stakingContractAddresses?: IPoolToken;
  currency: string;
  pair: SupportedPair;
  decimal: number;
  // This describes how much multiple factor we can increase on default gas limit with respect of a chain
  // The reasoning behind this is the fact that some EVM chains will consume more gas than others
  // So having a certain multiple config helps us manage without changes on many levels
  gasLimitLevel?: number;
  paymentBridgeContractAddress?: string;
  airnftV2ContractAddress?: string;
  airnftV2DeployerAddress?: string;
}

export type SupportedChainConfigInterface = Record<
  SupportedChains,
  ChainConfigInterface
>;

type ChainsConfigInterface = Record<
  'testnet' | 'mainnet',
  SupportedChainConfigInterface
>;

const BSC_STAKING_CONTRACT: IPoolToken = {
  AIRT: {
    token: 'AIRT',
    address: '0x04d9b0e276b5becf9c8c97738d91efac73f43779',
    contractAddress: '0x03b2907648482F0B815e9F8B23F7Fc80E2A3A224',
  },
};

const BSC_STAKING_MAINNET_CONTRACT: IPoolToken = {
  AIRT: {
    token: 'AIRT',
    address: '0x016cf83732f1468150d87dcc5bdf67730b3934d3',
    contractAddress: '0x36f176bd06b077424326ef5497d096109f00d77f',
  },
};

export const chainsConfig: ChainsConfigInterface = {
  testnet: {
    bsc: {
      name: 'bsc',
      network: 'binance',
      networkExplorer: 'https://testnet.bscscan.com/',
      nodeRPC: 'https://data-seed-prebsc-1-s2.binance.org:8545',
      chainID: 97,
      mintContractAddress: '0x5FA60a46365d384d22adCe448ca81cc0E19BBb72',
      airtTokenContactAddress: '0x03b2907648482F0B815e9F8B23F7Fc80E2A3A224',
      deployerAddress: '0x86D14722Fd05479f53c2AB64dbE062a924FA8b18',
      auctionEscrowAddress: '0x93222FE57AD5cdA2206550f3537fEb6965BB7fdD',
      stakingContractAddresses: BSC_STAKING_CONTRACT,
      currency: 'BNB',
      pair: SupportedPair.BNBUSD,
      decimal: 18,
      paymentBridgeContractAddress:
        '0x1d216AEa7023827bda3DE3100b51515797819968',
      airnftV2ContractAddress: '0xE9389dEf7f6A8948fF5469770799aEEC1D5d4b2D',
      airnftV2DeployerAddress: '0x6722394A31d6b7487F9920ED469445Cc4Dc6e71C',
    },
    polygon: {
      name: 'polygon',
      network: 'polygon',
      networkExplorer: 'https://mumbai.polygonscan.com/',
      nodeRPC: 'https://matic-mumbai.chainstacklabs.com',
      chainID: 80001,
      mintContractAddress: '0xD96250D736642a487366170acd7823d8038Df212',
      airtTokenContactAddress: '0x64f5a18a42317540f8f3bdbb3dee0c86054ca002',
      deployerAddress: '0x4f2575b8f88af8d8b1f7e62f518dd568cca31b2f',
      auctionEscrowAddress: '0x93222FE57AD5cdA2206550f3537fEb6965BB7fdD',
      stakingContractAddresses: BSC_STAKING_CONTRACT,
      currency: 'MATIC',
      pair: SupportedPair.MATICUSD,
      decimal: 18,
      paymentBridgeContractAddress:
        '0xeC62d31c096B12fdaAB5A7AfAAD6fe395DFc25bE',
      airnftV2ContractAddress: '0xF923cAA6E23a547380832b485C46B4201406e24b',
      airnftV2DeployerAddress: '0x6722394A31d6b7487F9920ED469445Cc4Dc6e71C',
    },
    fantom: {
      name: 'fantom',
      network: 'fantom',
      networkExplorer: 'https://testnet.ftmscan.com/',
      nodeRPC: 'https://rpc.testnet.fantom.network/',
      chainID: 4002,
      mintContractAddress: '0x94e22c14118353651636f9af43cd0a5a08b93da3',
      airtTokenContactAddress: '0xd96250d736642a487366170acd7823d8038df212',
      deployerAddress: '0x4f2575b8f88af8d8b1f7e62f518dd568cca31b2f',
      auctionEscrowAddress: '0x93222FE57AD5cdA2206550f3537fEb6965BB7fdD',
      currency: 'FTM',
      pair: SupportedPair.FTMUSD,
      decimal: 18,
      gasLimitLevel: 1.2,
      paymentBridgeContractAddress:
        '0xa25ba98D4D94E9530B2820d30a62dd99e2d8d148',
      airnftV2ContractAddress: '0x414bA9079Adb98cbf4F0c272d66aBA95e4999b94',
      airnftV2DeployerAddress: '0x6722394A31d6b7487F9920ED469445Cc4Dc6e71C',
    },
    ethereum: {
      name: 'ethereum',
      network: 'ethereum',
      networkExplorer: 'https://goerli.etherscan.io/',
      nodeRPC: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      chainID: 5,
      mintContractAddress: '0xB0D9A8D5b8670C18c2557eb8c44C2eab404e6C51',
      airtTokenContactAddress: '0xEf39b31BD81c4eCFcCba0B1d19744F60fF181E21',
      deployerAddress: '0x6722394A31d6b7487F9920ED469445Cc4Dc6e71C',
      auctionEscrowAddress: '0x396796821E67ab3616535b6e7Ca8eC152E505997',
      currency: 'ETH',
      pair: SupportedPair.ETHUSD,
      decimal: 18,
      gasLimitLevel: 1.2,
      paymentBridgeContractAddress:
        '0xbA23f57aAa7CF8DC0d08e1638DFeA54210120AE6',
      airnftV2ContractAddress: '0xeC62d31c096B12fdaAB5A7AfAAD6fe395DFc25bE',
      airnftV2DeployerAddress: '0x6722394A31d6b7487F9920ED469445Cc4Dc6e71C',
    },
  },
  mainnet: {
    bsc: {
      name: 'bsc',
      network: 'binance',
      networkExplorer: 'https://bscscan.com/',
      nodeRPC: 'https://bsc-dataseed.binance.org',
      chainID: 56,
      mintContractAddress: '0xF5db804101d8600c26598A1Ba465166c33CdAA4b',
      airtTokenContactAddress: '0x016cf83732f1468150d87dcc5bdf67730b3934d3',
      deployerAddress: '0x393a0Ef293ae6D8C11F49744B081ddD343dEbBD0',
      auctionEscrowAddress: '0x396796821E67ab3616535b6e7Ca8eC152E505997',
      stakingContractAddresses: BSC_STAKING_MAINNET_CONTRACT,
      currency: 'BNB',
      pair: SupportedPair.BNBUSD,
      decimal: 18,
      paymentBridgeContractAddress:
        '0xf9c9937D565022aD68f144047b0cEA6384d8F56F',
      airnftV2ContractAddress: '0x1EafA94080dc90DBf604F5f88284EAaa77A45216',
      airnftV2DeployerAddress: '0x4F2575b8F88aF8D8B1F7e62F518dd568CcA31b2f',
    },
    polygon: {
      name: 'polygon',
      network: 'polygon',
      networkExplorer: 'https://polygonscan.com/',
      nodeRPC: 'https://polygon-rpc.com/',
      chainID: 137,
      mintContractAddress: '0xCd494673999194365033D7A287af9f0a3b163874',
      airtTokenContactAddress: '0xBCD287ca1C614Ecd8c5Ca95bFf3c13c392192df5',
      deployerAddress: '0x4f2575b8f88af8d8b1f7e62f518dd568cca31b2f',
      auctionEscrowAddress: '0x396796821E67ab3616535b6e7Ca8eC152E505997',
      stakingContractAddresses: BSC_STAKING_MAINNET_CONTRACT,
      currency: 'MATIC',
      pair: SupportedPair.MATICUSD,
      decimal: 18,
      paymentBridgeContractAddress:
        '0x5EeAc0d11901D445A7e00b475e5F31DefFF2a3d7',
      airnftV2ContractAddress: '0xf57b25eA48e3050BF969dbBE35a78b1d2e33461b',
      airnftV2DeployerAddress: '0x4F2575b8F88aF8D8B1F7e62F518dd568CcA31b2f',
    },
    fantom: {
      name: 'fantom',
      network: 'fantom',
      networkExplorer: 'https://ftmscan.com/',
      nodeRPC: 'https://rpc.ftm.tools/',
      chainID: 250,
      mintContractAddress: '0x94e22c14118353651636f9af43cd0a5a08b93da3',
      airtTokenContactAddress: '0xd96250d736642a487366170acd7823d8038df212',
      deployerAddress: '0x4f2575b8f88af8d8b1f7e62f518dd568cca31b2f',
      auctionEscrowAddress: '0x396796821E67ab3616535b6e7Ca8eC152E505997',
      currency: 'FTM',
      pair: SupportedPair.FTMUSD,
      decimal: 18,
      gasLimitLevel: 1.2,
      paymentBridgeContractAddress:
        '0xeEa108b735C3730cfA1E1c40d8939d90923cD815',
      airnftV2ContractAddress: '0xBCD287ca1C614Ecd8c5Ca95bFf3c13c392192df5',
      airnftV2DeployerAddress: '0x4F2575b8F88aF8D8B1F7e62F518dd568CcA31b2f',
    },
    ethereum: {
      name: 'ethereum',
      network: 'ethereum',
      networkExplorer: 'https://etherscan.io/',
      nodeRPC: 'https://mainnet.infura.io/v3/abe680de4a264064851c67d0eea24000',
      chainID: 1,
      mintContractAddress: '0x1295c0aD0D915269D5B2DDF5981610Bc8a96ed51',
      airtTokenContactAddress: '0xD96250D736642a487366170acd7823d8038Df212',
      deployerAddress: '0x6722394A31d6b7487F9920ED469445Cc4Dc6e71C',
      auctionEscrowAddress: '0x396796821E67ab3616535b6e7Ca8eC152E505997',
      currency: 'ETH',
      pair: SupportedPair.ETHUSD,
      decimal: 18,
      gasLimitLevel: 1.2,
      paymentBridgeContractAddress:
        '0x0AEF2591c8A2937326A51214d435587a0535895C',
      airnftV2ContractAddress: '0xfe0711df6c8d6a91eb7199eb7BB61213207A9E3D',
      airnftV2DeployerAddress: '0x4F2575b8F88aF8D8B1F7e62F518dd568CcA31b2f',
    },
  },
};

export const chainEnvConfig =
  process.env.NEXT_PUBLIC_WEB3_NETWORK === 'Testnet'
    ? chainsConfig.testnet
    : chainsConfig.mainnet;

export default chainsConfig;
