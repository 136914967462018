import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import BigNumber from 'bignumber.js';
// eslint-disable-next-line import/no-cycle
import Web3 from 'web3';
import { configService } from './services/ConfigService';

dayjs.extend(utc);
dayjs.extend(timezone);

export const rand = (min: number, max: number) =>
  Math.floor(Math.random() * max + min);

export const truncate = (str: string, max: number) => {
  if (!str) {
    return '';
  }
  return str.length > max ? `${str.substr(0, max - 1)}...` : str;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const convertToSlug = (text: string) =>
  text
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '_')
    .replace('__', '_');

export const getExtension = (filename: string) => filename.split('.').pop();

export const middleEllipsis = (str: string, len: number) => {
  if (!str) {
    return '';
  }
  return `${str.substr(0, len)}...${str.substr(str.length - len, str.length)}`;
};

export function processCategory(category: string) {
  const processedKey = category.toLowerCase().trim().split(' ').pop();
  return processedKey;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitBroadcastSync() {
  const waitSyncTime = 5_000;
  return new Promise((resolve) => setTimeout(resolve, waitSyncTime));
}

export function isOnMobile() {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const mobile = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return mobile.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
}

export function isOnTablet() {
  const ua = navigator.userAgent;
  return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua);
}

export const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach((k) => {
    if (obj[k] === undefined || obj[k] === '' || obj[k] === null) {
      // eslint-disable-next-line no-param-reassign
      delete obj[k];
    }
  });
  return obj;
};

export function orderObject(object: any) {
  const newObject = removeEmpty(object);
  // eslint-disable-next-line no-return-assign,no-param-reassign,no-sequences
  return Object.entries(newObject)
    .sort()
    .reduce((o: any, [k, v]) => ((o[k] = v), o), {});
}

// 2K 3M notations formats
export const formatNumber = (n, decimals = 3) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(decimals)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(decimals)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(decimals)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(decimals)}T`;
  return n;
};

export const isVideo = (fileType: string) => /(video|mp4)/g.test(fileType);

export function convertMS(ms: number) {
  let h;
  let m;
  let s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s %= 60;
  h = Math.floor(m / 60);
  m %= 60;
  const d = Math.floor(h / 24);
  h %= 24;
  return { d, h, m, s };
}

export const acceptedFileFormats = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/avif',
  'video/mp4',
  'video/x-m4v',
  'video/quicktime',
  'video/*',
].join(',');

export const localRemoveHyphen = (locale = 'en') =>
  locale?.replace(/,\S+/g, '').replace(/-\S+/g, '');

export const mapLocaleLanguages = [
  { key: 'en', name: 'English' },
  { key: 'fr', name: 'Français' },
  { key: 'jp', name: '日本語' },
  { key: 'ru', name: 'Pусский' },
  { key: 'es', name: 'Español' },
  { key: 'id', name: 'Bahasa Indonesia' },
  { key: 'tr', name: 'Türk' },
];

export const calculateLaunchpadTimeLeft = (
  date = dayjs('2021-09-15T22:00:00+00:00').format(),
) => {
  const difference = +new Date(date) - +new Date();
  let timeLeft = `0 Days, 0 Hours, 0 Minutes, 0s`;

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    timeLeft = `${days} Days, ${hours} Hours, ${minutes} Minutes, ${seconds}s`;

    if (days < 1) {
      timeLeft = `${hours} Hours, ${minutes} Minutes, ${seconds}s`;
    }
  }

  return { timeLeft, difference };
};

export const disablePageScroll = () => {
  document.body.classList.toggle('is-drawer-open');
};

export const computeFiatPrice = (price, defaultAssetPrice) => {
  // eslint-disable-next-line no-restricted-globals
  const fieldValueIsValid = price && !isNaN(price);

  if (!fieldValueIsValid || !defaultAssetPrice) {
    return null;
  }

  const commission = new BigNumber(configService.getServiceFee())
    .multipliedBy(price)
    .multipliedBy(defaultAssetPrice);

  return fieldValueIsValid && defaultAssetPrice
    ? `USD ${new BigNumber(price)
        .multipliedBy(defaultAssetPrice)
        .minus(commission)
        .toFixed(2)}`
    : '';
};

export const getUrlFileType = (url) => {
  if (!url) return null;
  const fileExtension = url.substring(url.lastIndexOf('.'), url.length);

  if (
    fileExtension &&
    ['.mp4', '.mov', '.qt'].includes(fileExtension.toLowerCase())
  ) {
    return 'video';
  }
  return 'image';
};

export const getUrlFileInfo = async (url): Promise<File> => {
  try {
    const response = await fetch(url);

    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });

    return file;
  } catch (error) {
    return null;
  }
};

export const responsiveText = (text: string, element: HTMLElement) => {
  if (!text) return null;

  const textLength = text.length;

  if (textLength > 8 && textLength <= 10) {
    element.style.fontSize = '25px';
  }
  if (textLength > 11 && textLength <= 15) {
    element.style.fontSize = '20px';
  }
  if (textLength > 16 && textLength <= 20) {
    element.style.fontSize = '18px';
  }
  if (textLength > 20 && textLength <= 25) {
    element.style.fontSize = '16px';
  }
  if (textLength > 26) {
    element.style.fontSize = '14px';
  }

  return element;
};

export const formatToUSD = (amount, sign = '$') =>
  `${sign}${new Intl.NumberFormat().format(amount || 0)}`;

export const isEmpty = (obj: object) =>
  obj &&
  Object.keys(obj).length &&
  Object.getPrototypeOf(obj) === Object.prototype;

export const toSha256 = (str: string) =>
  Web3.utils.keccak256(str).replace('0x', '');

export const calculateOfferTimeLeft = (
  date = dayjs('2021-09-15T22:00:00+00:00').format(),
) => {
  const difference = +new Date(date) - +new Date();
  let timeLeft = `0 Days, 0 Hours, 0 Minutes, 0s`;

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);

    timeLeft = `${days} Days`;

    if (days < 1) {
      timeLeft = `${hours} Hours`;
    }

    if (hours < 1) {
      timeLeft = `${minutes} Minutes`;
    }
  }

  return { timeLeft, difference };
};
