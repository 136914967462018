import { IStakingStateItem } from '../lib/@Types';

export const STAKING_DEFAULT: IStakingStateItem = {
  rewards: 0,
  rewardDuration: 0,
  userStake: 0,
  total: 0,
  apy: 0,
  earliestExitTime: 0,
  earlyExitPenaltyPercentage: 0,
  exitTime: 0,
  minStakePeriod: 0,
  allowance: 0,
  tokenInfos: {
    rewardTokenName: 'AIRT',
    rewardTokenSymbol: 'AIRT',
    stakingTokenName: 'AIRT',
    stakingTokenSymbol: 'AIRT',
  },
  hasLoaded: false,
};
