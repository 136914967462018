import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { PageHeader } from './PageHeader';
import {
  allCollectionDataState,
  allNftDataState,
  allTopTradersState,
  connectionAccountState,
  homeAuctionsListState,
  searchResultState,
  smallDeviceSearchTriggerState,
  statusesState,
  StakingState,
  allAuctionsDataState,
} from '../../recoil/atoms';
import { storageService } from '../../lib/db/StorageService';
import { PageMetadata } from './PageMetadata';
import { TraderTypeSelected } from '../../recoil/models';
import { isEmpty, isOnMobile } from '../../lib/Utils';
import { PageFooter } from './PageFooter';
import { PageBanner } from './PageBanner';
import { NavMenu } from './NavMenu';
import { retryService } from '../../lib/services/RetryService';
import { InfoErrorIcon } from '../../lib/Resources';
import { IStakingState } from '../../lib/@Types';
import { localCache } from '../../lib/db/LocalCache';

// eslint-disable-next-line react/prop-types
export const Layout = ({ children }) => {
  const connectionAccount = useRecoilValue(connectionAccountState);
  const showResultModal = useRecoilValue(searchResultState);
  const [nftDataList, setNftDataList] = useRecoilState(allNftDataState);
  const [collectionList, setCollectionList] = useRecoilState(
    allCollectionDataState,
  );
  const [auctionList, setAuctionList] = useRecoilState(allAuctionsDataState);
  const setStakingState = useSetRecoilState(StakingState);
  const setTopTraders = useSetRecoilState(allTopTradersState);
  const setHomeAuctionsList = useSetRecoilState(homeAuctionsListState);
  const [statuses, setStatuses] = useRecoilState(statusesState);
  const smallDeviceSearchTrigger = useRecoilValue(
    smallDeviceSearchTriggerState,
  );

  const didMountRef = useRef(false);

  const { t } = useTranslation();

  useEffect(() => {
    let unmounted = false;

    const syncAssetData = async () => {
      if (!nftDataList.hasLoaded) {
        const [nftData, topTradersData] = await Promise.all([
          storageService.findAllNfts(isOnMobile() ? 8 : 12),
          storageService.loadTopTraders(),
        ]);

        setNftDataList((val) => ({
          ...val,
          nfts: nftData.nfts,
          categories: nftData.categories,
          hasLoaded: true,
        }));

        setTopTraders({
          traderTypeSelected: TraderTypeSelected.SELLER,
          topTraders: topTradersData,
          hasLoaded: true,
        });

        setHomeAuctionsList(topTradersData?.auctions || []);
      }

      if (!collectionList.hasLoaded) {
        const [collectionData] = await Promise.all([
          storageService.findAllCollections(isOnMobile() ? 8 : 12),
        ]);

        setCollectionList({
          collections: collectionData.collections,
          hasLoaded: true,
        });
      }

      if (!auctionList.hasLoaded) {
        const auctionNfts = await storageService.findAllNfts(
          isOnMobile() ? 8 : 12,
          'auctions',
        );

        setAuctionList({
          auctions: auctionNfts.nfts,
          hasLoaded: true,
        });
      }

      const storedStakingValues = localCache.getStakingValues();

      if (!isEmpty(storedStakingValues)) {
        const chainPools = await storageService.getStakingData();

        setStakingState((state) => ({
          ...state,
          ...((chainPools as unknown) as IStakingState),
        }));
      }
    };

    if (!didMountRef.current) {
      syncAssetData();
      retryService.startRetryAll();
      didMountRef.current = true;
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      unmounted = true;
    };
  }, [setNftDataList]);

  useEffect(() => {
    const fetchStatuses = async () => {
      const response = await storageService.getAllStatuses();

      if (response) {
        setStatuses(response.statuses);
      }
    };

    if (!statuses.length) {
      fetchStatuses();
    }
  }, [statuses]);

  return (
    <>
      <PageBanner />
      <div className="test-error-message-wrapper">
        {connectionAccount.network &&
        connectionAccount.network.type === 'warning' ? (
          <>
            {statuses
              .filter(
                (status) => status.name === 'bsc_network' && status.display,
              )
              .map((status) => (
                <div
                  className="error-message-wrapper"
                  key={`${status.name}_${status.message}`}
                >
                  <div className="error-message layout-container">
                    <div className="flex">
                      <div className="error-message-icon">
                        <img src={InfoErrorIcon} alt="Warning Error Icon" />
                      </div>
                      <p>{status.message}</p>
                    </div>
                    <div className="error-message-btn-wrapper">
                      <a
                        href={status.externalLink || 'https://www.airnfts.com'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="error-message-btn">
                          {t('Learn more')}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <div />
        )}
      </div>
      <div
        className={`layout-container ${
          showResultModal &&
          !smallDeviceSearchTrigger &&
          'page-header-container'
        }`}
      >
        <PageHeader />
      </div>
      <div className="layout-container main-container">
        <PageMetadata />
        {children}
      </div>
      <NavMenu />
      <PageFooter />
    </>
  );
};
