import React, { CSSProperties, FC } from 'react';

interface IProps {
  style?: CSSProperties;
  className?: string;
}

const ScamPattern: FC<IProps> = ({ style, className }: IProps) => (
  <svg
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={style}
    className={className}
  >
    <circle cx="50" cy="50" r="50" fill="url(#pattern0)" />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="0.256"
        height="0.256"
      >
        <use xlinkHref="#image0_4969_17396" transform="scale(0.004)" />
      </pattern>
      <image
        id="image0_4969_17396"
        width="64"
        height="64"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAA40lEQVR4Xu3bQQ6EQAhEUbj/
oXsO8Sdh4XOvJAi/qkF3Zt6E6710++xuiD6T40uACtACqYlzD2IACFKBkoHcgmSQDJJBMngKIT6ADygF6DSYfcCLTzg/
z0eGrASogDbT0gKxB2MB5pkiBoBgrEEMwIBjLx9fAAiCIAhygmkkRgYjhWMHditsL2AvYC+QIHjdwzk+BmAABmBAWc1kCF0bKRAEQRAEQRAMGaACbaCUz/
P5BRiKxhQaiV07uRjfYgQDMKDpGAhGCMUCzD4CBEEw1iAGYIBPZMJh+g8/P8cKpAJfV4EfMee/
sLtaEFIAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);

ScamPattern.defaultProps = {
  style: {},
  className: 'absolute scam-pattern',
};

export default ScamPattern;
