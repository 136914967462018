import React, { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { Avatar, Badge } from 'antd';
import Identicon from 'react-identicons';
import { useTranslation } from 'react-i18next';
import { connectionAccountState } from '../../recoil/atoms';
import UserAvatar from './UserAvatar';
import useMediaQuery from '../hooks/useMediaQuery';

const SubConnectMenuItem = ({ connecting }: { connecting: boolean }) => {
  const { t } = useTranslation();
  const localConnectionAccount = useRecoilValue(connectionAccountState);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (localConnectionAccount.address && !localConnectionAccount.userAvatarUrl) {
    return (
      <div className="connect-menu-avatar-bg">
        <Badge
          count={localConnectionAccount.notificationsCount}
          offset={[0, 9]}
        >
          <Avatar
            shape="circle"
            size="large"
            src={
              <Identicon
                string={localConnectionAccount.address}
                size={isMobile ? 40 : 45}
              />
            }
          />
        </Badge>
      </div>
    );
  }

  if (localConnectionAccount.address && localConnectionAccount.userAvatarUrl) {
    return (
      <div className="connect-menu-avatar-bg">
        <Badge
          count={localConnectionAccount.notificationsCount}
          offset={[0, 9]}
        >
          <UserAvatar
            user={localConnectionAccount}
            isClickable={false}
            className="user-avatar-container page-header-avatar"
          />
        </Badge>
      </div>
    );
  }

  return (
    <div className="create-menu-item">
      <div className="create-menu-item-text overflow-ellipsis">
        {connecting ? t('Connecting') : t('Connect')}
      </div>
    </div>
  );
};

export default memo(SubConnectMenuItem);
