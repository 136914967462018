/* eslint-disable prettier/prettier */
import axios from 'axios';
import dayjs from 'dayjs';
import log from 'loglevel';
import * as Sentry from '@sentry/nextjs';
import Web3 from 'web3';
import http from '../http';

import { convertToSlug, removeEmpty } from '../Utils';
import {
  NftDeletion,
  NftDetailResponse,
  NftIDUpdate,
  NftListingUpdate,
  NftModel,
  NftPriceUpdate,
  NftSaleEvent,
  UpvoteEvent,
  UserGroupedNfts,
  AdminSpecialDropData,
  CollectionModel,
  SupportedChains,
  TTxEventType,
  NFTSignature,
  NftOfferPlacing,
  NftOfferAcceptance,
  NftOfferUpdate,
  NftOfferCancelling,
  NftListingCreation,
  V2ListedNftSaleEvent,
  CancelNftListing,
  ListingPriceUpdate,
} from '../models/NftModel';
import {
  UserAccount,
  UsernameAvailabilityCheck,
  UserProfileUpdate,
} from '../models/UserAccount';
import { ConnectionAccount, Sorter } from '../../recoil/models';
import S3Service, { allowedThumbnails } from '../services/S3Service';
import {
  AdminStatuses,
  TopTradersResponse,
  UploadFileData,
} from '../models/GeneralModels';
import { NeDBStorageService } from './nedb/NeDBStorageService';
import { AssetMarketPrice } from '../models/dtos/ApiModels';
import { IExternalNFT, IFollowSignature, StakingData } from '../@Types';
import { localCache } from './LocalCache';
import { chainEnvConfig } from '../../config/chainsConfig';

const DEFAULT_ASSET_PRICE_KEY = 'DEFAULT_ASSET_PRICE_KEY';
const ALL_ASSETS_PRICE_KEY = 'ALL_ASSETS_PRICE_KEY';
export const APP_DB_NAMESPACE = 'data-store';

class StorageService {
  public readonly neDBStorageService: NeDBStorageService;

  constructor() {
    this.neDBStorageService = new NeDBStorageService(APP_DB_NAMESPACE);
  }

  public getFileSize(uploadRawFile) {
    return uploadRawFile ? uploadRawFile.size / (1024 * 1024) : 0;
  }

  public getNftID(nftName: string) {
    return convertToSlug(`${nftName.substring(0, 20)}_${new Date().getTime()}`);
  }

  public saveDefaultAssetPrice(price: string, asset: SupportedChains) {
    try {
      if (typeof window === 'undefined') {
        return;
      }
      localStorage.setItem(StorageService.getAssetPriceKey(asset), price);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to set DEFAULT_ASSET_PRICE_KEY', e);
      Sentry.captureException(e);
    }
  }

  public saveAllAssetsPrices(assetPrices: AssetMarketPrice[]) {
    try {
      if (typeof window === 'undefined') {
        return;
      }
      localStorage.setItem(ALL_ASSETS_PRICE_KEY, JSON.stringify(assetPrices));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to set DEFAULT_ASSET_PRICE_KEY', e);
      Sentry.captureException(e);
    }
  }

  public getStoredDefaultAssetPrice(asset: SupportedChains): string | null {
    try {
      if (typeof window === 'undefined') {
        return null;
      }
      return localStorage.getItem(StorageService.getAssetPriceKey(asset));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to get DEFAULT_ASSET_PRICE_KEY', e);
      Sentry.captureException(e);
      return null;
    }
  }

  private static getAssetPriceKey(asset: SupportedChains) {
    return `${DEFAULT_ASSET_PRICE_KEY}_${asset}`;
  }

  private getBaseUrl() {
    return process.env.NEXT_PUBLIC_BACKEND_SERVICE_URL;
  }

  private getFioApiUrl() {
    return process.env.NEXT_PUBLIC_FIO_API_URL;
  }

  public getAllStoredAssetsPrices(): AssetMarketPrice[] | null {
    try {
      if (typeof window === 'undefined') {
        return null;
      }
      const allAssetsPrice = localStorage.getItem(ALL_ASSETS_PRICE_KEY);
      return JSON.parse(allAssetsPrice) as AssetMarketPrice[];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to get ALL_ASSETS_PRICE_KEY', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async uploadUrl({
    walletAddress,
    fileType,
    resourceType,
    signature,
    file,
    nftBaseID,
    dropID,
    thumbnailSize = '800x800',
  }: UploadFileData): Promise<{
    error: boolean;
    url?: string;
    urlThumbnail?: string;
    urlMedium?: string;
    urlCompressed?: string;
    fileType?: string;
  }> {
    try {
      let urlThumbnail = null;
      let urlCompressed = null;
      let urlMedium = null;

      const { data } = await axios.post(
        `${this.getBaseUrl()}/files/signed-url`,
        {
          walletAddress,
          fileType,
          resourceType,
          signature,
          nftBaseID,
          dropID,
        },
      );

      const urlLink = data.signedUrl.split('?')[0];
      const filePath = urlLink.split('.s3.amazonaws.com/')[1];

      if (
        allowedThumbnails.some((type) =>
          file.type.toLowerCase().includes(type.toLowerCase()),
        ) &&
        file.size / (1024 * 1024) < 4.5
      ) {
        urlThumbnail = `${process.env.NEXT_PUBLIC_AWS_IMAGE_HANDLER_URL}/${thumbnailSize}/${filePath}`; // Resize to thumbnail size
        urlMedium = `${process.env.NEXT_PUBLIC_AWS_IMAGE_HANDLER_URL}/1200x1200/${filePath}`; // 3x 1200x1200
        urlCompressed = `${process.env.NEXT_PUBLIC_AWS_IMAGE_HANDLER_URL}/filters:quality(70)/${filePath}`; // Reduce 30% quality
      }

      if (file.size / (1024 * 1024) < 1) {
        urlMedium = urlLink;
      }

      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': fileType },
      });

      return {
        error: false,
        url: urlLink,
        urlThumbnail,
        urlMedium,
        urlCompressed,
        fileType,
      };
    } catch (e) {
      log.log('upload Url error', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async uploadAndReturnFilePath(
    folderName: string,
    fileName: string,
    file: File,
    defautlThumbnailSize = '800x800',
  ): Promise<{
    error: boolean;
    url?: string;
    urlThumbnail?: string;
    urlMedium?: string;
    urlCompressed?: string;
    fileType?: string;
    defautlThumbnailSize?: string;
  }> {
    const ext = file.name.split('.').pop();
    const path = `${folderName}/${dayjs().format('YYYYMMDD')}/${convertToSlug(
      `${fileName}__${new Date().getTime()}`,
    )}`;

    const newFileName = `${path}.${ext || file.type.replace(/\S+\//g, '')}`;

    const thumbnailSize =
      folderName === 'profile-images' ? '200x200' : `${defautlThumbnailSize}`;

    console.log({ file, newFileName, thumbnailSize });

    const res = await S3Service.uploadFile({
      file,
      fileName: newFileName,
      thumbnailSize,
    });

    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async removeUploadedFile(
    fileUrl: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    s3File = false,
  ): Promise<any> {
    try {
      return await Promise.resolve();
      // let deletion;
      // if (s3File) {
      //   deletion = await S3Service.deleteFile(fileUrl);
      // } else {
      //   deletion = await firebaseInstance
      //     .storage()
      //     .refFromURL(fileUrl)
      //     .delete();
      // }
      // console.log('successful deletion =>>', deletion);

      // return deletion;
    } catch (e) {
      // For now we ignore deletions
      return Promise.resolve();
    }
  }

  public async saveNftData(nft: NftModel) {
    try {
      return await axios.post(`${this.getBaseUrl()}/nfts`, nft);
    } catch (e) {
      log.log('saveNftData error', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async setNftID(nftIDUpdate: NftIDUpdate) {
    try {
      return await http.put(`/nfts/${nftIDUpdate.baseID}/token`, nftIDUpdate);
    } catch (e) {
      log.log('saveNftData error', { e, nftIDUpdate });
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateNft(nftData: NftModel) {
    try {
      return await http.put(`/nfts/updateTokenID`, nftData);
    } catch (e) {
      log.log('update NFT error', { e });
      Sentry.captureException(e);
      return null;
    }
  }

  public async findNftByBaseID(
    nftBaseID: string,
    headers = {},
  ): Promise<NftDetailResponse | null> {
    try {
      const { data } = await http.get(`/nfts/${nftBaseID}`, {
        headers,
      });

      const response: NftDetailResponse = {
        nft: data?.nft || null,
        owner: data?.nft?.owner || data?.owner || null,
        creator: data?.nft?.creator || data?.creator || null,
        historyEvents: data?.historyEvents || [],
        ownerOtherNfts: data?.ownerOtherNfts || [],
        bids: data?.bids || [],
        offers: data?.offers || [],
      };
      return response;
    } catch (e) {
      log.log('findNftByBaseID error', { e, nftBaseID });
      Sentry.captureException(e);
      return null;
    }
  }

  public async setNewNftPrice(nftPriceUpdate: NftPriceUpdate) {
    try {
      return await http.put(
        `/nfts/${nftPriceUpdate.baseID}/price`,
        nftPriceUpdate,
      );
    } catch (e) {
      log.log('setNewNftPrice error', { e, nftPriceUpdate });
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateUserProfile(userProfileUpdate: UserProfileUpdate) {
    try {
      return await http.put(`/users/profile`, userProfileUpdate);
    } catch (e) {
      log.log('updateUserProfile error', { e, userProfileUpdate });
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateNFTListingStatus(nftListingUpdate: NftListingUpdate) {
    try {
      return await http.put(
        `/nfts/${nftListingUpdate.baseID}/listed`,
        nftListingUpdate,
      );
    } catch (e) {
      log.log('updateNFTListingStatus error', { e, nftListingUpdate });
      Sentry.captureException(e);
      return null;
    }
  }

  public async deleteNFT(nftDeletion: NftDeletion) {
    try {
      const response = await http.post(
        `/nfts/${nftDeletion.nftBaseID}/delete`,
        nftDeletion,
      );
      return response;
    } catch (e) {
      log.log('deleteNFT error', { e, nftDeletion });
      Sentry.captureException(e);
      return null;
    }
  }

  public async loadTopSellers() {
    try {
      const topSellers = await http.get(`/sellers`);
      return topSellers.data.sellers as UserAccount[];
    } catch (e) {
      log.log('loadTopSellers error', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async loadCurrentNFTAuctions(): Promise<NftModel[]> {
    try {
      const auctions = await http.get(`/nfts/auctions?open=true&verified=true`);
      return auctions.data.nfts;
    } catch (e) {
      return [];
    }
  }

  public async loadTopTraders(): Promise<TopTradersResponse> {
    try {
      const { data } = await http.get(`/users/top-traders`);

      return {
        verified: data.verifiedTraders,
        specialDrops: data.specialDrops,
        auctions: await this.loadCurrentNFTAuctions(),
      } as any;
    } catch (e) {
      log.log('loadTopTraders error', e);
      Sentry.captureException(e);
      return null;
    }
  }

  async findAccountWhereAddressOrUsername(
    defaultAddress: string,
    params = {},
    headers = {},
  ): Promise<UserAccount> {
    try {
      const { data } = await http.get(`/users/${defaultAddress}`, {
        headers,
        params,
      });

      return {
        collections: data?.collections || [],
        ...data.user,
      } as UserAccount;
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  }

  async checkAccountWhereUsername(
    username: string,
  ): Promise<UsernameAvailabilityCheck> {
    try {
      const response = await http.get(`/users/${username}/check-username`);

      if (response.status !== 200 || response.data.error) {
        return null;
      }

      return response.data as UsernameAvailabilityCheck;
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  }

  async findAllNfts(
    limit?: number,
    category: string = '',
    sortOrder?: Sorter,
    page: number = 1,
  ) {
    const loadLimit = limit || 12;

    const prefixURL = '/nfts/feed';

    try {
      const params = removeEmpty({
        limit: loadLimit,
        page,
        category,
        sortField: sortOrder?.field,
        sortOrder: sortOrder?.ordering,
        chain: sortOrder?.chain,
        maxPrice: sortOrder?.maxPrice,
        minPrice: sortOrder?.minPrice,
      });

      const response = await http.get(prefixURL, { params });

      const nfts = response.data.nfts as NftModel[];
      const categories = response.data.categories as string[];
      const hasMore = response.data.meta.page < response.data.meta.totalPages;

      return {
        nfts,
        categories,
        hasMore,
      };
    } catch (e) {
      Sentry.captureException(e);
      return { error: e };
    }
  }

  async findAllCollections(limit?: number, sortOrder?: Sorter) {
    const loadLimit = limit || 12;
    try {
      const url = '/nfts-drops/collections';

      const params = removeEmpty({
        limit: loadLimit,
        sortField: sortOrder?.field,
        sortOrder: sortOrder?.ordering,
        chain: sortOrder?.chain,
        nftFloorPrice: sortOrder?.minPrice,
        nftHighPrice: sortOrder?.maxPrice,
      });

      const response = await http.get(url, { params });
      const collections = response.data.drops as CollectionModel[];

      return {
        collections,
      };
    } catch (e) {
      Sentry.captureException(e);
      return {};
    }
  }

  async findAllUsers({
    filterBy = '',
    page = 1,
    walletAddress = '',
  }: {
    page?: number;
    filterBy?: string;
    walletAddress?: string;
  }) {
    try {
      let url = `/users?page=${page}`;
      if (filterBy) {
        url = `${url}&${filterBy}=true`;
      }
      if (walletAddress) {
        url = `${url}&walletAddress=${walletAddress}`;
      }
      const { data } = await http.get(url);
      return {
        users: data.users as UserAccount[],
        usersMeta: data.meta,
      };
    } catch (e) {
      Sentry.captureException(e);
      return {};
    }
  }

  async findAllDrops({
    filterBy = '',
    page = 1,
    walletAddress = '',
  }: {
    page?: number;
    filterBy?: string;
    walletAddress?: string;
  }) {
    try {
      let url = `/nfts-drops/admin?page=${page}`;
      if (filterBy) {
        url = `${url}&${filterBy}=true`;
      }
      if (walletAddress) {
        url = `${url}&walletAddress=${walletAddress}`;
      }
      const { data } = await http.get(url);
      return {
        drops: data.drops as AdminSpecialDropData[],
        dropsMeta: data.meta,
      };
    } catch (e) {
      Sentry.captureException(e);
      return {};
    }
  }

  async submitCurationUpdate({
    walletAddress,
    action,
    payload,
  }: {
    walletAddress: string;
    action: string;
    payload: {
      signature: string;
    };
  }) {
    try {
      const { data } = await http.post(
        `/users/admin/${walletAddress}/${action}`,
        payload,
      );

      return {
        user: data?.user as UserAccount,
        message: (data?.message as string) || null,
      };
    } catch (e) {
      Sentry.captureException(e);
      return {
        success: false,
        user: (e?.response?.data?.user as UserAccount) || null,
        error: (e?.response?.data?.message as string) || null,
      };
    }
  }

  async updateAccountType({
    walletAddress,
    payload,
  }: {
    walletAddress: string;
    payload: {
      signature: string;
    };
  }) {
    try {
      const { data } = await http.put(
        `/users/admin/${walletAddress}/account-type`,
        payload,
      );

      return {
        user: data?.user as UserAccount,
        message: (data?.message as string) || null,
      };
    } catch (e) {
      Sentry.captureException(e);
      return {
        success: false,
        user: (e?.response?.data?.user as UserAccount) || null,
        error: (e?.response?.data?.message as string) || null,
      };
    }
  }

  async submitSpecialUpdate({
    dropID,
    payload,
  }: {
    dropID: string;
    payload: {
      published?: boolean;
      signature?: string;
      deactivatedAt?: string;
    };
  }) {
    try {
      const { data } = await http.put(
        `/nfts-drops/admin/${dropID}/update`,
        payload,
      );

      return {
        drop: data?.drop as UserAccount,
        message: (data?.message as string) || null,
      };
    } catch (e) {
      Sentry.captureException(e);
      return {
        success: false,
        drop: (e?.response?.data?.user as UserAccount) || null,
        error: (e?.response?.data?.message as string) || null,
      };
    }
  }

  async getAllStatuses() {
    try {
      const { data } = await http.get('statuses');
      return data as { statuses: AdminStatuses[] };
    } catch (error) {
      log.log('Error while searching nft', error);
      Sentry.captureException(error);
      return null;
    }
  }

  async updateStatus(id: number, payload) {
    try {
      const { data } = await http.put(`statuses/${id}`, payload);
      return data as { status: AdminStatuses };
    } catch (error) {
      log.log('Error while searching nft', error);
      Sentry.captureException(error);
      return null;
    }
  }

  async updateTransaction(
    transactionHash: string,
    eventType: TTxEventType,
    payload,
  ) {
    try {
      const { data } = await http.post(
        `transactions/${transactionHash}/${eventType}`,
        payload,
      );
      return data;
    } catch (error) {
      log.log('Error while searching nft', error);
      Sentry.captureException(error);

      if (error.response) {
        const { data: errData } = error.response;

        if (errData) {
          return errData;
        }
      }

      return null;
    }
  }

  async searchNfts(searchKey: string) {
    try {
      const response = await http.get(`/nfts/search/${searchKey}`);

      return response.data.docs as NftModel[];
    } catch (e) {
      log.log('Error while searching nft', e);
      Sentry.captureException(e);
      return [];
    }
  }

  async getUserNftLists(userAddress: string) {
    try {
      const response = await http.get(`/users/${userAddress}/nfts`);
      return (response.data?.docs || response.data?.nfts) as NftModel[];
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  }

  async getUserNftGrouped(userAddress: string, limit?: number, headers = {}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const loadLimit = limit || 12;
    try {
      const response = await http.get(
        `/users/${userAddress}/nfts/grouped?limit=${loadLimit}`,
        { headers },
      );

      const { data } = response;
      if (data?.user) {
        data.user.userFollowed = data.userFollowed;
      }

      return data as UserGroupedNfts;
    } catch (e) {
      log.log('error loading getUserNftGrouped', e);
      Sentry.captureException(e);
      return null;
    }
  }

  async followUser(walletAddress: string, body: IFollowSignature) {
    try {
      const { data } = await http.post(`/users/${walletAddress}/follow`, body);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }

  async unFollowUser(walletAddress: string, body: IFollowSignature) {
    try {
      const { data } = await http.delete(`/users/${walletAddress}/unfollow`, {
        data: body,
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }

  async postSaleEvent(nftSaleEvent: NftSaleEvent) {
    try {
      const response = await http.post(
        `/nfts/${nftSaleEvent.nftBaseID}/sales`,
        nftSaleEvent,
      );
      return response.data;
    } catch (e) {
      log.log('error while posting sale event: ', nftSaleEvent, e);
      Sentry.captureException(e);
      return null;
    }
  }

  async postNewUpvote(upvoteEvent: UpvoteEvent) {
    try {
      const response = await http.post(
        `/nfts/${upvoteEvent.baseID}/upvotes`,
        upvoteEvent,
      );
      return response.data;
    } catch (e) {
      log.log('error while postNewUpvote: ', upvoteEvent, e);
      Sentry.captureException(e);
      return null;
    }
  }

  async checkIfHasUpvotedAlready(
    walletAddress: string,
    nftBaseID: string,
  ): Promise<boolean> {
    try {
      const { data } = await http.get(
        `/nfts/${nftBaseID}/upvotes/check?walletAddress=${walletAddress}`,
      );
      return (data.hasUserEverVotedThisNft || data.upvoted) as boolean;
    } catch (e) {
      Sentry.captureException(e);

      log.log(
        'error while checkIfHasUpvotedAlready: ',
        { walletAddress, nftBaseID },
        e,
      );
      return false;
    }
  }

  async getExternalNFTs(userAddress: string): Promise<[]> {
    try {
      const { data } = await http.get(`/users/${userAddress}/external-nfts`);

      return data?.externalNfts || [];
    } catch (error) {
      Sentry.captureException(error);

      return [];
    }
  }

  async getExternalNftBySlug(slug: string): Promise<IExternalNFT> {
    try {
      const { data } = await http.get(`/users/external-nfts/${slug}`);

      return data.userExternalNft;
    } catch (error) {
      Sentry.captureException(error);
      return {} as IExternalNFT;
    }
  }

  async getUser(slug: string, referralCode?: string) {
    try {
      const { data } = await http.get(`/users/${slug}`, {
        params: { referralCode: referralCode || undefined },
      });

      if (data) {
        const user = data.user as ConnectionAccount;

        const referredUsers: ConnectionAccount[] = await this.getReferredUsers(
          user.id,
        );

        return [user, referredUsers || []];
      }
      return [];
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }

  async checkWalletAddress(address: string) {
    try {
      const { data } = await http.get(`/users/${address}/check`);

      return !!data.user;
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }

  async getUserByReferralCode(referralCode: string) {
    try {
      const { data } = await http.get(`users/${referralCode}/referral-code/`);

      return data.user as ConnectionAccount;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }

  async getReferredUsers(referredUserId: string) {
    try {
      const { data } = await http.get(`/users/${referredUserId}/referrals`);

      return data?.referredUsers || [];
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }

  async claimReferralReward(address: string) {
    try {
      const { data } = await http.post(`/users/${address}/referrals/claim`);

      return data;
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  }

  async getStakingData(): Promise<StakingData> {
    try {
      const { data } = await http.get('staking/chain-pool?platform=webapp');

      const chainPools = data.reduce((tokenInfos, pool) => {
        const storedStakingValues = localCache.getStakingValues(pool.poolToken);
        const totalStakedBalance = Number(
          Web3.utils.fromWei(pool.totalStakedBalance, 'ether'),
        );

        tokenInfos[pool.poolToken] = {
          ...pool,
          apy: pool.stakingAPY || storedStakingValues?.apy,
          totalStaked: totalStakedBalance || storedStakingValues?.totalStaked,
          stakeDuration:
            pool.rewardDurationInSeconds || storedStakingValues?.stakeDuration,
        };

        return tokenInfos;
      }, {});

      localCache.setStakingValues(chainPools);

      return chainPools;
    } catch (e) {
      Sentry.captureException(e);
      return {};
    }
  }

  async checkNFTSignature(nft: NftModel): Promise<NFTSignature> {
    try {
      const { data } = await axios.post(
        `${this.getFioApiUrl()}/v1/chain/get_nfts_contract`,
        {
          contract_address: chainEnvConfig[nft.chain].mintContractAddress,
          chain_code: nft.chain,
          token_id: nft.tokenID,
          // limit: 100,
          // offset: 0,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
          },
        },
      );

      return data?.nfts[0] as NFTSignature;
    } catch (error) {
      console.log('FIO', error);

      Sentry.captureException(error);
      return null;
    }
  }

  public async createNftListing(
    nftBaseID: string,
    nftListingData: NftListingCreation,
  ) {
    try {
      const response = await http.post(
        `/nfts/${nftBaseID}/create-listing`,
        nftListingData,
      );
      return response.data;
    } catch (e) {
      log.log('createNftListing error', { e, nftBaseID });
      Sentry.captureException(e);
      return null;
    }
  }

  public async makeOfferListing(nftOfferData: NftOfferPlacing) {
    try {
      const response = await http.post('/nfts-offers', nftOfferData);
      return response.data;
    } catch (e) {
      log.log('makeOfferNft error', { e });
      Sentry.captureException(e);
      return null;
    }
  }

  public async acceptOffer(nftOfferData: NftOfferAcceptance) {
    try {
      const response = await http.post('/nfts-offers/accept', nftOfferData);
      return response.data;
    } catch (e) {
      log.log('acceptOfferNft error', { e });
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateOffer(baseID: string, nftUpdateData: NftOfferUpdate) {
    try {
      const response = await http.put(`/nfts-offers/${baseID}`, nftUpdateData);
      return response.data;
    } catch (e) {
      log.log('acceptOfferNft error', { e });
      Sentry.captureException(e);
      return null;
    }
  }

  public async cancelOffer(nftOfferData: NftOfferCancelling) {
    try {
      const response = await http.post('/nfts-offers/cancel', nftOfferData);
      return response.data;
    } catch (e) {
      log.log('acceptOfferNft error', { e });
      Sentry.captureException(e);
      return null;
    }
  }

  public async postV2ListedSaleEvent(nftSaleEvent: V2ListedNftSaleEvent) {
    try {
      const response = await http.post(`/sales/v2-buy`, nftSaleEvent);
      return response.data;
    } catch (e) {
      log.log('error while posting sale event: ', nftSaleEvent, e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async cancelListing(nftBaseId: string, nftData: CancelNftListing) {
    try {
      const response = await http.put(
        `/nfts/${nftBaseId}/cancel-listing`,
        nftData,
      );
      return response.data;
    } catch (e) {
      log.log('error while posting sale event: ', nftData, e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateListingPrice(
    baseID: String,
    listingPriceUpdate: ListingPriceUpdate,
  ) {
    try {
      return await http.put(
        `/nfts/${baseID}/update-listing-price`,
        listingPriceUpdate,
      );
    } catch (e) {
      log.log('setNewNftPrice error', { e, listingPriceUpdate });
      Sentry.captureException(e);
      return null;
    }
  }
}

export const storageService = new StorageService();
