import React from 'react';

const MetamaskIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="white"
    />
    <path
      d="M24.7853 7.48242L17.3208 12.9583L18.7086 9.73125L24.7853 7.48242Z"
      fill="#E17726"
      stroke="#E17726"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.56733 7.48242L14.9654 13.0095L13.644 9.73125L7.56733
       7.48242ZM22.0981 20.1797L20.1117 23.1875L24.3646 24.3483L25.5826 20.2451L22.0981 20.1797ZM6.77734 20.2451L7.9879 24.3483L12.2334 23.1875L10.255 20.1792L6.77734 20.2451Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0037 15.0976L10.8232 16.8641L15.0318 17.0539L14.8914 12.5631L12.0037 15.0976ZM20.3468 15.0976L17.416 12.5129L17.32 17.0545L21.5284 16.8646L20.3468
       15.0976ZM12.2327 23.1873L14.78 21.968L12.5874 20.2742L12.2327 23.1873ZM17.5712 21.968L20.111 23.1873L19.7643 20.2742L17.5712 21.968Z"
      fill="#E27625"
      stroke="#E27625"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1118 23.1875L17.5713 21.9681L17.7787 23.604L17.756 24.2969L20.1118 23.1875ZM12.2334 23.1875L14.596 24.2976L14.5812 23.604L14.7807 21.9681L12.2334 23.1875Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6397 19.1938L12.5283 18.5807L14.0196 17.9016L14.6397 19.1938ZM17.7116 19.1938L18.3311 17.9016L19.8303 18.5807L17.7116 19.1938Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2333 23.1875L12.6027 20.1798L10.2549 20.2451L12.2333 23.1875ZM19.7496 20.1798L20.1116 23.1875L22.0973 20.2451L19.7496 20.1798ZM21.5296
       16.8643L17.3205 17.0547L17.712 19.1937L18.3316 17.9015L19.8308 18.5806L21.5296 16.8643ZM12.5288 18.5806L14.0201 17.9015L14.6402 19.1937L15.0318 17.0547L10.8232 16.8647L12.5288 18.5806Z"
      fill="#CC6228"
      stroke="#CC6228"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8232 16.8643L12.5879 20.2743L12.5288 18.5806L10.8232 16.8643ZM19.8308 18.5806L19.7644 20.2743L21.5291 16.8647L19.8308 18.5806ZM15.0318
       17.0547L14.6402 19.1937L15.1347 21.7203L15.2461 18.3902L15.0318 17.0547ZM17.3204 17.0547L17.1136 18.3833L17.2171 21.7203L17.7115 19.1937L17.3204 17.0547Z"
      fill="#E27525"
      stroke="#E27525"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7116 19.1939L17.2172 21.7205L17.5712 21.9683L19.7639 20.2746L19.8303 18.5808L17.7116 19.1939ZM12.5283 18.5808L12.5874 20.2746L14.7801
       21.9683L15.1347 21.7205L14.6403 19.1939L12.5283 18.5808Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.756 24.2976L17.7787 23.6038L17.5865 23.4433H14.7659L14.5812 23.6038L14.596 24.2969L12.2334 23.1875L13.0603 23.8592L14.7369 25.0057H17.6088L19.2921 23.8592L20.1118 23.1875L17.756 24.2976Z"
      fill="#C0AC9D"
      stroke="#C0AC9D"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5715 21.968L17.2168 21.72H15.1356L14.7809 21.968L14.582 23.6036L14.7662 23.443H17.5869L17.7789 23.6036L17.5715 21.968Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.1028 13.3161L25.7296 10.2942L24.785 7.48242L17.5717 12.7757L20.348 15.0972L24.2685 16.2298L25.1324 15.229L24.7556
       14.9586L25.354 14.4188L24.8959 14.0683L25.4938 13.6153L25.1028 13.3161ZM6.62207
       10.2936L7.2569 13.3161L6.85111 13.6158L7.45639 14.0683L6.99887 14.4188L7.59677 14.9591L7.21996 15.2296L8.08383 16.2293L12.0048 15.0978L14.7806 12.7762L7.56722 7.48242L6.62207 10.2936Z"
      fill="#763E1A"
      stroke="#763E1A"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2681 16.2295L20.3478 15.098L21.5288 16.865L19.7641 20.2746L22.0977 20.2453H25.5822L24.2681
       16.2295ZM12.004 15.098L8.08355 16.2295L6.77637 20.2453H10.2546L12.5876 20.2746L10.8229 16.865L12.004 15.098ZM17.3203 17.0549L17.5715 12.7759L18.7081 9.73145H13.6436L14.7803 12.7759L15.0315
       17.0549L15.1276 18.3983L15.1349 21.7205H17.2168L17.2241 18.3983L17.3203 17.0549Z"
      fill="#F5841F"
      stroke="#F5841F"
      strokeWidth="0.04"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MetamaskIcon;
