import React from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { StakingState } from '../../recoil/atoms';
import { localCache } from '../../lib/db/LocalCache';

export const PageBanner = () => {
  const { t } = useTranslation();
  const stakeState = useRecoilValue(StakingState);
  const storedStakingValues = localCache.getStakingValues();

  return (
    <div className="top-banner">
      <div className="top-banner-content">
        <Row style={{ width: '100%' }}>
          <Col xs={19} sm={14} md={14} lg={12} xl={8} xxl={8}>
            <div className="top-banner-text">
              <span className="address-section-airt">
                <span>{t('💰 Earn up to')} </span>
                {Number(
                  Number(
                    stakeState.AIRT.apy || storedStakingValues?.apy || 0,
                  )?.toFixed(2),
                ).toLocaleString()}
                % APY
              </span>
            </div>
          </Col>

          <Col xs={1} sm={4} md={7} lg={10} xl={14} xxl={14} />

          <Col xs={4} sm={6} md={3} lg={2} xl={2} xxl={2}>
            <div className="add-liquidity-btn-div">
              <Link href="/staking">
                <Button className="add-liquidity-btn">{t('Earn Now')}</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
