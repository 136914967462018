class LocalStorageService {
  public setHasUpvotedArt(walletAddress: string, nftTokenID: string) {
    if (process.browser) {
      localStorage.setItem(`Addr:${walletAddress}_NFT:${nftTokenID}`, 'true');
    }
  }

  public setHasAcceptedTerms(walletAddress: string) {
    if (process.browser) {
      localStorage.setItem(`Addr:${walletAddress}-AcceptedTerms`, 'true');
    }
  }

  public setSubscriptionToken({
    tokenStorageKey,
    token,
  }: {
    token: string;
    tokenStorageKey: string;
  }) {
    if (process.browser) {
      localStorage.setItem(tokenStorageKey, token);
    }
  }

  public checkIfItHasUpvoted(
    walletAddress: string,
    nftTokenID: string,
  ): boolean {
    if (process.browser) {
      return JSON.parse(
        localStorage.getItem(`Addr:${walletAddress}_NFT:${nftTokenID}`),
      );
    }
    return null;
  }

  public checkHasAcceptedTerms(walletAddress: string): boolean {
    if (process.browser) {
      return JSON.parse(
        localStorage.getItem(`Addr:${walletAddress}-AcceptedTerms`),
      );
    }

    return false;
  }

  public setHasPaidFee(nftName: string, fileSize: number) {
    localStorage.setItem(
      LocalStorageService.getHasPaidFeeKey(nftName, fileSize),
      'true',
    );
  }

  public checkIfHasPaidMintFee(nftName: string, fileSize: number): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    return JSON.parse(
      localStorage.getItem(
        LocalStorageService.getHasPaidFeeKey(nftName, fileSize),
      ),
    );
  }

  public setMintTransactionAfterSuccess(
    nftName,
    fileSize,
    mintTransactionHash,
  ) {
    localStorage.setItem(
      LocalStorageService.getMintTransactionHashKey(nftName, fileSize),
      mintTransactionHash,
    );
  }

  public checkIfSubsequentTxWasSuccessAfterStorageFee(
    nftName: string,
    fileSize: number,
  ): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    return (
      localStorage.getItem(
        LocalStorageService.getMintTransactionHashKey(nftName, fileSize),
      ) !== null
    );
  }

  public setStoragePaidTxHash(nftName, fileSize, transactionHash: string) {
    localStorage.setItem(
      LocalStorageService.getFeeStorageTxHashLocalStorageKey(nftName, fileSize),
      transactionHash,
    );
  }

  public getStoragePaidTxHash(nftName, fileSize) {
    return localStorage.getItem(
      LocalStorageService.getFeeStorageTxHashLocalStorageKey(nftName, fileSize),
    );
  }

  public setStorageFeeValue(nftName: string, finalFee: string) {
    localStorage.setItem(
      LocalStorageService.getStorageTxFeeKey(nftName),
      finalFee,
    );
  }

  public getStorageFeeValue(nftName: string) {
    return localStorage.getItem(
      LocalStorageService.getStorageTxFeeKey(nftName),
    );
  }

  public setStakingValues(
    poolValues: Record<
      string,
      {
        apy: number;
        totalStaked: number;
        stakeDuration: number;
        stakingTokenName: string;
        stakingTokenSymbol: string;
        rewardTokenName: string;
        rewardTokenSymbol: string;
      }
    >,
  ) {
    const values = JSON.parse(localStorage.getItem('tgsgQWE-2uhe')) || {};

    localStorage.setItem(
      'tgsgQWE-2uhe',
      JSON.stringify({ ...values, ...poolValues }),
    );
  }

  public getStakingValues(poolToken: string = 'AIRT') {
    if (typeof window === 'undefined') {
      return null;
    }
    const values = JSON.parse(localStorage.getItem('tgsgQWE-2uhe'));
    return values?.[poolToken] || {};
  }

  private static getFeeStorageTxHashLocalStorageKey(nftName, fileSize) {
    return `${nftName}_${fileSize}_storage_fee_tx`;
  }

  private static getStorageTxFeeKey(nftName) {
    return `${nftName}_tx_value`;
  }

  private static getHasPaidFeeKey(nftName: string, fileSize: number) {
    return `${nftName}_${fileSize}`;
  }

  private static getMintTransactionHashKey(nftName: string, fileSize: number) {
    return `name_${nftName}_size_${fileSize}_mint_tx_hash`;
  }
}

export const localCache = new LocalStorageService();
