import * as Sentry from '@sentry/nextjs';

const dsn =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://759ce2ab69e24f60b3fa6bf5e2b8273e@o707517.ingest.sentry.io/5778407';

Sentry.init({
  dsn,
  environment: process.env.NODE_ENV || 'staging',
});
