import React, { FC } from 'react';
import Router from 'next/router';

type Props = {
  href: string;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const HrefLink: FC<Props> = ({ children, href, className, onClick }) => {
  const onHrefClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (onClick) {
      onClick(e);
      return;
    }
    await Router.push(href);
  };

  return (
    <a href={href} className={className} onClick={onHrefClick}>
      {children}
    </a>
  );
};

export default HrefLink;
