import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import http from './http';

import { NetworkState } from './models/NftModel';
import { MarketPriceDTO } from './models/dtos/ApiModels';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export interface PriceData {
  symbol?: string;
  price?: string;
}

class MarketApiService {
  public async loadAllAssetsPrices(): Promise<MarketPriceDTO> {
    try {
      const response = await http.get<MarketPriceDTO>(
        `/prices/assets/market-prices`,
      );
      return response.data;
    } catch (e) {
      Sentry.captureException(e);
      return {
        data: [],
        statusCode: 400,
      };
    }
  }

  public async loadNetworkState(): Promise<NetworkState> {
    try {
      const response = await http.get(`/network/state`);
      return response.data as NetworkState;
    } catch (e) {
      Sentry.captureException(e);
      return {
        type: null,
        message: null,
        link: null,
      };
    }
  }
}

export const marketApi = new MarketApiService();
