/* eslint-disable import/no-cycle */
import { SupportedChains } from '../models/NftModel';
import { chainEnvConfig } from '../../config/chainsConfig';

class ConfigService {
  private static MINIMUM_SEARCH_CHARACTERS = 2;

  public getServiceFee() {
    return process.env.NEXT_PUBLIC_COMMISSION_FEE;
  }

  public getMinPercentBitIncrease() {
    return Number(
      (process.env.NEXT_BID_MIN_PERCENT_INCREASE || '10') as string,
    );
  }

  public getBidPlacementServiceFee() {
    return Number(
      (process.env.NEXT_BID_PLACEMENT_SERVICE_FEE || '0.001') as string,
    );
  }

  public getMaxFileSize(): number {
    return Number(process.env.NEXT_PUBLIC_MAX_FILE_SIZE_UPLOAD as string);
  }

  public getMinimumFileSize(): number {
    return Number(process.env.NEXT_PUBLIC_MIN_FILE_SIZE_UPLOAD as string);
  }

  public getMaxProfileSize(): number {
    return Number(
      process.env.NEXT_PUBLIC_MAX_PROFILE_FILE_SIZE_UPLOAD as string,
    );
  }

  public getDollarPerMbRate(fileType = 'image'): number {
    let cost = process.env.NEXT_PUBLIC_DOLLAR_PER_MB_RATE as string;
    if (fileType?.includes('video')) {
      cost = (process.env.NEXT_PUBLIC_DOLLAR_PER_MB_RATE_MP4 ||
        process.env.NEXT_PUBLIC_DOLLAR_PER_MB_RATE) as string;
    }
    return Number(cost);
  }

  public getDeployerAddress(chain: SupportedChains = 'bsc'): string {
    return (
      chainEnvConfig[chain].deployerAddress ||
      chainEnvConfig.bsc.deployerAddress
    );
  }

  public getUpvoteFundsReceiverAddress(): string {
    return process.env.NEXT_PUBLIC_UPVOTE_RECEIVER_ADDRESS as string;
  }

  public getLaunchpadFundsReceiverAddress(): string {
    return process.env.NEXT_PUBLIC_LAUNCHPAD_RECEIVER as string;
  }

  public getUpvotePerDollar(): number {
    return Number(process.env.NEXT_PUBLIC_UPVOTE_PER_DOLLAR as string);
  }

  public getDropCreationFeeInBNB(): number {
    return Number(
      (process.env.NEXT_PUBLIC_DROP_CREATION_FEE_IN_BNB || '0.5') as string,
    );
  }

  public getAuctionCreationFeeInBNB(): string {
    return (process.env.NEXT_PUBLIC_AUCTION_CREATION_FEE_IN_BNB ||
      '0.005') as string;
  }

  public getDropDefaultStayDays(): number {
    return Number(
      (process.env.NEXT_PUBLIC_DROP_DEFAULT_STAY_DAYS || '5') as string,
    );
  }

  public getMaxNftsPerDrop(): number {
    return Number((process.env.NEXT_PUBLIC_DROP_MAX_NFTS || '1000') as string);
  }

  public getAppUrl(): string {
    return process.env.NEXT_PUBLIC_APP_URL as string;
  }

  public getV1ApiBaseURL(): string {
    return process.env.NEXT_PUBLIC_V1_API_BACKEND as string;
  }

  public getMinimumSearchCharCount() {
    return ConfigService.MINIMUM_SEARCH_CHARACTERS;
  }

  public getAIRTTokenContractAddress(chain: SupportedChains = 'bsc'): string {
    return (
      chainEnvConfig[chain].airtTokenContactAddress ||
      chainEnvConfig.bsc.airtTokenContactAddress
    );
  }

  public getMintContractAddress(chain: SupportedChains = 'bsc'): string {
    return (
      chainEnvConfig[chain].mintContractAddress ||
      chainEnvConfig.bsc.mintContractAddress
    );
  }

  public getExplorerUrl(chain: SupportedChains = 'bsc'): string {
    return (
      chainEnvConfig[chain].networkExplorer ||
      chainEnvConfig.bsc.networkExplorer
    );
  }

  public getEscrowAccountAddress(chain: SupportedChains = 'bsc'): string {
    return (
      chainEnvConfig[chain].auctionEscrowAddress ||
      chainEnvConfig.bsc.auctionEscrowAddress
    );
  }

  public getEnv(): string {
    return process.env.NEXT_PUBLIC_ENV as string;
  }

  public getGasLimitAdjusted(
    chain: SupportedChains = 'bsc',
    gasLimit: number,
  ): number {
    return (chainEnvConfig[chain]?.gasLimitLevel || 1) * gasLimit;
  }

  public getEVMNullAddress(): string {
    return '0x0000000000000000000000000000000000000000';
  }
}

export const configService = new ConfigService();
