import { DatabaseManager } from './DatabaseManager';
import {
  DropCreationModel,
  DropDurationExtensionModel,
  LaunchpadPurchaseModel,
  RetryStatus,
  AuctionCreationFeePaymentModel,
  AuctionCreationFeePaymentQueryResult,
  AuctionCreationFeePaymentQuery,
} from './models/DBModels';

export class NeDBStorageService {
  private readonly db: DatabaseManager;

  constructor(namespace: string) {
    this.db = new DatabaseManager(namespace);
  }

  public async savePurchaseTransaction(
    launchpadPurchaseModel: LaunchpadPurchaseModel,
  ) {
    return this.db.launchpadPurchaseStore.update<LaunchpadPurchaseModel>(
      { _id: launchpadPurchaseModel.id },
      { $set: launchpadPurchaseModel },
      { upsert: true },
    );
  }

  public async retrieveAllPurchaseTransactions() {
    return this.db.launchpadPurchaseStore.find<LaunchpadPurchaseModel>({});
  }

  public async saveDropCreationTransaction(
    dropCreationModel: DropCreationModel,
  ) {
    return this.db.dropCreationStore.update<DropCreationModel>(
      { _id: dropCreationModel.creationFeeTransactionHash },
      { $set: dropCreationModel },
      { upsert: true },
    );
  }

  public async retrieveAllDropCreationTransactionByStatus(
    status: RetryStatus,
  ): Promise<DropCreationModel[]> {
    return this.db.dropCreationStore.find<DropCreationModel>({
      status,
    });
  }

  public async saveDropExtensionTransaction(
    dropDurationExtensionModel: DropDurationExtensionModel,
  ) {
    return this.db.dropExtensionStore.update<DropDurationExtensionModel>(
      { _id: dropDurationExtensionModel.transactionHash },
      { $set: dropDurationExtensionModel },
      { upsert: true },
    );
  }

  public async retrieveAllDropDurationExtensionsByStatus(
    status: RetryStatus,
  ): Promise<DropDurationExtensionModel[]> {
    return this.db.dropExtensionStore.find<DropDurationExtensionModel>({
      status,
    });
  }

  public async saveAuctionCreationFeePayment(
    auctionCreationFeePaymentModel: AuctionCreationFeePaymentModel,
  ) {
    return this.db.auctionFeePaymentStore.update<AuctionCreationFeePaymentModel>(
      {
        _id: auctionCreationFeePaymentModel.transactionReceipt.transactionHash,
      },
      { $set: auctionCreationFeePaymentModel },
      { upsert: true },
    );
  }

  public async findAuctionCreationFeePayment(nftTokenID: String) {
    return this.db.auctionFeePaymentStore.findOne<AuctionCreationFeePaymentModel>(
      {
        nftTokenID,
      },
    );
  }

  // Check if the user has already paid the auction creation fee, by checking if we have persisted a payment still valid
  public async hasPaidAuctionCreationFee(
    feePaymentQuery: AuctionCreationFeePaymentQuery,
  ): Promise<AuctionCreationFeePaymentQueryResult> {
    const auctionPayments = await this.db.auctionFeePaymentStore.find<AuctionCreationFeePaymentModel>(
      {
        nftTokenID: feePaymentQuery.nftTokenID,
        creatorAddress: feePaymentQuery.creatorAddress,
        chain: feePaymentQuery.chain,
      },
    );

    for (let index = 0; index < auctionPayments.length; index++) {
      const currentAuctionPayment = auctionPayments[index];
      if (
        currentAuctionPayment.auctionExpireTime > feePaymentQuery.currentTime
      ) {
        return {
          hasUserPaidAuctionCreationFee: true,
          paymentTransaction: currentAuctionPayment,
        };
      }
    }

    return {
      hasUserPaidAuctionCreationFee: false,
      paymentTransaction: null,
    };
  }
}
