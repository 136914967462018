import { TransactionReceipt } from 'web3-core';
import {
  LaunchpadPackage,
  SpecialDropModel,
  SupportedChains,
} from '../../../models/NftModel';

export enum LaunchpadPurchaseModelStatus {
  PENDING = 'PENDING',
  SUCCESSFULLY_SUBMITTED = 'SUCCESSFULLY_SUBMITTED',
}
export interface LaunchpadPurchaseModel {
  transactionReceipt: TransactionReceipt;
  launchpadPackage: LaunchpadPackage;
  buyerAddress: string;
  id: string;
  status: LaunchpadPurchaseModelStatus;
}

export enum RetryStatus {
  PENDING = 'PENDING',

  SUCCESSFULLY_SUBMITTED = 'SUCCESSFULLY_SUBMITTED',

  // This is marked after retrying a couple of times (3) and still failing
  FINALLY_FAILED = 'FINALLY_FAILED',
}

export interface DropDurationExtensionModel {
  status: RetryStatus;
  dropID: string;
  daysExtended: number;
  creatorAddress: string;
  transactionHash: string;
  registeredRetrials: number;
}

export interface DropCreationModel extends SpecialDropModel {
  status: RetryStatus;
  registeredRetrials: number;
}

export interface AuctionCreationFeePaymentModel {
  nftBaseID: string;
  creatorAddress: string;
  nftTokenID: string;
  auctionExpireTime: number;
  transactionReceipt: TransactionReceipt;
  chain?: SupportedChains;
}

export interface AuctionCreationFeePaymentQueryResult {
  hasUserPaidAuctionCreationFee: boolean;
  paymentTransaction: AuctionCreationFeePaymentModel;
}

export interface AuctionCreationFeePaymentQuery {
  nftTokenID: String;
  creatorAddress: String;
  currentTime: number;
  chain?: SupportedChains;
}
