import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import jp from './jp.json';
import ru from './ru.json';
import es from './es.json';
import id from './id.json';
import tr from './tr.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      ru: {
        translation: ru,
      },
      jp: {
        translation: jp,
      },
      es: {
        translation: es,
      },
      id: {
        translation: id,
      },
      tr: {
        translation: tr,
      },
    },
    lng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
