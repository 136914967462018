import { Modal } from 'antd';
import React, { FC } from 'react';
import { CloseActionBtn } from '../../lib/Resources';

interface IModalWrapper {
  title?: React.ReactNode;
  visible?: boolean;
  centered?: boolean;
  closable?: boolean;
  destroyOnClose?: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  footer?: React.ReactNode;
  closeIcon?: React.ReactNode;
  className?: string;
  width?: number;
  height?: number;
  forceRender?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const ModalWrapper: FC<IModalWrapper> = ({
  title,
  visible,
  centered,
  onCancel,
  footer,
  className,
  closeIcon,
  closable,
  destroyOnClose,
  children,
  width,
  height,
  forceRender,
}: IModalWrapper) => {
  const style = {
    '--modal-width': width ? `${width}px` : undefined,
    '--modal-height': height ? `${height}px` : undefined,
  } as React.CSSProperties;

  return (
    <Modal
      title={title}
      visible={visible}
      centered={centered}
      onCancel={onCancel}
      footer={footer}
      className={`modal-wrapper ${className}`}
      closeIcon={closeIcon}
      closable={closable}
      style={style}
      destroyOnClose={destroyOnClose}
      forceRender={forceRender}
    >
      {children}
    </Modal>
  );
};

ModalWrapper.defaultProps = {
  title: null,
  visible: false,
  centered: true,
  onCancel: () => null,
  footer: null,
  className: '',
  closeIcon: (
    <img src={CloseActionBtn} alt="Close action" height={20} width={20} />
  ),
  width: 310,
  closable: true,
  destroyOnClose: false,
  forceRender: false,
};

export default ModalWrapper;
