import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ModalWrapper from './ModalWrapper';
import SmallProfile from './SmallProfile';
import { UserAccount } from '../../lib/models/UserAccount';
import { REFERRAL_EARN_RULES } from '../../lib/constants';

const ReferralModal = ({
  visible,
  onCancel,
  user,
}: {
  visible: boolean;
  onCancel: () => void;
  user: UserAccount;
}) => {
  const { t } = useTranslation();
  const { push } = useRouter();

  const handleClick = () => {
    if (user?.walletAddress) {
      push(`/creators/${user.walletAddress}`);
    }
    onCancel();
  };

  return (
    <ModalWrapper visible={visible} onCancel={onCancel} width={331}>
      <div className="account-modal-div">
        <div className="mint-popup-title">
          {t('🎁 Friend sent you {{amount}}', {
            amount: `${REFERRAL_EARN_RULES.currency}${REFERRAL_EARN_RULES.earn}`,
          })}
        </div>
        <div className="referral-txt-description">
          {t(
            'Trade more than {{tradingVolume}} in NFT volume, you will get {{amount}} back.',
            {
              tradingVolume: `${REFERRAL_EARN_RULES.currency}${REFERRAL_EARN_RULES.tradingVolume}`,
              amount: `${REFERRAL_EARN_RULES.currency}${REFERRAL_EARN_RULES.earn}`,
            },
          )}
        </div>
        <div
          role="presentation"
          className="w-full flex justify-start align-center pointer"
          onClick={handleClick}
        >
          <SmallProfile className="referral-user-profile" user={user} />
          <div className="w-full md:w-auto ml-3">
            <div className="friend-username text-left">{user?.username}</div>
            <div className="friend-walletAddress text-gray">
              {t('INVITED YOU')}
            </div>
          </div>
        </div>
        <Link href="/referral">
          <Button
            type="primary"
            block
            onClick={onCancel}
            className="nft-card-edit-button"
          >
            <span className="nft-create-drop-button-text">Learn More</span>
          </Button>
        </Link>
      </div>
    </ModalWrapper>
  );
};

export default ReferralModal;
