/* eslint-disable import/no-cycle */
import BigNumber from 'bignumber.js';
import { AssetMarketPrice } from '../models/dtos/ApiModels';

export enum SupportedPair {
  AIRTUSD = 'AIRTUSD',
  BNBUSD = 'BNBUSD',
  MATICUSD = 'MATICUSD',
  FTMUSD = 'FTMUSD',
  ETHUSD = 'ETHUSD',
}

export class MarketPricePickerService {
  constructor(assetPrices: AssetMarketPrice[]) {
    this.assetPrices = assetPrices;
  }

  public readonly assetPrices: AssetMarketPrice[];

  public getAssetPrice(pair: SupportedPair) {
    return this.assetPrices.find(
      (asset) => asset.tradingPair === pair.toString(),
    );
  }

  public convertAssets(
    value: string,
    fromAssetPair: SupportedPair,
    toAssetPair: SupportedPair,
    decimalPlaces?: number,
  ) {
    const fromAsset = this.getAssetPrice(fromAssetPair);
    const toAsset = this.getAssetPrice(toAssetPair);

    if (!fromAsset || !toAsset) {
      return '';
    }

    return new BigNumber(value)
      .multipliedBy(fromAsset.price)
      .div(toAsset.price)
      .toFixed(decimalPlaces || 0);
  }
}
