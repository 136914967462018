import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectImage } from '../../lib/Resources';
import { truncate } from '../../lib/Utils';

export interface PageMetadataProps {
  MetaTitle?: string;
  MetDescription?: string;
  MetaImageUrl?: string;
  MetaDefaultImageUrl?: string;
}

export const PageMetadata = ({
  MetaTitle,
  MetDescription,
  MetaImageUrl,
  MetaDefaultImageUrl,
}: PageMetadataProps) => {
  const { locale } = useRouter();
  const { t, i18n } = useTranslation();
  const favIconUrl = process.env.NEXT_PUBLIC_APP_URL_FAV_ICON;

  // const shortProjectName = t('NFT Collection | Airnfts');

  // const ProjectName = t(
  //   'Best NFT Market | NFT Marketplace on BSC | Binance, FTM, Polygon NFTs | Airnfts',
  // );

  const shortProjectName =
    i18n.getDataByLanguage(locale)?.translation?.['NFT Collection | Airnfts'] ||
    t('NFT Collection | Airnfts');

  const ProjectName =
    i18n.getDataByLanguage(locale)?.translation?.SiteTitle || t('SiteTitle');

  const title = MetaTitle
    ? `${truncate(MetaTitle, 40)} | ${shortProjectName}`
    : ProjectName;

  const description =
    MetDescription ||
    i18n.getDataByLanguage(locale)?.translation?.SiteDescription ||
    t('SiteDescription');

  const keyWords =
    i18n.getDataByLanguage(locale)?.translation?.SiteKeywords ||
    t('SiteKeywords');

  const imageSource = MetaImageUrl || MetaDefaultImageUrl || ProjectImage;

  const isVideo = () =>
    ['mp4', 'mov', 'qt'].some((fileType) =>
      imageSource?.toLowerCase().includes(fileType),
    );

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
          key="viewport"
        />
        <link rel="icon" href={favIconUrl} />
        <meta name="keywords" content={keyWords} key="keywords" />
        <meta name="description" content={description} key="description" />
        <meta
          property="twitter:card"
          content={isVideo() ? 'player' : 'summary_large_image'}
          key="twitter:card"
        />
        <meta property="twitter:title" content={title} key="twitter:title" />
        <meta
          property="twitter:description"
          content={description}
          key="twitter:description"
        />
        {isVideo() ? (
          <>
            <meta
              property="twitter:image"
              content={MetaDefaultImageUrl || ProjectImage}
              key="twitter:image"
            />
            <meta
              property="twitter:player"
              content={imageSource}
              key="twitter:player"
            />
            <meta name="twitter:player:width" content="435" />
            <meta name="twitter:player:height" content="251" />
          </>
        ) : (
          <meta
            property="twitter:image"
            content={imageSource}
            key="twitter:image"
          />
        )}

        <meta property="twitter:site" content="airnfts" key="twitter:site" />

        {isVideo() ? (
          <>
            <meta property="og:video" content={imageSource} />
            <meta name="og:video:width" content="435" />
            <meta name="og:video:height" content="251" />
          </>
        ) : (
          <meta property="og:image" content={imageSource} key="og:image" />
        )}

        <meta property="og:title" content={title} key="og:title" />
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-FGKNLNY3ZN"
        />
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-FGKNLNY3ZN');`,
          }}
        />
      </Head>
    </div>
  );
};
