import React from 'react';

const TokenPocketIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0Z"
      fill="#2980FE"
    />
    <path
      opacity="0.6"
      // eslint-disable-next-line max-len
      d="M14.6903 12.6558H14.1681V8.92615H7.27744C7.14619 8.92615 7.03955 9.03279 7.03955 9.16404V14.4222C7.03955 14.5535 7.14619 14.6601 7.27744 14.6601H9.90791V24.2222C9.90791 24.3535 10.0146 24.4601 10.1458 24.4601H14.4497C14.581 24.4601 14.6876 24.3535 14.6876 24.2222V12.6558H14.6903Z"
      fill="white"
    />
    <path
      // eslint-disable-next-line max-len
      d="M19.2484 7.50977H17.5941H13.0523C12.9211 7.50977 12.8145 7.61641 12.8145 7.74766V22.8086C12.8145 22.9398 12.9211 23.0465 13.0523 23.0465H17.3562C17.4875 23.0465 17.5941 22.9398 17.5941 22.8086V18.9832H19.2484C22.4176 18.9832 24.9852 16.4156 24.9852 13.2465C24.9852 10.0773 22.4176 7.50977 19.2484 7.50977Z"
      fill="white"
    />
  </svg>
);

export default TokenPocketIcon;
