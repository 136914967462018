import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { SupportedChains } from '../models/NftModel';
import {
  chainEnvConfig,
  SupportedChainConfigInterface,
  ChainConfigInterface,
  chainsConfig,
} from '../../config/chainsConfig';

class ConnectionInteract {
  public async attemptWeb3Connection(): Promise<Web3 | null> {
    if (
      typeof window.ethereum === 'undefined' &&
      typeof window.web3 === 'undefined'
    ) {
      return null;
    }
    const web3Modal = new Web3Modal({
      providerOptions: {}, // required
    });

    window.web3Modal = web3Modal;

    web3Modal.clearCachedProvider();
    await web3Modal.toggleModal();

    const provider = await web3Modal.connect();
    return new Web3(provider);
  }

  // Wallet Connection type of connection
  public async attemptWCConnection(
    subscribeProvider: (prov: any) => void,
  ): Promise<Web3> {
    const providerOptions = {
      walletconnect: {
        type: 'qrcode',
        display: {},
        package: WalletConnectProvider,
        options: {
          rpc: {
            // bsc
            56: chainsConfig.mainnet.bsc.nodeRPC,
            97: chainsConfig.testnet.bsc.nodeRPC,

            // polygon
            137: chainsConfig.mainnet.polygon.nodeRPC,
            80001: chainsConfig.testnet.polygon.nodeRPC,

            // fantom
            250: chainsConfig.mainnet.fantom.nodeRPC,
            4002: chainsConfig.testnet.fantom.nodeRPC,
          },
          network: 'binance',
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
      cacheProvider: false,
    });

    window.web3Modal = web3Modal;

    web3Modal.clearCachedProvider();
    // await web3Modal.toggleModal();

    const provider = await web3Modal.connectTo('walletconnect');

    // This is the only workaround that works with WalletConnect - Damnnn !
    // https://github.com/ChainSafe/web3.js/issues/3891

    // eslint-disable-next-line no-proto
    delete provider.__proto__.request;
    // eslint-disable-next-line no-prototype-builtins
    if (provider.hasOwnProperty('request')) delete provider.request;

    subscribeProvider(provider);

    return new Web3(provider);
  }

  public async getConnectedAccountBalanceWithWeb3(web3: Web3): Promise<string> {
    const defaultAccount = await this.getDefaultAccount(web3);
    const balance = await web3.eth.getBalance(defaultAccount);
    return web3.utils.fromWei(balance, 'ether');
  }

  public async getDefaultAccount(web3: Web3) {
    const accounts = await web3.eth.getAccounts();
    return accounts[0];
  }

  public supportedChains(): SupportedChainConfigInterface {
    return chainEnvConfig;
  }

  public supportedChainIDs(): Array<number> {
    return Object.values(this.supportedChains()).map((chain) => chain.chainID);
  }

  public getChainById(chainID: number): ChainConfigInterface | null {
    return Object.values(this.supportedChains()).find(
      (chain) => chain.chainID === chainID,
    );
  }

  public getChainID(): number {
    return Number(process.env.NEXT_PUBLIC_WEB3_CHAIN_ID);
  }

  public getChainIDHex(chainId: number): string {
    if (!chainId) return null;
    return Web3.utils.numberToHex(chainId);
  }

  public getRpcNodeUrl(): string {
    return process.env.NEXT_PUBLIC_WEB3_NODE_RPC;
  }

  public getNetworkName(): string {
    return process.env.NEXT_PUBLIC_WEB3_NETWORK;
  }

  public getNetworkExplorer(): string {
    return process.env.NEXT_PUBLIC_NETWORK_EXPLORER;
  }

  public async switchChain(chain: SupportedChains) {
    try {
      const { ethereum } = window;
      const newChain = this.supportedChains()[chain];
      const jsonRPCs = !window?.ethereum?.isTrust
        ? {
            nativeCurrency: {
              name: newChain.currency,
              symbol: newChain.currency,
              decimals: newChain.decimal,
            },
            rpcUrls: [newChain.nodeRPC],
            blockExplorerUrls: [newChain.networkExplorer],
          }
        : {};

      const data = [
        {
          chainId: this.getChainIDHex(newChain.chainID),
          chainName: `${newChain.name.toUpperCase()} ${this.getNetworkName()}`,
          ...jsonRPCs,
        },
      ];

      // eslint-disable-next-line no-console
      console.log('Network switch', data);

      /* eslint-disable */
      const tx = await ethereum
        .request({ method: 'wallet_addEthereumChain', params: data })
        .catch();
      if (tx) {
        console.log('failed to switch', tx);
        alert(tx);
        return;
      }
      return newChain;
    } catch (e) {
      console.log('failed to switch network', e);
      alert(e.message);
    }
  }
}

export const connectionInteract = new ConnectionInteract();
