import copy from 'copy-to-clipboard';
import { Col, Divider, message, Row, Badge } from 'antd';
import BigNumber from 'bignumber.js';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { AIRTIcon, BNBIcon, CopyActionBtn } from '../../lib/Resources';
import { middleEllipsis, truncate } from '../../lib/Utils';
import {
  connectionAccountState,
  currentlySelectedCreatorTabState,
} from '../../recoil/atoms';
import UserAvatar from './UserAvatar';
import { CHAINS } from '../../lib/constants';
import { SupportedCurrencies } from '../../lib/models/NftModel';
import ModalWrapper from './ModalWrapper';

export const AccountModal = ({
  visible,
  onCancel,
  onEditAccountClicked,
  onMyProfileClicked,
  onMyDropsClicked,
  onAllNotificationsClicked,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onLogOutClicked,
}: {
  visible: boolean;
  onCancel: () => void;
  onEditAccountClicked: () => void;
  onMyProfileClicked: () => void;
  onMyDropsClicked: () => void;
  onAllNotificationsClicked: () => void;
  onLogOutClicked: () => void;
}) => {
  const { t } = useTranslation();
  const connectionAccount = useRecoilValue(connectionAccountState);
  const setCurrentlySelectedCreatorTab = useSetRecoilState(
    currentlySelectedCreatorTabState,
  );

  async function onAddressCopyClicked() {
    copy(connectionAccount.address);
    await message.info(t('Address copied to clipboard'), 3);
  }

  const chain = CHAINS[connectionAccount?.chain] || CHAINS.bsc;

  const onMyBidsClicked = async () => {
    setCurrentlySelectedCreatorTab('bids');

    await onMyProfileClicked();
  };

  return (
    <ModalWrapper visible={visible} onCancel={onCancel} width={310}>
      <div className="account-modal-div">
        <div>
          <div className="account-block-content">
            <Row>
              <div
                className="user-account-avatar"
                onClick={onMyProfileClicked}
                onKeyDown={onMyProfileClicked}
                role="button"
                tabIndex={-2}
              >
                <UserAvatar
                  user={connectionAccount}
                  className="user-avatar-container relative"
                  IdenticonSize={40}
                  isClickable={false}
                />
              </div>

              <div className="account-block-column">
                <Col>
                  <div className="account-username-section">
                    {connectionAccount.username
                      ? truncate(connectionAccount.username, 20)
                      : 'CREATOR'}
                  </div>
                  <div
                    className="account-address-section"
                    onClick={onAddressCopyClicked}
                    onKeyDown={onAddressCopyClicked}
                    tabIndex={-18}
                    role="button"
                  >
                    {middleEllipsis(connectionAccount.address, 7)}{' '}
                    <span>
                      <img
                        src={CopyActionBtn}
                        alt="Copy Icon"
                        height={16}
                        width={16}
                        className="account-copy-address-icon"
                      />
                    </span>
                  </div>
                </Col>
              </div>
            </Row>
          </div>

          <div style={{ padding: '0', margin: '8px' }} />

          <div className="account-block-content">
            <Row>
              <div style={{ height: '40px' }}>
                <div className="account-img-avatar">
                  <img
                    src={chain.icon || BNBIcon}
                    width={40}
                    height={40}
                    alt="Asset logo"
                  />
                </div>
              </div>

              <div className="account-block-column">
                <Col style={{}}>
                  <div className="account-balance-crypto-balance">
                    {connectionAccount.balance
                      ? `${new BigNumber(connectionAccount.balance).toFixed(
                          4,
                        )} ${
                          connectionAccount.chainDefaultAsset ||
                          SupportedCurrencies.BNB
                        }`
                      : ''}
                  </div>
                  <div className="account-balance-fiat-balance">
                    {connectionAccount.balance &&
                    connectionAccount.defaultAssetPrice
                      ? `$${new BigNumber(connectionAccount.balance)
                          .multipliedBy(connectionAccount.defaultAssetPrice)
                          .toFixed(2)}`
                      : ''}
                  </div>
                </Col>
              </div>
            </Row>
          </div>

          <div style={{ padding: '0', margin: '4px' }} />

          <div className="account-block-content">
            <Row>
              <div style={{ height: '40px' }}>
                <div className="account-img-avatar">
                  <img src={AIRTIcon} width={40} height={40} alt="Asset logo" />
                </div>
              </div>

              <div className="account-block-column">
                <Col>
                  <div className="account-balance-crypto-balance">
                    {`${
                      connectionAccount.airtTokenBalance
                        ? Number(
                            connectionAccount.airtTokenBalance,
                          ).toLocaleString()
                        : 0
                    } AIRT`}
                  </div>
                  <div className="account-balance-fiat-balance">
                    <a
                      target="_blank"
                      href="https://www.airnfts.com/post/how-to-buy-airt-and-earn"
                      rel="noopener noreferrer"
                    >
                      {t('Buy & Earn')}
                    </a>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
        <Divider style={{ marginTop: '12px', marginBottom: '8px' }} />
        <div>
          <div
            className="account-lower-part-text"
            onKeyDown={onMyProfileClicked}
            onClick={onMyProfileClicked}
            role="button"
            tabIndex={0}
          >
            {t('My Profile')}
          </div>
          <div
            className="account-lower-part-text"
            onKeyDown={onEditAccountClicked}
            onClick={onEditAccountClicked}
            role="button"
            tabIndex={-1}
          >
            {t('Edit Profile')}
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            onKeyDown={onMyBidsClicked}
            onClick={onMyBidsClicked}
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <div
              className="account-notification-text"
              role="button"
              tabIndex={-1}
            >
              {t('Bids')}
            </div>
            <div
              className="account-notification-text"
              role="button"
              tabIndex={-1}
            >
              {/* <Badge count={2} /> */}
            </div>
          </div>
          {connectionAccount.canCreateDrop &&
            !connectionAccount.notAllowedToMint && (
              <div
                className="account-lower-part-text"
                onKeyDown={onMyDropsClicked}
                onClick={onMyDropsClicked}
                role="button"
                tabIndex={-1}
              >
                {t('Drops')}
              </div>
            )}
          <div
            className="account-lower-part-text"
            onKeyDown={onLogOutClicked}
            onClick={onLogOutClicked}
            role="button"
            tabIndex={-1}
          >
            Logout
          </div>

          <Divider style={{ marginTop: '6px', marginBottom: '4px' }} />
          <div className="account-notifications-text-wrapper">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              onKeyDown={onAllNotificationsClicked}
              onClick={onAllNotificationsClicked}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="account-notification-text"
                role="button"
                tabIndex={-1}
              >
                Notifications
              </div>
              <div
                className="account-notification-text"
                role="button"
                tabIndex={-1}
              >
                <Badge count={connectionAccount.notificationsCount} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
