import 'firebase/messaging';
import { Button, Checkbox, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { requestFirebaseNotificationPermission } from '../../lib/db/FirebaseConfig';

export const SubscribeModal = ({
  visible,
  onCancel,
  onFinishSubscribe,
}: {
  visible: boolean;
  onCancel: () => void;
  onFinishSubscribe: () => void;
}) => {
  const { t } = useTranslation();
  const [subscribed, setSubscribed] = useState(false);
  const [subscribing, setSubscribing] = useState(false);

  function onCheckSubscribe() {
    setSubscribed(!subscribed);
  }

  async function onSubscribe() {
    if (!subscribed) {
      await message.warn(t('Turn on before proceeding'));
      return;
    }
    setSubscribing(true);

    requestFirebaseNotificationPermission()
      .catch((error) => {
        message.error(error.message);
      })
      .then(() => {
        message.success(t('Subscription sent successfully'));
        onFinishSubscribe();
      })
      .finally(() => {
        setSubscribing(false);
      });
  }

  return (
    <Modal
      title=""
      visible={visible}
      centered
      onCancel={onCancel}
      footer={null}
      className="subscribe-modal"
      closeIcon={<div />}
      closable={false}
    >
      <div className="account-modal-div">
        <div className="mint-popup-title">{t('Turn on notifications')}</div>
        <div className="delete-txt-description">
          {t('Please turn on notifications')}.{' '}
          {t('So you don’t miss important events happening to your nfts')}.
        </div>
        <div className="accept-term-checkbox">
          <Checkbox checked={subscribed} onChange={onCheckSubscribe}>
            <span className="accept-terms-checkbox-text">
              {t('I turn on AirNFTs notifications')}
            </span>
          </Checkbox>
        </div>
        <Button
          type="primary"
          block
          onClick={onSubscribe}
          className="nft-card-edit-button"
          loading={subscribing}
        >
          <span className="nft-create-drop-button-text">
            {subscribing ? t('Subscribing ...') : t('Subscribe')}
          </span>
        </Button>
      </div>
    </Modal>
  );
};
