import { initializeApp, getApp, FirebaseOptions } from 'firebase/app';
import {
  getToken,
  getMessaging,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import { getStorage, getDownloadURL, uploadBytes, ref } from 'firebase/storage';
import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import http from '../http';
import { Web3Service } from '../web3/Web3Service';
import { orderObject } from '../Utils';
import { localCache } from './LocalCache';

let storage;

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID || undefined,
};

const createFirebaseApp = (config: FirebaseOptions = firebaseConfig) => {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
};

try {
  const app = createFirebaseApp();
  storage = getStorage(app);
} catch (err) {
  Sentry.captureException(err);
}

export const requestFirebaseNotificationPermission = async () => {
  if (window === undefined || (await !isSupported())) Promise.resolve();
  const walletAddress = localStorage.getItem('_anft_user_address');
  const tokenStorageKey = `_an_fcm_token_${walletAddress}`.toLowerCase();

  const fcmToken = localStorage.getItem(tokenStorageKey);
  if (fcmToken && walletAddress) return Promise.resolve(fcmToken);

  return new Promise((resolve, reject) => {
    Notification.requestPermission()
      .then(() => getToken(getMessaging(createFirebaseApp())))
      .then(async (token) => {
        // setting FCM token in indexed db using localforage
        const { connection } = window;
        console.log(token, 'token', connection);
        const web3Service = new Web3Service(connection);
        const timestamp = dayjs().format();
        const signData = JSON.stringify(
          orderObject({
            walletAddress,
            token,
            timestamp,
          }),
        );

        const { signature } = await web3Service.signPersonalMessage(
          signData,
          walletAddress,
        );

        if (signature) {
          await http.post('/users/push_notifications', {
            walletAddress,
            token,
            timestamp,
            signature,
          });
          localStorage.setItem(tokenStorageKey, token);
        }

        localCache.setSubscriptionToken({ tokenStorageKey, token });
        resolve(token);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const onMessageListener = async () => {
  if (window !== undefined || (await isSupported())) {
    return new Promise((resolve) => {
      const app = createFirebaseApp();
      const messaging = getMessaging(app);

      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  }
  return Promise.resolve();
};

const firebaseInstance = {
  storage,
  getToken,
  ref,
  getDownloadURL,
  uploadBytes,
};

export default firebaseInstance;
