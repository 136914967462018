import { Button, Checkbox, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TermsLink } from '../../lib/Resources';
import { orderObject, sleep } from '../../lib/Utils';
import { Web3Service } from '../../lib/web3/Web3Service';
import { connectionAccountState } from '../../recoil/atoms';
import { AcceptTermData } from '../../lib/models/UserAccount';
import { backendApiService } from '../../lib/services/BackendApiService';
import { localCache } from '../../lib/db/LocalCache';

export const AcceptTermsModal = ({
  visible,
  onCancel,
  onFinishAccept,
}: {
  visible: boolean;
  onCancel: () => void;
  onFinishAccept: () => void;
}) => {
  const { t } = useTranslation();
  const [accepted, setAccepted] = useState(false);
  const [acceptingTerms, setAcceptingTerms] = useState(false);
  const currentAccount = useRecoilValue(connectionAccountState);

  function onCheckAcceptChange() {
    const newValue = !accepted;
    setAccepted(newValue);
  }

  async function onAcceptTerms() {
    if (!accepted) {
      await message.warn(t('Accept terms before proceeding'));
      return;
    }

    setAcceptingTerms(true);

    const { connection } = window;
    const web3Service = new Web3Service(connection);

    const termsAcceptance: AcceptTermData = {
      message: t('I accept AirNFTs terms'),
      walletAddress: currentAccount.address,
    };

    const signData = JSON.stringify(orderObject(termsAcceptance));
    const signResult = await web3Service.signPersonalMessage(
      signData,
      currentAccount.address,
    );

    if (!signResult || !signResult.signature) {
      setAcceptingTerms(false);
      message.warn(t('Could not get acceptance signature'));
      return;
    }
    termsAcceptance.signature = signResult.signature;

    const sendAcceptanceResult = await backendApiService.acceptTermsAndService(
      termsAcceptance,
    );

    if (sendAcceptanceResult?.error) {
      setAcceptingTerms(false);
      message.warn(
        sendAcceptanceResult.message || t('Failed to submit acceptance'),
      );
      return;
    }

    localCache.setHasAcceptedTerms(currentAccount.address);
    setAcceptingTerms(false);

    message.success(t('Terms acceptance sent successfully'));

    await sleep(2_000);
    onFinishAccept();
  }

  return (
    <Modal
      title=""
      visible={visible}
      centered
      onCancel={onCancel}
      footer={null}
      className="accept-terms-modal"
      closeIcon={<div />}
      closable={false}
    >
      <div className="account-modal-div">
        <div className="mint-popup-title">{t('Terms Of Service')}</div>
        <div className="delete-txt-description">
          {t('Please take a few minutes to read and understand AirNFTs')}{' '}
          <a href={TermsLink} target="_blank" rel="noopener noreferrer">
            {t('Terms of Service')}
          </a>
          .{' '}
          {t(
            'To continue, you’ll need to accept the Terms of Service by checking the box.',
          )}
        </div>
        <div className="accept-term-checkbox">
          <Checkbox checked={accepted} onChange={onCheckAcceptChange}>
            <span className="accept-terms-checkbox-text">
              {t('I accept the AirNFTs Terms of Service')}
            </span>
          </Checkbox>
        </div>
        <Button
          type="primary"
          block
          onClick={onAcceptTerms}
          className="nft-card-edit-button"
          loading={acceptingTerms}
        >
          <span className="nft-create-drop-button-text">
            {acceptingTerms ? t('Accepting ...') : t('Accept')}
          </span>
        </Button>
      </div>
    </Modal>
  );
};
