import React, { ReactNode } from 'react';
import { Col } from 'antd';

const WalletConnection = ({
  title,
  onConnect,
  icon,
}: {
  title: string;
  onConnect: () => void;
  icon: ReactNode;
}) => (
  <>
    <Col onClick={onConnect}>
      <div className="connect-option-div">
        <div className="connect-option-name">{title}</div>
        <div className="connect-option-icon">{icon}</div>
      </div>
    </Col>
  </>
);

export default WalletConnection;
