import { atom } from 'recoil';
import BigNumber from 'bignumber.js';
import {
  ConnectionAccount,
  FixedDefaultQueryOrder,
  InitialNftLoad,
  InitialTopTradersLoad,
  LeaderboardListState,
  QueryLoaderStateModel,
  StorageFeeData,
  TraderTypeSelected,
  InitialAdminLoad,
  InitialCollectionLoad,
  SearchStore,
  InitialStakingPoolLoad,
  InitialAuctionLoad,
} from './models';
import {
  ClaimableNFT,
  LaunchpadBalanceResponse,
  LaunchpadPackage,
  NftModel,
  UserGroupedNfts,
} from '../lib/models/NftModel';
import { AssetMarketPrice } from '../lib/models/dtos/ApiModels';
import {
  NotificationItem,
  DropDetailResponse,
  AdminStatuses,
  UserFollowersResponse,
} from '../lib/models/GeneralModels';
import {
  IAuctionStep,
  IOfferStep,
  IStakingAmountValue,
  IStakingModals,
  IStakingProcess,
  IStakingState,
} from '../lib/@Types';
import { STAKING_DEFAULT } from './defaults';

export const connectionAccountState = atom<ConnectionAccount>({
  key: 'connectionAccountState',
  default: {
    defaultAsset: 'BNB',
  },
});

export const connectedAccountGroupedNFTsState = atom<UserGroupedNfts>({
  key: 'connectedAccountGroupedNFTsState',
  default: {
    currentNfts: [],
    soldNfts: [],
    boughtNfts: [],
    createdNfts: [],
    specialDrops: [],
    hiddenNfts: [],
  },
});

export const showConnectOptionsModalState = atom<boolean>({
  key: 'showConnectOptionsModalState',
  default: false,
});

export const allNftDataState = atom<InitialNftLoad>({
  key: 'allNftDataState',
  default: {
    nfts: [],
    categories: [],
    hasLoaded: false,
    enableInfiniteScroll: false,
  },
});

export const allCollectionDataState = atom<InitialCollectionLoad>({
  key: 'allCollectionDataState',
  default: {
    collections: [],
    hasLoaded: false,
  },
});

export const allAuctionsDataState = atom<InitialAuctionLoad>({
  key: 'allAuctionsDataState',
  default: {
    auctions: [],
    hasLoaded: false,
  },
});

export const allTopTradersState = atom<InitialTopTradersLoad>({
  key: 'AllTopTraderState',
  default: {
    topTraders: null,
    traderTypeSelected: TraderTypeSelected.SELLER,
    hasLoaded: false,
  },
});

export const allStakingPoolDataState = atom<InitialStakingPoolLoad>({
  key: 'allStakingPoolDataState',
  default: {
    pools: [],
    hasLoaded: false,
  },
});

export const listLoadingState = atom<boolean>({
  key: 'listLoadingState',
  default: true,
});

export const showStorageFeeModalState = atom<boolean>({
  key: 'showStorageFeeModalState',
  default: false,
});

export const showWaitConfirmationModalState = atom<boolean>({
  key: 'showWaitConfirmationModalState',
  default: false,
});

export const launchpadBalanceState = atom<LaunchpadBalanceResponse>({
  key: 'launchpadBalanceState',
  default: {
    userAddress: '',
    tokensBalance: -1,
    tokensTotal: -1,
    distributions: [],
  },
});

export const launchpadPackagesState = atom<LaunchpadPackage[]>({
  key: 'launchpadPackagesState',
  default: [],
});

export const homeAuctionsListState = atom<NftModel[]>({
  key: 'homeAuctionsListState',
  default: [],
});

export const showDropProlongationModalState = atom<boolean>({
  key: 'showDropProlongationModalState',
  default: false,
});

export const showAuctionSetupModalState = atom<boolean>({
  key: 'showAuctionSetupModalState',
  default: false,
});

export const showMenuState = atom<boolean>({
  key: 'showMenuState',
  default: false,
});

export const showSubscriptionModalState = atom<boolean>({
  key: 'showSubscriptionModalState',
  default: false,
});

export const smallDeviceSearchTriggerState = atom<boolean>({
  key: 'smallDeviceSearchTriggerState',
  default: false,
});

export const searchModeState = atom<boolean>({
  key: 'searchModeState',
  default: false,
});

export const searchResultState = atom<boolean>({
  key: 'searchResultState',
  default: false,
});

export const searchStoreState = atom<SearchStore>({
  key: 'searchStoreState',
  default: {
    results: {
      collections: [],
      items: [],
      users: [],
    },
    searching: false,
    hasRunSearch: false,
    lastQuery: '',
  },
});

export const currentlySelectedCreatorTabState = atom<string>({
  key: 'currentlySelectedCreatorBottomTabState',
  default: 'current',
});

export const currentSelectedCategoryState = atom<string>({
  key: 'currentSelectedCategoryState',
  default: '',
});

export const canLoadMoreSearchResultState = atom<boolean>({
  key: 'canLoadMoreSearchResults',
  default: true,
});

export const storageFeeState = atom<StorageFeeData>({
  key: 'storageFeeState',
  default: {
    hasPaidStorageFee: false,
  },
});

export const sorterQueryRecoilState = atom<QueryLoaderStateModel>({
  key: 'sorterQueryRecoilState',
  default: {
    inLoadingState: false,
    queryOrder: FixedDefaultQueryOrder,
  },
});

export const sorterQueryDropRecoilState = atom<QueryLoaderStateModel>({
  key: 'sorterQueryDropRecoilState',
  default: {
    inLoadingState: false,
    queryOrder: FixedDefaultQueryOrder,
  },
});

export const leaderboardState = atom<LeaderboardListState>({
  key: 'LeaderboardListState',
  default: {
    hasLoaded: false,
    leaderboard: null,
  },
});

export const assetMarketPricesState = atom<AssetMarketPrice[]>({
  key: 'assetMarketPricesState',
  default: [],
});

export const currentModalRequireAmountState = atom<BigNumber>({
  key: 'currentModalRequireAmountState',
  default: new BigNumber(0),
});

export const showInsufficientBalanceModalState = atom<boolean>({
  key: 'showInsufficientBalanceModalState',
  default: false,
});

export const followStepsModalState = atom<IAuctionStep>({
  key: 'followStepsModalState',
  default: { visible: false, isAuctionPaid: false, isEscrowSent: false },
});

export const claimableLaunchpadNFTsState = atom<ClaimableNFT[]>({
  key: 'claimableLaunchpadNFTsState',
  default: [],
});

export const allAdminDataState = atom<InitialAdminLoad>({
  key: 'allAdminDataState',
  default: {
    usersMeta: {
      pageSize: 50,
      currentPage: 1,
      totalPages: 1,
      totalRecords: 1,
    },
    users: [],
    dropsMeta: {
      pageSize: 50,
      currentPage: 1,
      totalPages: 1,
      totalRecords: 1,
    },
    drops: [],
    hasLoaded: false,
  },
});

export const notificationsListState = atom<NotificationItem[]>({
  key: 'notificationsListState',
  default: [],
});

export const notificationsState = atom<boolean>({
  key: 'notificationsState',
  default: false,
});

export const dropDetailDataState = atom<DropDetailResponse>({
  key: 'dropDetailDataState',
  default: null,
});

export const statusesState = atom<AdminStatuses[]>({
  key: 'statusesState',
  default: [],
});

export const accountUsersState = atom<
  Required<UserFollowersResponse> & { loading?: boolean }
>({
  key: 'accountUsers',
  default: {
    followings: [],
    followers: [],
    loading: false,
  },
});

export const currentUserExternalNftState = atom<{
  externalNfts: Partial<NftModel>[];
  hasLoaded?: boolean;
}>({
  key: 'externalNFTs',
  default: {
    externalNfts: [],
    hasLoaded: false,
  },
});

export const StakingState = atom<IStakingState>({
  key: 'stakingState',
  default: {
    AIRT: STAKING_DEFAULT,
  },
});

export const stakingModalsState = atom<IStakingModals>({
  key: 'stakingModalsState',
  default: {
    approve: false,
    stake: false,
    unstake: false,
    unstakeWarning: false,
    showRewardTokenInfo: false,
    claimWarning: false,
    hasClaimed: false,
    currentToken: 'AIRT',
  },
});

export const stakingProcessState = atom<IStakingProcess>({
  key: 'stakingProcessState',
  default: {
    approve: false,
    stake: false,
    unstake: false,
  },
});

export const stakeAmountValueState = atom<IStakingAmountValue>({
  key: 'stakeAmountValueState',
  default: {
    toApprove: null,
    toStake: null,
  },
});

export const ReferralState = atom<{
  loading: boolean;
  referredUsers: ConnectionAccount[];
  user: ConnectionAccount & { walletAddress?: string };
}>({
  key: 'referralState',
  default: {
    loading: false,
    referredUsers: [],
    user: {},
  },
});

export const currentlySelectedRankingTabState = atom<string>({
  key: 'currentlySelectedRankingTabState',
  default: 'current',
});

export const adminCategoryTab = atom<string>({
  key: 'adminCategoryTab',
  default: 'users',
});

export const showPutOnSaleModalState = atom<{
  externalNFT: boolean;
  offer: boolean;
}>({
  key: 'showPutOnSaleModalState',
  default: {
    externalNFT: false,
    offer: false,
  },
});

export const offerCreationStepsModalState = atom<IOfferStep>({
  key: 'offerCreationStepsModalState',
  default: {
    visible: false,
    isNftApproved: false,
    offerCreation: false,
  },
});

export const displayBuyButtonState = atom<boolean>({
  key: 'displayBuyButtonState',
  default: false,
});
