import log from 'loglevel';
import * as Sentry from '@sentry/nextjs';
import { AcceptTermData } from '../models/UserAccount';
import {
  LaunchpadBalanceResponse,
  LaunchpadClaimableNFTsResponse,
  LaunchPadResponse,
  MultiNFTDrop,
  NftToDrop,
  PurchaseDto,
  SearchResultData,
  SpecialDropModel,
} from '../models/NftModel';
import {
  BidNftAuctionDto,
  DropDetailResponse,
  DropDurationExtension,
  DropUpdateData,
  LeaderboardDataResponse,
  MakeNftAuctionDto,
  Notifications,
  UserFollowersResponse,
} from '../models/GeneralModels';
import http from '../http';
import { Sorter } from '../../recoil/models';
import { TransferNftDto } from '../models/dtos/ApiModels';

class BackendApiService {
  public async acceptTermsAndService(acceptTermData: AcceptTermData) {
    try {
      const response = await http.post('/users/terms/accept', acceptTermData);
      return response.status <= 201 && response.data;
    } catch (e) {
      log.warn('acceptTermsAndService error', e);
      Sentry.captureException(e);
      return {
        message: e.message,
        ...(e?.response?.data || {}),
        error: true,
      };
    }
  }

  public async getSearchResults(
    searchQuery: string,
    limit?: number,
  ): Promise<SearchResultData> {
    const resultLimit = limit || 12;
    try {
      const { data } = await http.get(`/nfts/search?keywords=${searchQuery}`);

      // For now fix to 12 elements, will paginate on next iteration
      const response: SearchResultData = {
        collections: data.dropSearchData.slice(0, resultLimit),
        users: data.userSearchData.slice(0, resultLimit),
        items: data.nftSearchData.slice(0, resultLimit),
      };

      return response;
    } catch (e) {
      log.warn('getSearchResults error', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async getAllDrops(): Promise<SpecialDropModel[]> {
    try {
      const response = await http.get(`/nfts-drops`);

      return response.data.docs as SpecialDropModel[];
    } catch (e) {
      log.warn('getAllDrops error', e);
      return [];
    }
  }

  public async submitDrop(drop: SpecialDropModel) {
    try {
      const { data, status } = await http.post(`/nfts-drops`, drop);
      if (!data || (status !== 200 && status !== 201)) {
        return null;
      }
      return data;
    } catch (e) {
      log.warn('submitDrop error', e);
      return null;
    }
  }

  public async updateDropData(dropUpdateData: DropUpdateData) {
    try {
      const { data } = await http.put(
        `/nfts-drops/${dropUpdateData.dropID}/publish`,
        dropUpdateData,
      );

      return data;
    } catch (e) {
      log.warn('submitDrop error', e);
      return null;
    }
  }

  public async submitBackendProlongDropDuration(
    dropDurationExtension: DropDurationExtension,
  ) {
    try {
      const { data, status, statusText } = await http.post(
        `/nfts-drops/${dropDurationExtension.dropID}/extend`,
        dropDurationExtension,
      );

      if (!data || (status !== 200 && status !== 201)) {
        Sentry.captureException(TypeError(statusText));
        return null;
      }
      return data;
    } catch (e) {
      Sentry.captureException(e);
      log.warn('submitDrop error', e);
      return null;
    }
  }

  private static getCloudFnsBackendUrl() {
    return process.env.NEXT_PUBLIC_BACKEND_SERVICE_URL;
  }

  async findDropByID(
    dropID: string,
    page?: number,
    sortOrder?: Sorter,
  ): Promise<DropDetailResponse | null> {
    try {
      const loadPage = page || 1;

      let url = `/nfts-drops/${dropID}?page=${loadPage}`;
      if (sortOrder) {
        url = `/nfts-drops/${dropID}?page=${loadPage}&sortField=${sortOrder.field}&sortOrder=${sortOrder.ordering}`;
      }
      const { data } = await http.get(url);

      const response = {
        drop: data?.drop || null,
        creator: data?.drop?.creator || null,
        nfts: data?.nfts || [],
        nftsCount: data?.meta?.totalNfts || 0,
      } as DropDetailResponse;
      return response;
    } catch (e) {
      log.warn('findDropByID error', e);
      return null;
    }
  }

  async getLeaderboardData(
    walletAddress?: string,
    filter?: string,
  ): Promise<LeaderboardDataResponse | null> {
    try {
      const response = await http.get(
        `users/leaderboard?walletAddress=${walletAddress}&filter=${filter}`,
      );
      return response.data as LeaderboardDataResponse;
    } catch (e) {
      return null;
    }
  }

  async loadLaunchpadPackages(): Promise<LaunchPadResponse | null> {
    try {
      const response = await http.get(`launchpads`);
      return response.data as LaunchPadResponse;
    } catch (e) {
      return null;
    }
  }

  async loadUserLaunchpadBalance(
    launchpadID: string,
    walletAddress: string,
  ): Promise<LaunchpadBalanceResponse | null> {
    try {
      const response = await http.get(
        `launchpads/${launchpadID}/purchase_balance?walletAddress=${walletAddress}`,
      );
      return response.data as LaunchpadBalanceResponse;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error loadUserLaunchpadBalance ', e);
      return null;
    }
  }

  async loadUserClaimableLaunchpadNFTs(
    walletAddress: string,
    page?: number,
    pageSize?: number,
  ): Promise<LaunchpadClaimableNFTsResponse | null> {
    const loadPage = page || 1;
    const loadPageSize = pageSize || 12;

    try {
      const response = await http.get(
        `users/${walletAddress}/claimnfts?page=${loadPage}&pageSize=${loadPageSize}`,
      );
      return response.data as LaunchpadClaimableNFTsResponse;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error loadUserClaimableLaunchpadNFTs ', e);
      return null;
    }
  }

  async submitLaunchpadPurchase(
    purchaseDto: PurchaseDto,
    launchpadId: number,
  ): Promise<LaunchpadBalanceResponse | null> {
    try {
      const response = await http.post(
        `launchpads/${launchpadId}/purchase`,
        purchaseDto,
      );
      return response.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error submitLaunchpadPurchase ', e);
      return null;
    }
  }

  async makeNFTAuction(nftAuctionDto: MakeNftAuctionDto, nftBaseID: string) {
    try {
      const response = await http.post(
        `nfts/${nftBaseID}/auctions`,
        nftAuctionDto,
      );
      if (response.status !== 200 && response.status !== 201) {
        return null;
      }

      return response.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error makeNFTAuction ', e);
      return null;
    }
  }

  async submitAuctionBit(nftAuctionDto: BidNftAuctionDto, nftBaseID: string) {
    try {
      const response = await http.post(
        `nfts/${nftBaseID}/auctions/bid`,
        nftAuctionDto,
      );
      if (response.status !== 200 && response.status !== 201) {
        return null;
      }

      return response.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error submitAuctionBit ', e);
      return null;
    }
  }

  async getNotifications(
    walletAddress?: string,
  ): Promise<Notifications | null> {
    try {
      const response = await http.get(`users/${walletAddress}/notifications`);
      return response.data as Notifications;
    } catch (e) {
      return null;
    }
  }

  async submitNFTTransfer(nftTransfer: TransferNftDto) {
    try {
      const response = await http.post(`/nfts/transfers`, nftTransfer);
      if (!response || (response.status !== 200 && response.status !== 201)) {
        return null;
      }
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async addNFTtoDrop(dropID: string, nftBaseID: string, nft: NftToDrop) {
    try {
      const response = await http.post(
        `nfts-drops/${dropID}/nfts/${nftBaseID}`,
        nft,
      );
      if (!response || (response.status !== 200 && response.status !== 201)) {
        return null;
      }
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async addMultipleNFTtoDrop(dropID: string, data: MultiNFTDrop) {
    try {
      const response = await http.post(`nfts-drops/${dropID}/nfts`, data);
      if (!response || (response.status !== 200 && response.status !== 201)) {
        return null;
      }
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async loadUserFollowers({
    walletAddress,
    type,
  }: {
    walletAddress: string;
    type: 'followers' | 'followings';
  }): Promise<UserFollowersResponse | null> {
    try {
      const response = await http.get(`/users/${walletAddress}/${type}`);

      return response.data as UserFollowersResponse;
    } catch (e) {
      return null;
    }
  }
}

export const backendApiService = new BackendApiService();
