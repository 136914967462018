import React, { FC } from 'react';
import Identicon from 'react-identicons';
import { VerifiedIcon } from '../../lib/Resources';
import { UserAccount } from '../../lib/models/UserAccount';
import HrefLink from './HrefLink';

interface IProps {
  user: UserAccount;
  imgSize?: number;
  iconSize?: number;
  identIconSize?: number;
  className?: string;
  BgClassName?: string;
}

const SmallProfile: FC<IProps> = ({
  user,
  iconSize,
  imgSize,
  identIconSize,
  className,
  BgClassName,
}: IProps) => (
  <HrefLink href={`/creators/${user?.walletAddress}`} className={className}>
    <div className="user-profile-badge-container">
      <div className={`${BgClassName} nft-owner-bg`}>
        {user?.userAvatarUrl ? (
          <div>
            <img
              src={user?.userAvatarUrl || user?.userAvatarUrlThumbnail}
              alt="User profile avatar"
              height={imgSize}
              width={imgSize}
            />
            {user?.verified ? (
              <span>
                <img
                  src={VerifiedIcon}
                  alt="VerifiedIcon"
                  height={iconSize}
                  width={iconSize}
                  className="verified-icon-profile-small"
                />
              </span>
            ) : (
              <div />
            )}
          </div>
        ) : (
          <div>
            <Identicon string={user?.walletAddress} size={identIconSize} />
            {user?.verified ? (
              <span>
                <img
                  src={VerifiedIcon}
                  alt="VerifiedIcon"
                  height={iconSize}
                  width={iconSize}
                  className="verified-icon-profile-small"
                />
              </span>
            ) : (
              <div />
            )}
          </div>
        )}
      </div>
    </div>
  </HrefLink>
);

SmallProfile.defaultProps = {
  imgSize: 44,
  iconSize: 16,
  identIconSize: 30,
  className: 'nft-owner-img',
  BgClassName: 'nft-owner-img-bg',
};

export default SmallProfile;
