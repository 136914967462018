import Datastore from 'nedb-promises';

export class DatabaseManager {
  public readonly launchpadPurchaseStore: Datastore;

  public readonly dropExtensionStore: Datastore;

  public readonly dropCreationStore: Datastore;

  public readonly auctionFeePaymentStore: Datastore;

  public getStore(namespace: string, store: string) {
    return Datastore.create(`./data/${namespace}.${store}.db`);
  }

  constructor(namespace: string) {
    this.launchpadPurchaseStore = this.getStore(
      namespace,
      'launchpadPurchaseStore',
    );

    this.dropExtensionStore = this.getStore(namespace, 'dropExtensionStore');
    this.dropCreationStore = this.getStore(namespace, 'dropCreationStore');
    this.auctionFeePaymentStore = this.getStore(
      namespace,
      'actionFeePaymentStore',
    );
  }
}
