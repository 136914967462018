import React, { FC } from 'react';
import { Image } from 'antd';
import { SearchNftResult } from '../../lib/models/NftModel';
import { middleEllipsis, truncate } from '../../lib/Utils';
import { ImageLoadFallBack } from '../../lib/Resources';
import SkeletonLoader from './SkeletonLoader';
import HrefLink from './HrefLink';

interface Props {
  nft: SearchNftResult;
}

const NftSmallCard: FC<Props> = ({ nft }: Props) => (
  <HrefLink
    href={`/nft/${nft.baseID}`}
    className="flex justify-start align-center my-1 pointer"
  >
    <div className="nft-small-card-img-container mr-1">
      <Image
        src={nft?.urlThumbnail || nft?.url}
        alt={`Nft ${nft.name}`}
        className="nft-small-card-img fit-cover"
        height={44}
        width={44}
        fallback={ImageLoadFallBack}
        preview={false}
        placeholder={<SkeletonLoader className="nft-small-card-img" />}
      />
    </div>
    <div style={{ marginTop: '-7px' }}>
      <div className="normal-text-13 color-black">
        {nft.name && truncate(nft.name, 23)}
      </div>
      <div className="normal-text-11" style={{ color: '#76808F' }}>
        by {nft.ownerUsername || middleEllipsis(nft.ownerAddress, 7)}
      </div>
    </div>
  </HrefLink>
);

NftSmallCard.defaultProps = {};

export default NftSmallCard;
