import React, { FC, ReactNode } from 'react';
import {
  CollectionModel,
  NftModel,
  SearchNftResult,
} from '../../lib/models/NftModel';
import { UserAccount } from '../../lib/models/UserAccount';

interface IProps {
  results: (SearchNftResult | UserAccount | CollectionModel | NftModel)[];
  categoryTitle: string;
  loading: boolean;
  onClose: () => void;
  renderItem: (result) => ReactNode;
}

const SearchResultData: FC<IProps> = ({
  results,
  categoryTitle,
  loading,
  onClose,
  renderItem,
}: IProps) => (
  <div>
    <div>
      {loading || results.length > 0 ? (
        <div className="result-category">{categoryTitle}</div>
      ) : (
        <div />
      )}
      <div className="result-content">
        {loading && (
          <div className="my-1">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                className="item-result-skeleton flex justify-start align-center my-2"
                key={index.toFixed(2)}
              >
                <div className="item-result-skeleton-circle" />
                <div className="item-result-skeleton-square" />
              </div>
            ))}
          </div>
        )}
        {!loading &&
          results.length > 0 &&
          results.slice(0, 3).map((result, index) => (
            <div onClick={onClose} role="presentation" key={index.toFixed(2)}>
              {renderItem(result)}
            </div>
          ))}
      </div>
    </div>
  </div>
);

export default SearchResultData;
