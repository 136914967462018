import React from 'react';

const TrustWalletIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9511 32C24.6517 32 31.705 24.8365 31.705 16C31.705 7.16345 24.6517 0 15.9511 0C7.25051 0 0.197266 7.16345 0.197266 16C0.197266 24.8365 7.25051 32 15.9511 32Z"
      fill="#3375BB"
    />
    <path
      d="M16.0628 7C19.1762
      9.6408 22.7464 9.47795 23.7666 9.47795C23.5434 24.4969 21.8433 21.5186 16.0628 25.73C10.2823 21.5186 8.59279 24.4969 8.36963 9.47795C9.37911 9.47795 12.9494 9.6408 16.0628 7Z"
      stroke="white"
      strokeWidth="2.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrustWalletIcon;
