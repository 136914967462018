import React, { FC } from 'react';

interface Props {
  className?: string;
}

const SkeletonLoader: FC<Props> = ({ className }: Props) => (
  <div className={`skeleton ${className} animate`} />
);

SkeletonLoader.defaultProps = {
  className: 'nft-card border-radius-20',
};

export default SkeletonLoader;
