import { notification } from 'antd';
import '../public/static/fonts/font.styles.css';
import '../styles/global.css';
import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil';
import 'nprogress/nprogress.css';
import 'video.js/dist/video-js.css';
import NProgress from 'nprogress';
import jsCookie from 'js-cookie';
import { localRemoveHyphen } from '../lib/Utils';
import { Layout } from '../components/ui/Layout';
import cookieParser from '../lib/cookieParser';
import locales from '../locales';
import { onMessageListener } from '../lib/db/FirebaseConfig';

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-console,func-names
  console.log = function () {};
}

// eslint-disable-next-line react/prop-types
function MyApp({ Component, pageProps, cookies }) {
  const { locale } = useRouter();
  // const setNotification = useSetRecoilState(notificationsState);

  useEffect(() => {
    onMessageListener()
      .then((payload: Record<string, Record<string, string>>) => {
        if (payload?.notification) {
          const content = {
            message: payload?.notification.title,
            description: payload?.notification.body,
          };
          notification.success(content);
        }
      })
      .catch((err) => console.log('onMessageListener failed ==>', err));
  }, []);

  // On capturing notification

  useEffect(() => {
    if (window !== undefined && 'serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(
          (registration) => {
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope,
            );
          },
          (err) => {
            console.log('Service Worker registration failed: ', err);
          },
        );
      });
    }
  }, []);

  useEffect(() => {
    cookies._anft_language = locale;
    jsCookie.set('_anft_language', locale);
    locales.changeLanguage(localRemoveHyphen(locale));
  }, [locale]);

  return (
    <>
      <RecoilRoot>
        <Layout>
          <Component cookies={cookies} {...pageProps} />
        </Layout>
      </RecoilRoot>
    </>
  );
}

MyApp.getInitialProps = async ({ ctx, Component }) => {
  const cookies = cookieParser(ctx.req);
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    cookies,
    pageProps,
    browserLanguage: localRemoveHyphen(
      ctx?.req?.headers['accept-language'] || '',
    ),
  };
};

export default MyApp;
