import {
  NetworkState,
  NftModel,
  AdminSpecialDropData,
  CollectionModel,
  SupportedChains,
  SearchResultData,
} from '../lib/models/NftModel';
import { UserAccount, UserCollection } from '../lib/models/UserAccount';
import {
  LeaderboardDataResponse,
  TopTradersResponse,
} from '../lib/models/GeneralModels';

export interface Sorter {
  field:
    | 'priority'
    | 'createdAt'
    | 'createdAtTimestamp'
    | 'priceNumber'
    | 'listed'
    | 'upvotesCount';
  ordering: 'desc' | 'asc';
  name?: string;
  chain?: SupportedChains;
  maxPrice?: number;
  minPrice?: number;
}

export interface InitialNftLoad {
  nfts: NftModel[];
  categories: string[];
  hasLoaded: boolean;
  enableInfiniteScroll: boolean;
}

export interface InitialCollectionLoad {
  collections: CollectionModel[];
  hasLoaded: boolean;
}

export interface InitialAuctionLoad {
  auctions: NftModel[];
  hasLoaded: boolean;
}

export interface InitialStakingPoolLoad {
  pools: PoolData[];
  hasLoaded: boolean;
}

export interface InitialAdminLoad {
  usersMeta: {
    pageSize: number;
    currentPage: number;
    totalPages: number;
    totalRecords: number;
  };
  users: UserAccount[];
  dropsMeta: {
    pageSize: number;
    currentPage: number;
    totalPages: number;
    totalRecords: number;
  };
  drops: AdminSpecialDropData[];
  hasLoaded: boolean;
}

export enum TraderTypeSelected {
  BUYER,
  SELLER,
}

export interface InitialTopTradersLoad {
  topTraders: TopTradersResponse;
  traderTypeSelected: TraderTypeSelected;
  hasLoaded: boolean;
}

export interface SearchStore {
  results: SearchResultData;
  searching: boolean;
  hasRunSearch: boolean;
  lastQuery: string;
}

export interface InitialSellersLoad {
  sellers: UserAccount[];
  hasLoaded: boolean;
}

export interface UserLoad {
  user: UserAccount;
  hasLoaded: boolean;
}

export interface NftLoad {
  nft: NftModel;
  hasLoaded: boolean;
}

export interface ConnectionAccount {
  id?: string;
  address?: string;
  username?: string;
  userBio?: string;
  balance?: string;
  coverUrl?: string;
  coverThumbnailUrl?: string;
  userAvatarUrl?: string;
  userAvatarUrlThumbnail?: string;
  userAvatarUrlCompressed?: string;
  socialUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  facebookUrl?: string;
  defaultAsset?: string;
  defaultAssetPrice?: string;
  MATICAssetPrice?: string;
  BNBAssetPrice?: string;
  AIRTAssetPrice?: string;
  AVAXAssetPrice?: string;
  banned?: boolean;
  verified?: boolean;
  network?: NetworkState;
  notificationsCount?: number;
  sales?: {
    totalAmount: number;
    numberOfSales: number;
    airDropValue?: string;
  };
  buys?: {
    totalAmount: number;
    numberOfBuy: number;
    airDropValue?: string;
  };
  computedAirdropBalance?: string;
  hasAcceptedTerms?: boolean;
  canCreateDrop?: boolean;
  notAllowedToMint?: boolean;
  userRoles?: Array<string>;
  collections?: Array<UserCollection>;
  airtTokenBalance?: string;
  chain?: SupportedChains;
  chainDefaultAsset?: string;
  referralBalance?: number | null;
  referralCode?: string;
  referredUserId?: string;
  referredUserReferralCoce?: string;
}

export interface QueryLoaderStateModel {
  inLoadingState: boolean;
  queryOrder: Sorter;
}

export interface LeaderboardListState {
  hasLoaded: boolean;
  leaderboard: LeaderboardDataResponse;
}

export const Sorters = {
  // Default sort
  default: {
    field: 'priority',
    ordering: 'desc',
    name: 'default',
  },

  // Show recently added
  recent: {
    field: 'createdAt',
    ordering: 'desc',
    name: 'recent',
  },

  // Show recently added
  oldest: {
    field: 'createdAt',
    ordering: 'asc',
    name: 'oldest',
  },

  // Most expensive
  expensive: {
    field: 'price',
    ordering: 'desc',
    name: 'expensive',
  },

  // Least expensive
  cheapest: {
    field: 'price',
    ordering: 'asc',
    name: 'cheapest',
  },

  // Currently listed
  onSale: {
    field: 'listed',
    ordering: 'desc',
    name: 'onSale',
  },

  upvotesCount: {
    field: 'upvotesCount',
    ordering: 'desc',
    name: 'upvotesCount',
  },

  // Most expensive collection
  expensiveC: {
    field: 'nftTradedVolume',
    ordering: 'asc',
    name: 'expensiveC',
  },

  // Least expensive collection
  cheapestC: {
    field: 'nftTradedVolume',
    ordering: 'desc',
    name: 'cheapestC',
  },
};

// Fixed sort order when user are filtering by category
export const FixedDefaultQueryOrder: Sorter = {
  field: 'priority',
  ordering: 'desc',
  name: 'default',
};

export interface StorageFeeData {
  fileSizeInMb?: number;
  transactionHash?: string;
  storageFeeValue?: string;
  nftName?: string;
  hasPaidStorageFee?: boolean;
  fileType?: string;
  file?: File;
}

export interface PoolData {
  id: string;
  months: number;
  status: boolean;
  createdAt: string;
}
