/* eslint-disable import/no-cycle */
import { IAttribute, IExternalNFT } from '../@Types';
import { getUrlFileType } from '../Utils';
import { UserAccount } from './UserAccount';

export const DefaultChain = 'bsc';
export type SupportedChains = 'bsc' | 'polygon' | 'fantom' | 'ethereum';
export type TAccountType = 'individual' | 'project';
export type SupportedFilterChains = SupportedChains | 'all';
export type TTxEventType = 'replay-transfer' | 'replay-sale';

export enum SupportedCurrencies {
  AIRT = 'AIRT',
  BNB = 'BNB',
  MATIC = 'MATIC',
  ETH = 'ETH',
}

export interface NftModel {
  tokenID?: string;
  baseID: string;
  image?: string;
  url?: string;
  urlCompressed?: string;
  urlMedium?: string;
  urlThumbnail?: string;
  gcpUrl?: string;
  price: string;
  name: string;
  description: string;
  ownerAddress: string;
  mintTransactionHash?: string;
  storageFeeTransaction?: string;
  fileSize?: number;
  fileType?: string;
  storageFee?: string;
  marketplace?: string;
  contractAddress?: string;
  initialCreatorAddress?: string;
  listed: boolean;
  listedOnChain?: boolean;
  upvotesCount?: number;
  oldDropID?: string;
  id?: string;
  chain?: SupportedChains;
  currency?: SupportedCurrencies;
  drop?: Partial<SpecialDropModel>;
  creator?: UserAccount;
  owner?: UserAccount;
  attributes?: IAttribute[];

  auction?: boolean;
  auctionOpen?: boolean;
  auctionStartedAt?: string;
  auctionEndedAt?: string;
  auctionInitialPrice?: number;

  listingID?: string;
  listingStatus?: string;
  listingType?: number;
  offerListingPrice?: number;

  isV2Listed?: boolean;

  verified?: boolean;
  isExternal?: boolean;
  USDPrice?: string;
  isMysteryBox?: boolean;

  externalTokenId?: string;
  tokenAddress?: string;
}

export interface CollectionModel {
  active?: boolean;
  collection?: boolean;
  createdAt?: string;
  creationFeeTransactionHash?: string;
  creator: {
    id?: string;
    userAvatarUrl?: string;
    userAvatarUrlCompressed?: string;
    userAvatarUrlThumbnail?: string;
    username?: string;
    walletAddress: string;
  };
  creatorAddress: string;
  creatorId?: string;
  creatorUsername?: string;
  deactivatedAt?: string;
  description?: string;
  dropCampaignUrl?: string;
  dropID?: string;
  dropType?: string;
  id?: string;
  imageUrl?: string;
  imageUrlThumbnail?: string;
  nftFloorPrice?: number;
  nftOwnerTotalNumber?: number;
  nftTotalNumber?: number;
  nftTradedVolume?: number;
  priority?: number;
  published?: boolean;
  title: string;
  updatedAt?: string;
  currency?: string;
  chain?: string;
}

export interface NftListingUpdate {
  baseID: string;
  nftID: string;
  userAddress: string;
  setIsListed: boolean;
  listingUpdateTxHash: string;
}

export interface NftIDUpdate {
  baseID: string;
  tokenID: string;
  mintTransactionHash: string;
  chain: SupportedChains;
}

export interface NftPriceUpdate {
  baseID: string;
  nftID: string;
  newPrice: string;
  priceUpdateTxHash: string;
}

export interface NftSaleEvent {
  nftName?: string;
  transactionHash: string;
  newOwnerAddress: string;
  newOwnerId?: string;
  sellerAddress: string;
  nftIDSold: string;
  nftUrl?: string;
  nftFileType?: string;
  nftBaseID?: string;
  nftSoldAtPrice: string;
  currentPrice?: string;
  doneOnTimestamp?: number;
  chain: SupportedChains;
  doneOn?: string;
  buyer?: UserAccount;
  seller?: UserAccount;
  nft?: NftModel;
}

export interface NftCardData {
  nftName: string;
  nftUrl: string;
  nftUrlThumbnail?: string;
  nftBaseID: string;
  nftTokenID: string;
  nftFileType?: string;
  nftPrice: string;
  upvotesCount?: number;
  chain?: SupportedChains;
  currency?: SupportedCurrencies;
  openForSale: boolean;
  owner?: UserAccount;
  nft?: NftModel;
}

export interface NFTSignature {
  fio_address: string;
  chain_code: string;
  contract_address: string;
  token_id: string;
  url: string;
  hash: string;
  metadata: string;
}

export interface SpecialDropData {
  ID: string;
  dropTitle: string;
  dropImage: string;
  dropType?: string;
  dropCampaignUrl?: string;
}

export interface CollectionData {
  ID: string;
  collectionTitle: string;
  collectionUrl?: string;
  collectionUrlThumbnail?: string;
  collectionTradedVolume?: number;
  creatorAddress?: string;
  owner?: {
    username: string;
    walletAddress: string;
    userAvatarUrl?: string;
    userAvatarUrlThumbnail?: string;
  };
  collectionCampaignUrl?: string;
  currency?: string;
  chain?: string;
}

export interface AdminSpecialDropData {
  id: string;
  dropID: string;
  creatorAddress: string;
  creatorUsername?: string;
  title?: string;
  description: string;
  imageUrl: string;
  imageUrlThumbnail?: string;
  creationFeeTransactionHash?: string;
  published: boolean;
  active: boolean;
  priority?: number;
  collection?: boolean;
  dropType?: string;
  deactivatedAt?: string;
  createdAt?: string;
}

export interface NftDetailResponse {
  nft: NftModel;
  owner: UserAccount;
  creator: UserAccount;
  historyEvents: NftGeneralEvent[];
  ownerOtherNfts?: NftModel[];
  bids: NFTBid[];
  offers: NFTOffer[];
  updateNftData?: (payload: NftModel) => void;
}

export interface NFTBid {
  id: string;
  nftId: string;
  nftBaseID: string;
  userId: string;
  userAddress: string;
  price: number;
  signedTransactionHexData: string;
  createdAt: string;
  updatedAt: string;
  user: NFTBidUser;
}

export interface NFTOffer {
  id: string;
  nftId: string;
  nftBaseID: string;
  listingID: string;
  price: number;
  status: string;
  offerTrxHash: string;
  sellerAddress: string;
  buyerAddress: string;
  buyerId?: string;
  createdAt: string;
  updatedAt: string;
  offerStartedAt: string;
  offerEndedAt: string;
  user: NFTOfferUser;
}

export interface NFTBidUser {
  id: string;
  username: string;
  walletAddress: string;
  userAvatarUrl: string;
  userAvatarUrlCompressed: string;
  userAvatarUrlThumbnail: string;
}

export interface NFTOfferUser {
  id: string;
  username: string;
  walletAddress: string;
  verified: boolean;
  userAvatarUrl: string;
  userAvatarUrlCompressed: string;
  userAvatarUrlThumbnail: string;
}

export interface UserGroupedNfts {
  user?: UserAccount;
  userFollowed?: boolean;
  currentNfts: NftModel[];
  soldNfts: NftSaleEvent[];
  boughtNfts: NftSaleEvent[];
  createdNfts: NftModel[];
  specialDrops: SpecialDropModel[];
  hiddenNfts: NftModel[];
  externalNfts?: NftModel[];
  createdOffers?: UserOfferModel[];
  receivedOffers?: UserOfferModel[];
}

export enum NftGeneralEventType {
  SALE = 'SALE',
  MINT = 'MINT',
  PRICE_UPDATE = 'PRICE_UPDATE',
  NFT_TRANSFER = 'NFT_TRANSFER',
  AUCTION_ESCROW_TRANSFER = 'AUCTION_ESCROW_TRANSFER',
  AUCTION_NFT_ESCROW_RELEASE = 'AUCTION_NFT_ESCROW_RELEASE',
  AUCTION_NFT_ESCROW_SALE = 'AUCTION_NFT_ESCROW_SALE',
}

export interface NftGeneralEvent {
  type: NftGeneralEventType;
  nftName?: string;
  doneOn: string;
  createdAt?: string;
  transactionHash: string;
  nftUrl?: string;
  nftFileType?: string;
  nftBaseID?: string;
  nftDescription?: string;
  nftIDSold: string;
  chain: SupportedChains;

  seller?: UserAccount;

  currentPrice?: string;
  newPrice?: string;
  oldPrice?: string;
  currency?: string;

  receiver?: UserAccount;
  receiverAddress?: string;
  actor?: UserAccount;
  actorAddress?: string;
  nftIDTransferred?: string;
}

export interface NetworkState {
  type: string;
  message: string;
  link: string;
}

export interface NftDeletion {
  userAddress: string;
  nftTokenID: string;
  nftBaseID: string;
  message: string;
  signature?: string;
}

export interface UpvoteEvent {
  baseID?: string;
  recordedOn?: number;
  upvoterAddress: string;
  upvoteAmount: number;
  nftTokenID: string;
  upvoteTransactionHash: string;
  chain: SupportedChains;
}

export interface SearchResultData {
  collections: CollectionModel[] | NftModel[];
  users: UserAccount[];
  items: SearchNftResult[] | NftModel[];
}

export interface SearchNftResult {
  baseID: string;
  ownerAddress: string;
  ownerUsername: string;
  creatorUsername: string;
  mintTransactionHash: string;
  tokenID: string;
  name: string;
  listed: boolean;
  verified: boolean;
  category: string;
  gcpUrl: null;
  url: string;
  urlCompressed: null;
  urlThumbnail: null;
  price: number;
  USDPrice: number;
  fileType: string;
  currency: string;
  chain: string;
}

export const DELETION_MESSAGE = 'DELETE THIS NFT';

export function NftModelToCardData(
  nftModel: NftModel,
  // If from search results - show all items as listed because the state is synced once for search results
  fromSearchResult?: boolean,
): NftCardData {
  const nftCardData: NftCardData = {
    upvotesCount: nftModel.upvotesCount,
    nftBaseID: nftModel.baseID,
    nftName: nftModel.name,
    nftPrice: nftModel.price,
    nftTokenID: nftModel.tokenID,
    nftUrl: nftModel.urlCompressed || nftModel.url || nftModel.gcpUrl,
    nftUrlThumbnail: nftModel?.urlThumbnail || null,
    nftFileType: nftModel?.fileType,
    openForSale: fromSearchResult || nftModel.listed,
    owner: nftModel?.owner,
    chain: nftModel?.chain,
    currency: nftModel?.currency,
    nft: nftModel,
  };
  return nftCardData;
}

export function NftSaleToCardData(nftSaleEvent: NftSaleEvent): NftCardData {
  const nftCardData: NftCardData = {
    // Avoid crashes on non existing nftSaleEvent?.nft?.baseID
    nftBaseID: (nftSaleEvent.nftBaseID || nftSaleEvent?.nft?.baseID) ?? '',
    nftName: nftSaleEvent.nftName || nftSaleEvent?.nft?.name,
    nftPrice: nftSaleEvent.nftSoldAtPrice || nftSaleEvent.currentPrice,
    nftTokenID: nftSaleEvent.nftIDSold,
    nftFileType: nftSaleEvent.nft.fileType,
    nftUrl:
      nftSaleEvent.nftUrl ||
      nftSaleEvent?.nft?.urlThumbnail ||
      nftSaleEvent?.nft?.url,
    nftUrlThumbnail: nftSaleEvent?.nft?.urlThumbnail || null,
    openForSale: true,
    chain: nftSaleEvent?.nft?.chain,
    nft: nftSaleEvent.nft,
  };
  return nftCardData;
}

export const externalToNftModel = (nft: IExternalNFT) => ({
  name: nft.name,
  baseID: nft.slug || nft.id,
  fileType: getUrlFileType(nft.image),
  urlThumbnail: nft.image || null,
  url: nft.image || null,
  listed: !!nft.minted,
  ownerAddress: nft.userAddress || null,
  initialCreatorAddress: nft.userAddress || null,
  external_url: nft.external_url || null,
  description: nft.description,
  chain: nft.chain || null,
  marketplace: nft.contractName || null,
  contractAddress: nft.contractAddress,
  isExternal: true,
});

export const DropType = {
  NORMAL: 'NORMAL',
  CAMPAIGN: 'CAMPAIGN',
};

export interface SpecialDropModel {
  dropID?: string;
  title: string;
  creatorAddress: string;
  creatorId: string;
  creatorUsername: string;
  description: string;
  imageUrlThumbnail: string;
  imageUrl: string;
  fileType?: string;

  maxDropItemsCount?: number;
  maximumContributorCount?: number;

  createdAtTimestamp?: number;
  deactivatedAt?: number;

  priority?: number;

  // Has it been published by owner
  published?: boolean;

  creationFeeTransactionHash?: string;

  // There is a use case to use drops for campaigns on the front-end.
  // These are different from normal drops in a sense that they don't NFTs but they in fact redirect to a campaign page
  dropType?: string;

  // campaign URL to which the drop redirects to, this only applies to drop of type CAMPAIGN,
  dropCampaignUrl?: string;

  nftTotalNumber?: number;
  nftOwnerTotalNumber?: number;
  nftFloorPrice?: number;
  nftTradedVolume?: number;
  polygonNftTradedVolume?: number;
  polygonNftFloorPrice?: number;
  USDNftFloorPrice?: number;
  USDNftTradedVolume?: number;

  symbol?: string;
  currency?: string;
  chain?: string;
}

export function SpecialDropToDropCardData(
  specialDrop: SpecialDropModel,
): SpecialDropData {
  return {
    ID: specialDrop.dropID,
    dropImage: specialDrop.imageUrlThumbnail || specialDrop.imageUrl,
    dropTitle: specialDrop.title,
    dropType: specialDrop.dropType,
    dropCampaignUrl: specialDrop.dropCampaignUrl,
  };
}

export function CollectionModelToCardData(
  collectionModel: CollectionModel,
): CollectionData {
  return {
    ID: collectionModel.dropID,
    collectionTitle: collectionModel.title,
    collectionUrl: collectionModel.imageUrl,
    collectionUrlThumbnail: collectionModel.imageUrlThumbnail,
    collectionTradedVolume: collectionModel.nftTradedVolume,
    creatorAddress: collectionModel.creatorAddress,
    owner: {
      username: collectionModel.creator.username,
      walletAddress: collectionModel.creator.walletAddress,
      userAvatarUrl: collectionModel.creator.userAvatarUrl,
      userAvatarUrlThumbnail: collectionModel.creator.userAvatarUrlThumbnail,
    },
    collectionCampaignUrl: collectionModel.dropCampaignUrl,
    currency: collectionModel.currency,
    chain: collectionModel.chain,
  };
}

export interface LaunchpadPackage {
  id: string;
  launchpadId: string;
  name: string;
  tokensInPackage: number;
  supplyPurchased: number;
  unitPrice: number;
  unitPriceSymbol: string;
  packageSupply: number;
  tokenSymbol: string;
  imageUrl: string;
  imageUrlThumbnail?: any;
  imageType: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export class PurchaseDto {
  packageId: number;

  buyerAddress: string;

  tokensTotal: number;

  price: number;

  transactionHash: string;
}

export interface Launchpad {
  id: string;
  title: string;
  tokenSymbol: string;
  description: string;
  description_fr?: any;
  description_es?: any;
  description_id?: any;
  description_jp?: any;
  description_ru?: any;
  targetAmount: number;
  raisedAmount: number;
  startAt: Date;
  endAt: Date;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  pricePerToken: number;
  packages: LaunchpadPackage[];
}

export interface LaunchPadResponse {
  launchpad: Launchpad;
}

export interface LaunchpadDistribution {
  id: string;
  userId: string;
  userAddress: string;
  transactionHash: string;
  amount: number;
  token: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface LaunchpadBalanceResponse {
  userAddress: string;
  tokensBalance: number;
  tokensTotal: number;
  distributions: LaunchpadDistribution[];
}

export interface ClaimableNFT {
  baseID: string;
  ownerAddress: string;
  mintTransactionHash?: any;
  name: string;
  urlCompressed: string;
  urlThumbnail: string;
  price: number;
  launchpadId: string;
  chain: SupportedChains;
}

export interface Meta {
  currentPage: number;
  totalPages: number;
  totalNfts: number;
}

export interface LaunchpadClaimableNFTsResponse {
  statusCode: number;
  userAddress: string;
  tokensBalance: number;
  meta: Meta;
  nfts: ClaimableNFT[];
}

export interface NftToDrop {
  signature?: string;
  creatorAddress?: string;
  timestamp?: string;
}

export interface MultiNFTDrop {
  creatorAddress: string;
  nftIDs: Array<string>;
  signature?: string;
  timestamp: string;
}

export interface NFTListing {
  tokenAddress: string;
  tokenID: string;
  listingID: number;
  sellerAddress: string;
  price: number;
  listingType: number;
  transactionHash: string;
  startAt: number;
  endAt: number;
}

export interface Bid {
  listingID: number;
  bidder: string;
  totalBidAmount: number;
}
export interface NftListingCreation {
  walletAddress: string;
  contractAddress: string;
  price: number;
  listingID: number;
  listingTrxHash: string;
  fcmToken?: string;
}

export interface MakeOfferListing {
  listingID: number;
  sellerAddress: string;
  price: number;
  transactionHash: string;
  startAt: string;
  endAt: string;
}

export interface NftOfferPlacing {
  buyerAddress: string;
  sellerAddress: string;
  price: number;
  listingID: number;
  offerTrxHash: string;
  fcmToken: string;
  offerStartedAt: string;
  offerEndedAt: string;
  chain: SupportedChains;
  baseID: string;
}

export interface NftOfferAcceptance {
  buyerAddress: string;
  sellerAddress: string;
  price: number;
  listingID: number;
  offerId: number;
  offerTrxHash: string;
  fcmToken?: string;
  chain: SupportedChains;
  baseID: string;
}

export interface NftOfferUpdate {
  buyerAddress: string;
  sellerAddress?: string;
  price: number;
  offerTrxHash: string;
  fcmToken?: string;
  chain: SupportedChains;
}

export interface NftOfferCancelling {
  buyerAddress: string;
  listingID: number;
  offerId: number;
  offerTrxHash: string;
  fcmToken?: string;
  chain: SupportedChains;
  baseID: string;
}

export enum NftOfferStatus {
  CREATED = 'created',
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
}

export interface UserOfferModel {
  id: string;
  nftId: string;
  listingID: string;
  sellerId: string;
  sellerAddress: string;
  buyerId: string;
  buyerAddress: string;
  price: number;
  status: string;
  offerTrxHash: string;
  offerStartedAt: string;
  offerEndedAt?: string;
  nft: NftModel;
}

export enum UserOfferType {
  CREATED = 'created',
  RECEIVED = 'received',
}

export interface V2ListedNftSaleEvent {
  baseID?: string;
  newOwnerAddress: string;
  newOwnerId?: number;
  nftIDSold: string;
  nftSoldAtPrice: string;
  sellerAddress: string;
  transactionHash: string;
  country?: string;
  currency?: SupportedCurrencies;
  chain?: SupportedChains;
}

export interface CancelNftListing {
  userAddress: string;
  listingID: number;
  cancelListingTrxHash: string;
}

export interface UpdateNftListingPrice {
  listingID: number;
  newPrice: number;
  oldPrice: number;
  seller: string;
  updateListingTrxHash: string;
}

export interface ListingPriceUpdate {
  newPrice: number;
  priceUpdateTxHash: string;
}
