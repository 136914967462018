import { storageService } from '../db/StorageService';
import { RetryStatus } from '../db/nedb/models/DBModels';
import { backendApiService } from './BackendApiService';
import { sleep } from '../Utils';

class EventsRetryService {
  public async startRetryAll() {
    try {
      setInterval(async () => {
        const backgroundJobConnection = window.connection;
        if (
          backgroundJobConnection &&
          backgroundJobConnection.currentProvider
        ) {
          await Promise.all([
            this.retryingUnconfirmedDropCreations(),
            this.retryingUnconfirmedDropProlongationPayments(),
          ]);
        }
      }, 100_000);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  public async retryingUnconfirmedDropCreations() {
    const dbStorageService = storageService.neDBStorageService;
    const pendingCreations = await dbStorageService.retrieveAllDropCreationTransactionByStatus(
      RetryStatus.PENDING,
    );

    if (pendingCreations.length) {
      // eslint-disable-next-line no-console
      console.log('RETRYING_PENDING_DROP_CREATION: LIST', {
        size: pendingCreations.length,
        pendingCreations,
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const dropCreation of pendingCreations) {
      await sleep(5_000);

      const updatedRetrialCount = (dropCreation.registeredRetrials || 0) + 1;
      // We change the status to finally failed if the number of retrials have exceeded 3
      const newStatus =
        updatedRetrialCount > 3
          ? RetryStatus.FINALLY_FAILED
          : dropCreation.status;

      // eslint-disable-next-line no-console
      console.log('RETRYING_PENDING_DROP_CREATION', {
        updatedRetrialCount,
        newStatus,
        dropCreation,
      });

      try {
        const submitResult = await backendApiService.submitDrop(dropCreation);
        if (submitResult) {
          await dbStorageService.saveDropCreationTransaction({
            ...dropCreation,
            status: RetryStatus.SUCCESSFULLY_SUBMITTED,
          });
          // eslint-disable-next-line no-console
          console.log(
            'RETRYING_PENDING_DROP_CREATION: SUCCESSFUL_RETRY',
            dropCreation,
          );
        } else {
          await dbStorageService.saveDropCreationTransaction({
            ...dropCreation,
            registeredRetrials: updatedRetrialCount,
            status: newStatus,
          });
        }
      } catch (e) {
        await dbStorageService.saveDropCreationTransaction({
          ...dropCreation,
          registeredRetrials: updatedRetrialCount,
          status: newStatus,
        });
      }
    }
  }

  public async retryingUnconfirmedDropProlongationPayments() {
    const dbStorageService = storageService.neDBStorageService;
    const pendingDropExtensions = await dbStorageService.retrieveAllDropDurationExtensionsByStatus(
      RetryStatus.PENDING,
    );

    if (pendingDropExtensions) {
      // eslint-disable-next-line no-console
      console.log('RETRYING_PENDING_DROP_EXTENSION: LIST', {
        size: pendingDropExtensions.length,
        pendingDropExtensions,
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const dropExtension of pendingDropExtensions) {
      await sleep(5_000);

      const updatedRetrialCount = (dropExtension.registeredRetrials || 0) + 1;
      // We change the status to finally failed if the number of retrials have exceeded 3
      const newStatus =
        updatedRetrialCount > 3
          ? RetryStatus.FINALLY_FAILED
          : dropExtension.status;

      // eslint-disable-next-line no-console
      console.log('RETRYING_PENDING_DROP_EXTENSION', {
        updatedRetrialCount,
        newStatus,
        dropExtension,
      });

      try {
        const submitResult = await backendApiService.submitBackendProlongDropDuration(
          dropExtension,
        );
        if (submitResult) {
          await dbStorageService.saveDropExtensionTransaction({
            ...dropExtension,
            status: RetryStatus.SUCCESSFULLY_SUBMITTED,
          });
          // eslint-disable-next-line no-console
          console.log(
            'RETRYING_PENDING_DROP_EXTENSION: SUCCESSFUL_RETRY',
            dropExtension,
          );
        } else {
          await dbStorageService.saveDropExtensionTransaction({
            ...dropExtension,
            registeredRetrials: updatedRetrialCount,
            status: newStatus,
          });
        }
      } catch (e) {
        await dbStorageService.saveDropExtensionTransaction({
          ...dropExtension,
          registeredRetrials: updatedRetrialCount,
          status: newStatus,
        });
      }
    }
  }
}

export const retryService = new EventsRetryService();
