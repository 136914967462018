/* eslint-disable prettier/prettier */
import 'firebase/messaging';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Col, message, Row, Space, Empty, Button } from 'antd';
import Router, { useRouter } from 'next/router';
import jsCookie from 'js-cookie';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import {
  canLoadMoreSearchResultState,
  connectionAccountState,
  searchModeState,
  searchStoreState,
  assetMarketPricesState,
  showMenuState,
  showSubscriptionModalState,
  searchResultState,
  smallDeviceSearchTriggerState,
  showConnectOptionsModalState,
  StakingState,
  adminCategoryTab,
  ReferralState,
} from '../../recoil/atoms';

import { storageService } from '../../lib/db/StorageService';
import { connectionInteract } from '../../lib/web3/ConnectionInteract';
import { marketApi } from '../../lib/MarketApiService';
import { AccountModal } from './AccountModal';
import {
  AppIcon,
  CancelSearchIcon,
  MenuIconPng,
  SearchIcon,
} from '../../lib/Resources';
import { localCache } from '../../lib/db/LocalCache';
import { AcceptTermsModal } from './AcceptTermsModal';
import { backendApiService } from '../../lib/services/BackendApiService';
import { SearchComponent } from './SearchComponent';
import { configService } from '../../lib/services/ConfigService';
import { disablePageScroll, isOnMobile, sleep } from '../../lib/Utils';
import { HowToConnectModal } from './HowToConnectModal';
import { Web3Service } from '../../lib/web3/Web3Service';
import {
  MarketPricePickerService,
  SupportedPair,
} from '../../lib/services/MarketPriceService';
import MenuIcon from '../icons/MenuIcon';
import { SubscribeModal } from './SubscribeToPushNotification';
import { requestFirebaseNotificationPermission } from '../../lib/db/FirebaseConfig';
import SubConnectMenuItem from './SubConnectionMenuItem';
import useMediaQuery from '../hooks/useMediaQuery';
import UserSmallCard from './UserSmallCard';
import NftSmallCard from './NftSmallCard';
import useDebounce from '../hooks/useDebounce';
import CollectionSmallCard from './CollectionSmallCard';
import useClickOutside from '../hooks/useClickOutside';
import SearchResultData from './SearchResultData';
import ReferralModal from './ReferralModal';
import { UserAccount } from '../../lib/models/UserAccount';

export const PageHeader = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { pathname, push, query, asPath } = useRouter();

  const [searchResultList, setSearchResultList] = useRecoilState(
    searchStoreState,
  );
  const setStakingState = useSetRecoilState(StakingState);

  const [showMenu, setShowMenu] = useRecoilState<boolean>(showMenuState);

  const [, setAssetsMarketPrice] = useRecoilState(assetMarketPricesState);

  const [connectionAccount, setConnectionAccount] = useRecoilState(
    connectionAccountState,
  );

  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [connecting, setConnecting] = useState(false);

  const [inSearchMode, setInSearchMode] = useRecoilState(searchModeState);

  const referral = useRecoilValue(ReferralState);

  const [showSearchResult, setShowSearchResult] = useRecoilState(
    searchResultState,
  );

  const [searchQuery, setSearchQuery] = useState<string>(
    (query?.search || '') as string,
  );

  const [showConnectOptionsModal, setShowConnectOptionsModal] = useRecoilState(
    showConnectOptionsModalState,
  );

  const setCanLoadMoreSearchResults = useSetRecoilState(
    canLoadMoreSearchResultState,
  );

  const [
    smallDeviceSearchTrigger,
    setSmallDeviceSearchTrigger,
  ] = useRecoilState(smallDeviceSearchTriggerState);

  const [termsAndServiceShown, setTermsAndServiceShown] = useState(false);
  const [, setSubscriptionShown] = useRecoilState(showSubscriptionModalState);

  const adminSelectedCategory = useRecoilValue(adminCategoryTab);

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [isPageHeaderOnTop, setIsPageHeaderOnTop] = useState<boolean>(false);

  const [showReferralModal, setShowReferralModal] = useState(false);

  const debouncedSearch = useDebounce(searchQuery, 800);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 120) {
        setIsPageHeaderOnTop(true);
      } else setIsPageHeaderOnTop(false);
    });
    return () => {
      // @ts-ignore
      window.addEventListener('scroll');
    };
  }, [showSearchResult]);

  async function checkTermsOfServiceAcceptance() {
    if (connectionAccount && connectionAccount.address) {
      const hasAcceptedTermsLocal = localCache.checkHasAcceptedTerms(
        connectionAccount.address,
      );
      const hasAcceptedTermsServer = connectionAccount.hasAcceptedTerms;

      if (hasAcceptedTermsLocal || hasAcceptedTermsServer) {
        localCache.setHasAcceptedTerms(connectionAccount.address);
        return;
      }

      if (!hasAcceptedTermsLocal && !hasAcceptedTermsServer) {
        setTermsAndServiceShown(true);
        return;
      }

      if (!hasAcceptedTermsServer && hasAcceptedTermsLocal) {
        setTermsAndServiceShown(true);
      }
    }
  }

  async function checkSubscription() {
    if (connectionAccount && connectionAccount.address) {
      if (window === undefined) await Promise.resolve();

      const tokenStorageKey = `_an_fcm_token_${connectionAccount.address}`.toLowerCase();
      const currentToken = localStorage.getItem(tokenStorageKey);

      await sleep(6_000);

      if (currentToken === null && !termsAndServiceShown) {
        setSubscriptionShown(true);
        return;
      }

      requestFirebaseNotificationPermission().finally(() => {
        setSubscriptionShown(false);
      });
    }
  }

  async function checkReferralModalDisplay() {
    await sleep(2_000);

    if (
      asPath.includes('/join/') &&
      !termsAndServiceShown &&
      referral.user.walletAddress !== connectionAccount.address &&
      !connectionAccount.hasAcceptedTerms
    ) {
      setShowReferralModal(true);
    }
  }

  useEffect(() => {
    if (connectionAccount?.address) {
      // Terms and condition checks
      checkTermsOfServiceAcceptance();

      // Subscription to notifications
      checkSubscription();

      // Show referral modal
      checkReferralModalDisplay();
    }
  }, [connectionAccount]);

  function onAcceptTerms() {
    setTermsAndServiceShown(false);
  }

  function onSubscribe() {
    setSubscriptionShown(false);
  }

  async function onMyProfileClicked() {
    setAccountModalVisible(false);
    await Router.push(`/creators/${connectionAccount.address}`, null, {
      shallow: false,
    });
    // Called on the same page - force reload
    if (router.pathname === '/creators/[slug]') {
      Router.reload();
    }
  }

  async function onAllNotificationsClicked() {
    setAccountModalVisible(false);
    await Router.push('/notifications');
  }

  async function onMyDropsClicked() {
    setAccountModalVisible(false);
    if (
      connectionAccount.canCreateDrop &&
      !connectionAccount.notAllowedToMint
    ) {
      await Router.push(
        `/creators/${connectionAccount.address}?tab=drops`,
        null,
        {
          shallow: false,
        },
      );

      // Called on the same page - force reload
      if (router.pathname === '/creators/[slug]') {
        Router.reload();
      }
    } else {
      await Router.push(`https://www.airnfts.com`);
    }
  }

  async function onEditProfileClicked() {
    setAccountModalVisible(false);
    await Router.push('/creators/edit');
  }

  const updateConnectedAccount = async (web3: Web3) => {
    const [defaultAddress, accountBalance, chainId] = await Promise.all([
      connectionInteract.getDefaultAccount(web3),
      connectionInteract.getConnectedAccountBalanceWithWeb3(web3),
      web3?.eth?.getChainId(),
    ]);

    const networkChain = connectionInteract.getChainById(chainId);
    if (!networkChain) {
      setConnecting(false);
      await connectionInteract.switchChain('bsc');

      setConnecting(true);
    }

    window.chain = networkChain?.name || 'bsc';

    const web3Service = new Web3Service(web3);

    const [loadedAirtTokenBalance, stakingAPY] = await Promise.all([
      web3Service.fetchAirtTokenBalance(defaultAddress, networkChain?.name),
      web3Service.getRewardAPY(networkChain?.name || 'bsc'),
    ]);

    const [previousAccount, marketPrice, networkState] = await Promise.all([
      storageService.findAccountWhereAddressOrUsername(defaultAddress, {
        referralCode: (asPath.includes('/join/') && query?.slug) || undefined,
      }),
      marketApi.loadAllAssetsPrices(),
      marketApi.loadNetworkState(),
    ]);

    jsCookie.set('_anft_user_address', defaultAddress);
    localStorage.setItem('_anft_user_address', defaultAddress);

    const marketPricePicker = new MarketPricePickerService(marketPrice.data);
    const defaultAssetPrice = marketPricePicker.getAssetPrice(
      networkChain?.pair || SupportedPair.BNBUSD,
    );

    if (marketPrice && defaultAssetPrice && defaultAssetPrice.price) {
      storageService.saveDefaultAssetPrice(
        defaultAssetPrice?.price?.toString(),
        networkChain?.name,
      );
      storageService.saveAllAssetsPrices(marketPrice.data);
    }

    if (defaultAddress && previousAccount) {
      setConnectionAccount({
        ...connectionAccount,
        id: previousAccount?.id,
        address: defaultAddress,
        username: previousAccount.username,
        coverUrl: previousAccount.coverUrl,
        coverThumbnailUrl: previousAccount?.coverThumbnailUrl ?? '',
        userAvatarUrl: previousAccount.userAvatarUrl,
        userAvatarUrlThumbnail: previousAccount?.userAvatarUrlThumbnail ?? '',
        userAvatarUrlCompressed: previousAccount?.userAvatarUrlCompressed ?? '',
        socialUrl: previousAccount?.socialUrl ?? '',
        instagramUrl: previousAccount?.instagramUrl ?? '',
        twitterUrl: previousAccount?.twitterUrl ?? '',
        facebookUrl: previousAccount?.facebookUrl ?? '',
        userBio: previousAccount.userBio,
        balance: accountBalance,
        airtTokenBalance: loadedAirtTokenBalance,
        defaultAssetPrice:
          `${defaultAssetPrice?.price}` ||
          storageService.getStoredDefaultAssetPrice(networkChain?.name),
        banned: previousAccount?.banned ?? false,
        verified: previousAccount?.verified ?? false,
        sales:
          previousAccount && previousAccount.sales
            ? previousAccount.sales
            : null,
        buys:
          previousAccount && previousAccount.buys ? previousAccount.buys : null,
        network: networkState,
        computedAirdropBalance:
          previousAccount && previousAccount.computedAirdropBalance
            ? previousAccount.computedAirdropBalance
            : '0',
        hasAcceptedTerms: previousAccount?.hasAcceptedTerms ?? false,
        canCreateDrop: previousAccount?.canCreateDrop ?? false,
        notAllowedToMint: previousAccount?.notAllowedToMint ?? false,
        userRoles: previousAccount?.userRoles ?? [],
        collections: previousAccount?.collections ?? [],
        chain: networkChain?.name,
        chainDefaultAsset: networkChain?.currency,
      });
    }

    const stakingValues = localCache.getStakingValues();

    setStakingState((state) => ({
      ...state,
      AIRT: {
        ...state.AIRT,
        apy: stakingAPY || stakingValues.apy,
        total: stakingValues.totalStaked,
        tokenInfos: {
          rewardTokenName: stakingValues.rewardTokenName,
          rewardTokenSymbol: stakingValues.rewardTokenSymbol,
          stakingTokenName: stakingValues.stakingTokenName,
          stakingTokenSymbol: stakingValues.rewardTokenSymbol,
        },
        hasLoaded: true,
      },
    }));

    if (marketPrice.data && marketPrice.data.length > 0) {
      setAssetsMarketPrice(marketPrice.data);
    }
  };

  async function onWeb3Connection(web3: Web3) {
    window.connection = web3;
    await updateConnectedAccount(web3);

    setTimeout(() => setConnecting(false), 1_000);
  }

  async function subscribeProvider(provider: any) {
    if (!provider.on) {
      return;
    }
    provider.on('close', () => {
      message.warn('Wallet Disconnected 🚫');
    });
  }

  async function attemptWalletConnect() {
    try {
      setConnecting(true);

      const wcWeb3Connection = await connectionInteract.attemptWCConnection(
        subscribeProvider,
      );

      if (wcWeb3Connection) {
        await onWeb3Connection(wcWeb3Connection);
        setShowConnectOptionsModal(false);
      }

      setConnecting(false);
    } catch (error) {
      console.log(error);
      setConnecting(false);
    }
  }

  const reloadWeb3Connection = async () => {
    const web3: Web3 = await connectionInteract.attemptWeb3Connection();
    await onWeb3Connection(web3);
  };

  function detectOnAccountAndChainChanged() {
    window.ethereum.on('accountsChanged', reloadWeb3Connection);
    window.ethereum.on('chainChanged', reloadWeb3Connection);
  }

  const connectWallet = async () => {
    setConnecting(true);
    const web3: Web3 = await connectionInteract.attemptWeb3Connection();

    if (!web3) {
      setConnecting(false);
      return;
    }
    window.connection = web3;
    await updateConnectedAccount(web3);

    setConnecting(false);

    detectOnAccountAndChainChanged();
  };

  const closeConnectionModal = () => {
    setConnecting(false);
    setShowConnectOptionsModal(false);
  };

  const connectLastSession = () => {
    if (localStorage.getItem('_anft_user_address')) {
      connectWallet();
    }
  };

  useEffect(() => {
    console.info('UseEffect PageHeader init ....');
    connectLastSession();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const queryInput = searchQuery.trim();

      // setLoadingSearch(true);

      setSearchResultList({
        ...searchResultList,
        searching: true,
      });

      let searchResultsNfts;

      if (pathname.includes('admin') && adminSelectedCategory === 'users') {
        searchResultsNfts = await storageService.findAllUsers({
          page: +query?.page || 1,
          walletAddress: queryInput,
        });
      } else if (
        pathname.includes('admin') &&
        adminSelectedCategory === 'drops'
      ) {
        searchResultsNfts = await storageService.findAllDrops({
          page: +query?.page || 1,
          walletAddress: queryInput,
        });
      } else {
        searchResultsNfts = await backendApiService.getSearchResults(
          queryInput,
        );
      }

      //  Load images while still searching
      setSearchResultList({
        hasRunSearch: false,
        lastQuery: searchQuery,
        results: {
          collections: searchResultsNfts?.collections || [],
          users: searchResultsNfts?.users || [],
          items: searchResultsNfts?.items || [],
        },
        searching: true,
      });

      // Wait for images to load
      await sleep(2_000);

      setSearchResultList({
        ...searchResultList,
        results: {
          collections: searchResultsNfts.collections,
          users: searchResultsNfts.users,
          items: searchResultsNfts.items,
        },
        searching: false,
        hasRunSearch: true,
        lastQuery: searchQuery,
      });

      if (pathname.includes('admin') && !searchResultsNfts.users?.length) {
        message.warning('Wallet address not found');
      }
    }

    if (debouncedSearch) fetchData();
  }, [debouncedSearch]);

  const onConnectMenuClicked = async () => {
    try {
      console.log({ connectionAccount, connecting });
      if (connecting && (!connectionAccount || !connectionAccount.address)) {
        return;
      }

      if (connectionAccount.address) {
        setAccountModalVisible(true);
        // Stop here for already connected accounts
        return;
      }

      setShowConnectOptionsModal(true);
    } catch (e) {
      log.error(e);
    }
  };

  async function onModalConnectionWeb3Clicked() {
    try {
      setConnecting(true);

      setShowConnectOptionsModal(false);

      const web3: Web3 = await connectionInteract.attemptWeb3Connection();

      if (!web3) {
        setConnecting(false);
        message.error(t('No web3 provider was found'));
        return;
      }

      await onWeb3Connection(web3);
      setConnecting(false);
    } catch (e) {
      console.log(e);
      setConnecting(false);
    }
  }

  function onSmallDeviceSearchTriggerIconClicked() {
    setSearchQuery('');
    setSmallDeviceSearchTrigger(true);
    setSearchResultList({
      ...searchResultList,
      results: {
        collections: [],
        users: [],
        items: [],
      },
      hasRunSearch: false,
      lastQuery: '',
    });
    setInSearchMode(!inSearchMode);
  }

  async function onAllResultsClicked() {
    const queryInput = searchQuery.trim();
    if (
      !queryInput ||
      queryInput.length < configService.getMinimumSearchCharCount()
    ) {
      return;
    }

    setShowSearchResult(false);

    if (!['/', '/admin'].includes(router.pathname)) {
      await push({
        pathname: '/',
        query: {
          search: queryInput,
        },
      });
    } else {
      await push({
        pathname,
        query: {
          ...query,
          search: queryInput,
        },
      });
    }

    if (!['/admin'].includes(router.pathname)) {
      setInSearchMode(true);
      setSearchResultList({
        ...searchResultList,
        searching: true,
        lastQuery: searchQuery,
      });

      setCanLoadMoreSearchResults(true);
      const searchResultsNfts = await backendApiService.getSearchResults(
        queryInput,
      );

      // Load images while still searching
      setSearchResultList({
        searching: true,
        results: {
          collections: searchResultsNfts.collections,
          users: searchResultsNfts.users,
          items: searchResultsNfts.items,
        },
        hasRunSearch: false,
        lastQuery: searchQuery,
      });

      // Wait for images to load
      await sleep(2_500);

      setSearchResultList({
        ...searchResultList,
        results: {
          collections: searchResultsNfts.collections,
          users: searchResultsNfts.users,
          items: searchResultsNfts.items,
        },
        searching: false,
        hasRunSearch: true,
        lastQuery: searchQuery,
      });
    }
  }

  async function goHome() {
    setInSearchMode(false);
    await Router.push('/');
  }

  function OnCloseSearchResultModal() {
    // setSearchQuery('');
    setShowSearchResult(false);
  }

  function onInputQueryChange({ target }) {
    setSearchQuery(target.value);

    if (target.value.length > 1) {
      setShowSearchResult(true);
      setSearchResultList((val) => ({
        ...val,
        searching: true,
      }));
    } else {
      setSearchResultList((val) => ({
        ...val,
        results: {
          users: [],
          collections: [],
          items: [],
        },
      }));
      setShowSearchResult(false);
    }
  }

  const closeSearchResultModalRef = useClickOutside(() => {
    if (showSearchResult) {
      OnCloseSearchResultModal();
    }
  });

  function onLargeDeviceSearchDivClicked() {
    setSmallDeviceSearchTrigger(false);
  }

  async function onLogoutClicked() {
    console.log('Logging out');

    try {
      const { currentProvider } = window.connection;
      // @ts-ignore
      if (currentProvider && currentProvider.close) {
        // @ts-ignore
        await currentProvider.close();
      }

      // @ts-ignore
      if (currentProvider && currentProvider.disconnect) {
        // @ts-ignore
        await currentProvider.disconnect();
      }

      // Clear previous provider cache data
      await window.web3Modal.clearCachedProvider();

      localStorage.removeItem('_anft_user_address');
      jsCookie.remove('_anft_user_address');

      // Ignore
    } catch (e) {
      console.log(e);
    }

    setConnectionAccount({
      chain: connectionAccount.chain,
    });

    window.connection = null;
    setAccountModalVisible(false);
    setConnecting(false);

    await sleep(4_000);

    console.log('Finish Logging out');
  }

  const onOpenMenu = () => {
    if (isOnMobile()) {
      disablePageScroll();
    }
    setShowMenu(!showMenu);
  };

  return (
    <div
      style={{
        padding: '0px 0px 4px 0px',
        background: `${showSearchResult && isPageHeaderOnTop ? '' : '#f9f9f9'}`,
      }}
    >
      <div
        className={
          inSearchMode && smallDeviceSearchTrigger
            ? 'top-menu-bar nav-bar-row-hidden'
            : 'top-menu-bar'
        }
      >
        <AccountModal
          visible={accountModalVisible}
          onCancel={() => setAccountModalVisible(false)}
          onEditAccountClicked={onEditProfileClicked}
          onAllNotificationsClicked={onAllNotificationsClicked}
          onMyProfileClicked={onMyProfileClicked}
          onMyDropsClicked={onMyDropsClicked}
          onLogOutClicked={onLogoutClicked}
        />
        <AcceptTermsModal
          onFinishAccept={onAcceptTerms}
          onCancel={() => setTermsAndServiceShown(false)}
          visible={termsAndServiceShown && !showReferralModal}
        />
        <SubscribeModal
          onCancel={() => setSubscriptionShown(false)}
          onFinishSubscribe={onSubscribe}
          visible={false}
        />
        <HowToConnectModal
          visible={showConnectOptionsModal}
          onCancel={closeConnectionModal}
          onWCConnectAttempt={attemptWalletConnect}
          onWeb3ConnectAttempt={onModalConnectionWeb3Clicked}
        />
        <ReferralModal
          visible={showReferralModal}
          onCancel={() => setShowReferralModal(false)}
          user={referral.user as UserAccount}
        />
        <Row
          className="page-header-wrapper"
          justify="space-between"
          wrap={false}
        >
          <Col span={2} onClick={goHome}>
            <div className="branding-icon">
              {isMobile ? (
                <img src={AppIcon} alt="Project Icon" width={38} height={38} />
              ) : (
                <img src={AppIcon} alt="Project Icon" width={42} height={42} />
              )}
            </div>
          </Col>

          <Col
            span={isMobile ? 12 : 9}
            xs={0}
            sm={0}
            md={isMobile ? 12 : 6}
            lg={isMobile ? 12 : 9}
            xl={isMobile ? 12 : 9}
            xxl={isMobile ? 12 : 9}
          >
            <div onClick={onLargeDeviceSearchDivClicked} role="presentation">
              <SearchComponent
                enabled={!smallDeviceSearchTrigger}
                searchQuery={searchQuery}
                onChange={onInputQueryChange}
              />
            </div>
            {!pathname.includes('admin') && (
              <div
                className="search-result-wrapper flex justify-center align-start"
                style={{
                  display: `${showSearchResult ? 'block' : 'none'}`,
                }}
                ref={closeSearchResultModalRef}
              >
                <div className="search-result-content">
                  <SearchResultData
                    results={searchResultList.results.collections || []}
                    categoryTitle="Collections"
                    loading={searchResultList.searching}
                    onClose={OnCloseSearchResultModal}
                    renderItem={(collection) => (
                      <CollectionSmallCard collection={collection} />
                    )}
                  />

                  <SearchResultData
                    results={searchResultList.results.items || []}
                    categoryTitle="NFTs"
                    loading={searchResultList.searching}
                    onClose={OnCloseSearchResultModal}
                    renderItem={(nft) => <NftSmallCard nft={nft} />}
                  />

                  <SearchResultData
                    results={searchResultList.results.users || []}
                    categoryTitle="Users"
                    loading={searchResultList.searching}
                    onClose={OnCloseSearchResultModal}
                    renderItem={(user) => <UserSmallCard user={user} />}
                  />

                  {!searchResultList.searching &&
                    (searchResultList.results.collections?.length > 1 ||
                      searchResultList.results.items?.length > 1 ||
                      searchResultList.results.users?.length > 1) && (
                      <div className="flex justify-center align-center">
                        <Button
                          type="primary"
                          block
                          onClick={onAllResultsClicked}
                          className="nft-card-edit-button"
                        >
                          <span className="nft-create-drop-button-text">
                            Show more
                          </span>
                        </Button>
                      </div>
                    )}

                  {!searchResultList.searching &&
                    searchResultList.results.collections?.length < 1 &&
                    searchResultList.results.items?.length < 1 &&
                    searchResultList.results.users?.length < 1 && (
                      <div className="result-content">
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="No result"
                          className="auction-description"
                        />
                      </div>
                    )}
                </div>
              </div>
            )}
          </Col>

          <Col span={4} />

          <Col span={isMobile ? 6 : 9} className="page-header-items">
            <Space size={25} align="center">
              {!isMobile && (
                <Link href="/">
                  <div className="menu-item-text">{t('Explore')}</div>
                </Link>
              )}
              {!isMobile && (
                <Link href="/staking">
                  <div className="menu-item-text">{t('Staking')}</div>
                </Link>
              )}
              {!isMobile && (
                <Link href="/collections" className="collection-item">
                  <div className="menu-item-text collection-item">
                    {t('Collections')}
                  </div>
                </Link>
              )}
              {connectionAccount.address ? (
                <div className="create-menu-item">
                  <Link href="/create">
                    <div className="create-menu-item-text">{t('Create')}</div>
                  </Link>
                </div>
              ) : (
                <div />
              )}

              {isMobile && (
                <div className="search-menu-item-small-screens">
                  <Col
                    span={12}
                    xs={12}
                    sm={12}
                    md={0}
                    lg={0}
                    xl={0}
                    xxl={0}
                    className="search-icon-col"
                  >
                    <img
                      src={
                        inSearchMode && smallDeviceSearchTrigger
                          ? CancelSearchIcon
                          : SearchIcon
                      }
                      alt="Search Icon"
                      onClick={onSmallDeviceSearchTriggerIconClicked}
                      role="presentation"
                    />
                  </Col>
                </div>
              )}

              <div
                className="connect-menu-icon-div"
                onClick={onOpenMenu}
                onKeyDown={onOpenMenu}
                role="button"
                tabIndex={-22}
              >
                {isMobile ? (
                  <MenuIcon />
                ) : (
                  <img
                    src={MenuIconPng}
                    alt="Project Icon"
                    width={45}
                    height={45}
                  />
                )}
              </div>

              <div
                onClick={onConnectMenuClicked}
                onKeyDown={onConnectMenuClicked}
                role="button"
                tabIndex={-12}
                className="connect-menu-item-div"
              >
                <SubConnectMenuItem connecting={connecting} />
              </div>
            </Space>
          </Col>
        </Row>
      </div>
      <div className="small-devices-search-div">
        {inSearchMode && smallDeviceSearchTrigger ? (
          <Row className="small-devices-search-row">
            <Col span={20} xs={20} sm={20} md={0} lg={0} xl={0} xxl={0}>
              <div className="small-devices-search-input">
                <SearchComponent
                  enabled={smallDeviceSearchTrigger && inSearchMode}
                  searchQuery={searchQuery}
                  onChange={onInputQueryChange}
                />
              </div>
            </Col>

            <Col span={1} xs={1} sm={1} md={0} lg={0} xl={0} xxl={0} />

            <Col span={2} xs={2} sm={2} md={0} lg={0} xl={0} xxl={0}>
              <img
                src={inSearchMode ? CancelSearchIcon : SearchIcon}
                alt="Search Icon"
                onClick={onSmallDeviceSearchTriggerIconClicked}
                role="presentation"
              />
            </Col>
          </Row>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
