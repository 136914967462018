import React, { FC } from 'react';
import { Image } from 'antd';
import { middleEllipsis, truncate } from '../../lib/Utils';
import { CollectionModel } from '../../lib/models/NftModel';
import HrefLink from './HrefLink';
import { ImageLoadFallBack } from '../../lib/Resources';
import SkeletonLoader from './SkeletonLoader';

interface Props {
  collection: CollectionModel;
}

const CollectionSmallCard: FC<Props> = ({ collection }: Props) => (
  <HrefLink
    href={`/drop/${collection.dropID}`}
    className="flex justify-start align-center my-1 pointer"
  >
    <div className="nft-small-card-img-container mr-1">
      <Image
        src={collection.imageUrl || collection.imageUrlThumbnail}
        alt={`Collection ${collection.title}`}
        className="nft-small-card-img fit-cover"
        height={44}
        width={44}
        fallback={ImageLoadFallBack}
        preview={false}
        placeholder={<SkeletonLoader className="nft-small-card-img" />}
      />
    </div>
    <div>
      <div className="normal-text-13 color-black">
        {collection.title && truncate(collection.title, 20)}
      </div>
      <div className="normal-text-11" style={{ color: '#76808F' }}>
        {`By ${
          (collection.creator.username &&
            truncate(collection.creator.username, 15)) ||
          (collection.creator.walletAddress &&
            middleEllipsis(collection.creator.walletAddress, 7))
        }`}
      </div>
    </div>
  </HrefLink>
);

export default CollectionSmallCard;
