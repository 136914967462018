import React, { FC } from 'react';
import { UserAccount } from '../../lib/models/UserAccount';
import SmallProfile from './SmallProfile';
import { truncate } from '../../lib/Utils';
import HrefLink from './HrefLink';

interface Props {
  user: UserAccount;
}

const UserSmallCard: FC<Props> = ({ user }: Props) => (
  <HrefLink
    href={`/creators/${user.walletAddress}`}
    className="flex justify-start align-center my-2 pointer"
  >
    <SmallProfile
      user={user}
      imgSize={44}
      className="nft-owner-img-sm-card fit-cover mr-1"
      BgClassName="nft-owner-img-bg-sm-card"
    />
    <div>
      <div className="normal-text-13 color-black">
        {user?.username && truncate(user?.username, 20)}
      </div>
      <div className="normal-text-11" style={{ color: '#76808F' }}>
        {`${user?.followerCount || 0} followers`}
      </div>
    </div>
  </HrefLink>
);

export default UserSmallCard;
