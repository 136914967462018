import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
} from '@aws-sdk/client-s3';
import * as Sentry from '@sentry/nextjs';
import firebase from '../db/FirebaseConfig';

// Set the AWS Region.
const region = process.env.NEXT_PUBLIC_AWS_REGION || 'us-east-1'; // e.g. "us-east-1"
const baseUrl = `https://${process.env.NEXT_PUBLIC_AWS_BUCKET}.s3.amazonaws.com`;
export const allowedThumbnails = ['jpeg', 'png', 'WebP', 'AVIF'];

// Set the parameters

// Create an Amazon S3 service client object.
const s3 = new S3Client({
  region,
  credentials: {
    accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY,
    secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
  },
});

interface UploadReponse {
  error: boolean;
  url?: string;
  urlThumbnail?: string;
  urlMedium?: string;
  urlCompressed?: string;
  fileType?: string;
}

interface UploadParams {
  file: File;
  fileName?: string;
  thumbnailSize?: string;
}

export default class ConfigService {
  public static async firebaseUploadFile({
    fileName,
    file,
  }: {
    fileName: string;
    file: File;
  }): Promise<{
    error: boolean;
    url?: string;
    urlThumbnail?: string;
    urlMedium?: string;
    urlCompressed?: string;
    fileType?: string;
  }> {
    try {
      const storageRef = firebase.ref(firebase.storage, fileName);

      const newMetadata = {
        cacheControl: 'public, max-age=315360000',
      };

      await firebase.uploadBytes(storageRef, file, newMetadata);
      const url = await firebase.getDownloadURL(storageRef);

      return {
        error: false,
        url,
        urlThumbnail: null,
        urlMedium: null,
        urlCompressed: null,
        fileType: file?.type.toLowerCase(),
      };
    } catch (error) {
      console.log('failed to upload file error', error);
      Sentry.captureException(error);
      return {
        error,
        url: null,
        urlThumbnail: null,
        urlMedium: null,
        urlCompressed: null,
        fileType: file?.type.toLowerCase(),
      };
    }
  }

  public static async uploadFile({
    file,
    fileName,
    thumbnailSize = '800x800',
  }: UploadParams): Promise<UploadReponse> {
    const url = `${baseUrl}/${fileName}`;
    let urlThumbnail = null;
    let urlCompressed = null;
    let urlMedium = null;
    const fileType = file?.type.toLowerCase();

    try {
      const uploadParams = {
        ACL: 'public-read',
        Bucket: process.env.NEXT_PUBLIC_AWS_BUCKET,
        Key: fileName,
        ContentType: file.type,
        Body: file,
        CacheControl: `max-age=${60 * 60 * 24 * 365}`, // Cache for 1 Year
      };

      if (
        allowedThumbnails.some((type) =>
          file.type.toLowerCase().includes(type.toLowerCase()),
        ) &&
        file.size / (1024 * 1024) < 4.5
      ) {
        urlThumbnail = `${process.env.NEXT_PUBLIC_AWS_IMAGE_HANDLER_URL}/${thumbnailSize}/${fileName}`; // Resize to thumbnail size
        urlMedium = `${process.env.NEXT_PUBLIC_AWS_IMAGE_HANDLER_URL}/1200x1200/${fileName}`; // 3x 1200x1200
        urlCompressed = `${process.env.NEXT_PUBLIC_AWS_IMAGE_HANDLER_URL}/filters:quality(70)/${fileName}`; // Reduce 30% quality
      }

      if (file.size / (1024 * 1024) < 1) {
        urlMedium = url;
      }

      await s3.send(new PutObjectCommand(uploadParams));

      return {
        error: false,
        url,
        urlThumbnail,
        urlMedium,
        urlCompressed,
        fileType,
      };
    } catch (error) {
      console.log('failed to upload file error', error);
      console.log('WE ARE RUNNING AWS');

      Sentry.captureException(error);

      // Try uploading to GCP when aws fails
      const res = await ConfigService.firebaseUploadFile({
        file,
        fileName,
      });

      return {
        error,
        url: null,
        urlMedium: null,
        urlThumbnail: null,
        urlCompressed: null,
        fileType,
        ...res,
      };
    }
  }

  public static async deleteFile(url: string): Promise<any> {
    const uploadParams = {
      Bucket: process.env.NEXT_PUBLIC_AWS_BUCKET,
      Key: url.replace(/\S+s3.amazonaws.com\//g, ''),
    };

    return s3.send(new DeleteObjectCommand(uploadParams));
  }
}
