import React from 'react';

const MenuIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F2F2F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M27 13H13C12.4 13 12 13.4 12 14C12 14.6 12.4 15 13 15H27C27.6 15 28 14.6 28 14C28 13.4 27.6 13 27 13ZM13 21H20C20.6 21 21 20.6 21 20C21 19.4 20.6 19 20 19H13C12.4 19 12 19.4 12 20C12 20.6 12.4 21 13 21ZM12 26C12 25.4 12.4 25 13 25H27C27.6 25 28 25.4 28 26C28 26.6 27.6 27 27 27H13C12.4 27 12 26.6 12 26Z"
      fill="#76808F"
    />
  </svg>
);

export default MenuIcon;
