import {
  AllChains,
  BNBIcon,
  FTMIcon,
  MATICIcon,
  CoinsImg,
  LinkImg,
  TalkImg,
  ETHIcon,
} from './Resources';
import {
  SupportedChains,
  SupportedFilterChains,
  TAccountType,
  TTxEventType,
} from './models/NftModel';
import { ReferralCardData } from './models/GeneralModels';

export const CHAINS: Record<
  SupportedChains,
  { icon: string; currency?: string; name: string; displayedName: string }
> = {
  bsc: {
    icon: BNBIcon,
    currency: 'BNB',
    name: 'bsc',
    displayedName: 'Binance Chain (BEP-721)',
  },
  polygon: {
    icon: MATICIcon,
    currency: 'MATIC',
    name: 'polygon',
    displayedName: 'Polygon',
  },
  fantom: {
    icon: FTMIcon,
    currency: 'FTM',
    name: 'fantom',
    displayedName: 'Fantom',
  },
  ethereum: {
    icon: ETHIcon,
    currency: 'ETH',
    name: 'ethereum',
    displayedName: 'Ethereum',
  },
};

export const ACCOUNT_TYPE: Record<TAccountType, { name: string }> = {
  individual: { name: 'individual' },
  project: { name: 'project' },
};

export const FILTERCHAINS: Record<
  SupportedFilterChains,
  { icon: string; currency?: string; name: string }
> = {
  all: {
    icon: AllChains,
    currency: 'MATIC',
    name: 'all',
  },
  ...CHAINS,
};

export const TRANSACTION_EVENT_TYPES: Record<
  TTxEventType,
  { name: string; value: string }
> = {
  'replay-transfer': { name: 'NFT Transfer', value: 'replay-transfer' },
  'replay-sale': { name: 'NFT Sale', value: 'replay-sale' },
};

export const REFERRAL_STEPS: ReferralCardData[] = [
  {
    image: LinkImg,
    step: 1,
    description: 'Get referral link',
    contentText:
      'Connect a wallet and generate your referral link in the Referral\n' +
      '        section.',
  },
  {
    image: TalkImg,
    step: 2,
    description: 'Invite friends',
    contentText: 'Invite your friends to register via your referral link',
  },
  {
    image: CoinsImg,
    step: 3,
    description: 'Earn crypto',
    contentText:
      'Receive referral rewards in AIRT tokens from your friends trades.',
  },
];

export const REFERRAL_EARN_RULES = {
  tradingVolume: 500,
  earn: 5,
  currency: '$',
  claimThreshold: 100,
};

export enum PaymentType {
  STORAGE_FEE = 'STORAGE_FEE',
  DROP_CREATION_FEE = 'DROP_CREATION_FEE ',
  DROP_EXTENSION_FEE = 'DROP_EXTENSION_FEE',
  AUCTION_CREATION_FEE = 'AUCTION_CREATION_FEE',
}

export enum ImageResourceType {
  USER = 'user',
  DROP = 'drop',
  NFT = 'nft',
}

export const TOKEN_TYPES = {
  ERC721: 1,
  ERC1155: 2,
};

export const LISTING_TYPES = {
  fixSale: 1,
  auction: 2,
};
