import React from 'react';
import jsCookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Space } from 'antd';
import { useRouter } from 'next/router';
import { CancelSearchIcon, SelectIcon } from '../../lib/Resources';
import {
  disablePageScroll,
  isOnMobile,
  mapLocaleLanguages,
} from '../../lib/Utils';
import { connectionAccountState, showMenuState } from '../../recoil/atoms';
import useClickOutside from '../hooks/useClickOutside';
import useMediaQuery from '../hooks/useMediaQuery';
import { REFERRAL_EARN_RULES } from '../../lib/constants';

export const NavMenu = () => {
  const [showMenu, setShowMenu] = useRecoilState<boolean>(showMenuState);
  const connectionAccount = useRecoilValue(connectionAccountState);
  const { t } = useTranslation();
  const { locale, pathname, asPath, push } = useRouter();

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.target;

    if (value !== locale) {
      push(pathname, asPath, { locale: value });
    }

    jsCookie.set('_anft_language', value);
    setShowMenu(!showMenu);
  };

  const onOpenMenu = () => {
    if (isOnMobile()) {
      disablePageScroll();
    }
    setShowMenu(!showMenu);
  };

  const dropdownRef = useClickOutside(() => {
    if (showMenu) {
      onOpenMenu();
    }
  });

  const isManager = () =>
    connectionAccount?.userRoles &&
    connectionAccount?.userRoles.some((role) =>
      ['admin', 'moderator'].includes(role),
    );

  return (
    <div
      className={showMenu ? 'nav-menu active-menu' : 'nav-menu'}
      ref={dropdownRef}
    >
      <div className="nav-menu-wrapper">
        <div className="nav-menu-header">
          <div className="nav-menu-header-text">
            <h3>Menu</h3>
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={CancelSearchIcon}
            alt="Cancel Icon"
            onClick={onOpenMenu}
            onKeyDown={() => null}
          />
        </div>
        <div className="nav-menu-list">
          <ul>
            {isManager() && !isMobile ? (
              <li
                className="nav-menu-item"
                onClick={onOpenMenu}
                role="presentation"
              >
                <Link href="/admin">{t('Admin')}</Link>
              </li>
            ) : (
              <div />
            )}

            {isMobile && (
              <li
                className="nav-menu-item"
                onClick={onOpenMenu}
                role="presentation"
              >
                <Link href="/">Explore</Link>
              </li>
            )}
            {isMobile && (
              <li
                className="nav-menu-item"
                onClick={onOpenMenu}
                role="presentation"
              >
                <Link href="/collections">Collections 🏆</Link>
              </li>
            )}
            <li
              className="nav-menu-item"
              onClick={onOpenMenu}
              role="presentation"
            >
              <Link href="/auctions">Hot Auctions ⏱</Link>
            </li>
            <li className="nav-menu-item ant-row">
              <Space size="large">
                <a
                  href="https://www.airnfts.com/post/nft-launchpad-for-defi-and-gamefi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NFT Launchpad
                </a>
                <div className="badge">Coming soon</div>
              </Space>
            </li>
            <li
              className="nav-menu-item"
              onClick={onOpenMenu}
              role="presentation"
            >
              <Link href="/nftbattle">NFT Battle</Link>
            </li>
            {isMobile && (
              <li
                className="nav-menu-item"
                onClick={onOpenMenu}
                role="presentation"
              >
                <Link href="/staking">Staking</Link>
              </li>
            )}
            <li
              className="nav-menu-item"
              onClick={onOpenMenu}
              role="presentation"
            >
              <Link
                href={
                  connectionAccount.address
                    ? `/referral/${connectionAccount.address}`
                    : '/referral'
                }
              >
                {t('Earn {{amount}}', {
                  amount: `${REFERRAL_EARN_RULES.currency}${REFERRAL_EARN_RULES.earn}`,
                })}
              </Link>
            </li>
            <li className="nav-menu-item">
              <a
                href="https://airnfts.com/blog"
                target="_blank"
                rel="noopener noreferrer"
                onClick={onOpenMenu}
              >
                {t('Blog')}
              </a>
            </li>
          </ul>
        </div>

        <div className="nav-menu-footer">
          <h4>{t('LANGUAGE')}</h4>
          <div className="nav-menu-select-wrapper">
            <select className="nav-menu-select" onChange={handleLanguageChange}>
              {mapLocaleLanguages.map((lang) => (
                <option
                  value={lang.key}
                  key={lang.key}
                  selected={locale === lang.key}
                >
                  {lang.name}
                </option>
              ))}
            </select>
            <div className="nav-menu-select-icon">
              <img src={SelectIcon} alt="SelectIcon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
