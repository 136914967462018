import React from 'react';
import { Col, Row, Select } from 'antd';
import {
  FaDiscord,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
} from 'react-icons/fa';
import jsCookie from 'js-cookie';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { AppAIRNFTIcon } from '../../lib/Resources';
import { mapLocaleLanguages } from '../../lib/Utils';
import locales from '../../locales';

export const PageFooter = () => {
  const { t } = useTranslation();
  const { locale, pathname, asPath, push } = useRouter();

  function handleLanguageChange(value) {
    if (value !== locale) {
      push(pathname, asPath, { locale: value });
    }
    jsCookie.set('_anft_language', value);
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div className="layout-container footer-container">
        <Row gutter={[16, 32]}>
          <Col lg={9} md={9} sm={24} xs={24}>
            <div className="branding-name">
              <img
                src={AppAIRNFTIcon}
                alt="Project Icon"
                width={180}
                height={42}
              />
            </div>
            <h4>{t('FOLLOW US ON')}</h4>
            <div className="footer-social-links">
              <a
                href="https://twitter.com/airnfts"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter size="20" />
              </a>
              <a
                href="https://t.me/joinchat/HKsQnSvxhfjjKGJe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegramPlane size="20" />
              </a>
              <a
                href="https://instagram.com/airnfts"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size="20" />
              </a>
              <a
                href="https://discord.gg/TXjQUVWXx7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord size="20" />
              </a>
            </div>
          </Col>
          <Col lg={5} md={5} sm={12} xs={12}>
            <h4>AIRNFTS</h4>
            <div className="footer-links">
              <Link href="/">NFTs</Link>
              <a
                href="https://airnfts.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Website')}
              </a>
              <a
                href="https://airnfts.com/blog"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Blog')}
              </a>
            </div>
          </Col>
          <Col lg={5} md={5} sm={12} xs={12}>
            <h4>{t('INFORMATION')}</h4>
            <div className="footer-links">
              <a
                href="https://www.airnfts.com/terms-of-services"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Terms of service')}
              </a>
              <a
                href="https://www.airnfts.com/privacy-and-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Privacy policy')}
              </a>
              <a
                href="https://forms.gle/d3jW5cJS4JYXSmS98"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Get verified')}
              </a>
            </div>
          </Col>
          <Col lg={5} md={5} sm={24} xs={24}>
            <h4>{t('LANGUAGE')}</h4>

            <Select
              className="footer-language-button"
              size="large"
              onChange={handleLanguageChange}
              defaultValue={jsCookie.get('_anft_language') || locales.language}
            >
              {mapLocaleLanguages.map((lang) => (
                <Select.Option
                  value={lang.key}
                  key={lang.key}
                  selected={lang.key === locale}
                >
                  {lang.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
    </div>
  );
};
