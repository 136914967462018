import axios from 'axios';
import jsCookie from 'js-cookie';

const http = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_BACKEND_SERVICE_URL}`,
});

const requestHandler = (request) => {
  if (!request.headers.address && process.browser) {
    request.headers.address = jsCookie.get('_anft_user_address') || '';
  }
  if (process.browser) {
    request.headers['Accept-Language'] =
      jsCookie.get('_anft_language') || request.headers['Accept-Language'];
  }
  return request;
};

const errorHandler = (err) => {
  throw err;
};

export const successHandler = (response) => response;

http.interceptors.request.use((request) => requestHandler(request));

http.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default http;
