import React from 'react';

const WalletConnectIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0Z"
      fill="url(#paint0_linear)"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.1682 12.3568C13.389 9.2144 18.611 9.2144 21.8318 12.3568L22.2194 12.735C22.3805 12.8921 22.3805 13.1469 22.2194 13.304L20.8934 14.5977C20.8129 14.6763 20.6823 14.6763 20.6018 14.5977L20.0684 14.0773C17.8215 11.885 14.1785 11.885 11.9316 14.0773L11.3604 14.6346C11.2798 14.7132 11.1493 14.7132 11.0688 14.6346L9.74278 13.3409C9.58174 13.1838 9.58174 12.929 9.74278 12.7719L10.1682 12.3568ZM24.5741 15.0324L25.7542 16.1838C25.9153 16.3409 25.9153 16.5956 25.7542 16.7528L20.4329 21.9447C20.2718 22.1018 20.0107 22.1018 19.8497 21.9447C19.8497 21.9447 19.8497 21.9447 19.8497 21.9447L16.0729 18.2598C16.0327 18.2205 15.9674 18.2205 15.9271 18.2598L15.9271 18.2598L12.1505 21.9447C11.9894 22.1018 11.7283 22.1018 11.5673 21.9447C11.5673 21.9447 11.5673 21.9447 11.5673 21.9447L6.24578 16.7527C6.08474 16.5956 6.08474 16.3408 6.24578 16.1837L7.42592 15.0323C7.58696 14.8752 7.84806 14.8752 8.0091 15.0323L11.7859 18.7172C11.8262 18.7565 11.8915 18.7565 11.9317 18.7172C11.9317 18.7172 11.9317 18.7172 11.9317 18.7172L15.7084 15.0323C15.8694 14.8752 16.1305 14.8752 16.2915 15.0323C16.2915 15.0323 16.2915 15.0323 16.2915 15.0323L20.0684 18.7172C20.1086 18.7565 20.1739 18.7565 20.2141 18.7172L23.9909 15.0324C24.1519 14.8752 24.413 14.8752 24.5741 15.0324Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="27"
        x2="32"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5D9DF6" />
        <stop offset="1" stopColor="#006FFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default WalletConnectIcon;
